import type { QueryKey } from '@tanstack/query-core/src/types';
import { getToggles, IFeatureToggleParams } from 'common/api/ab';

export const getGetTogglesKey = (params: IFeatureToggleParams): QueryKey => ['getToggles', params];

export const getGetTogglesQueryOptions = (params: IFeatureToggleParams) => ({
    queryKey: getGetTogglesKey(params),
    queryFn: async () => getToggles(params),
    cacheTime: Infinity,
});
