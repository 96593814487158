import { FieldValidator } from 'final-form';

export const composeValidators =
    <T>(...validators: FieldValidator<T>[]) =>
    (value: T) => {
        for (const validator of validators) {
            const error = validator(value);
            if (error) {
                return error;
            }
        }

        return undefined;
    };
