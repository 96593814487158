import { ReactNode } from 'react';
import * as Styled from './card-layout.styles';

interface ICardLayout {
    avatarSlot?: ReactNode;
    headerSlot?: ReactNode;
    contentSlot?: ReactNode;
    buttonsSlot?: ReactNode;
}

export const CardLayout = ({ avatarSlot, headerSlot, buttonsSlot, contentSlot }: ICardLayout) => (
    <Styled.Container noContent={!contentSlot}>
        {avatarSlot && <Styled.Avatar>{avatarSlot}</Styled.Avatar>}
        {headerSlot && <Styled.Header>{headerSlot}</Styled.Header>}
        {contentSlot && <Styled.Content>{contentSlot}</Styled.Content>}
        {buttonsSlot && <Styled.ButtonsSlot>{buttonsSlot}</Styled.ButtonsSlot>}
    </Styled.Container>
);
