import { useTranslation } from 'next-i18next';
import { ILocation } from 'common/api';
import { calculateDistance, getCountryUnit } from 'common/lib';
import { InlineItem } from 'common/ui/index';

interface IProps {
    from: ILocation | undefined;
    to: ILocation | undefined;
    countryIso: string;
}

export const Distance = ({ from, to, countryIso }: IProps) => {
    const { t } = useTranslation('active_order');

    if (from === undefined || to === undefined) {
        return null;
    }

    const { unit, ratio } = getCountryUnit(countryIso);
    const approximateDistance = Math.max(0.1, calculateDistance(from, to) * ratio);
    return (
        <InlineItem>
            {t(`active_order:bid_tasker_distance_${unit}`, { distance: approximateDistance.toFixed(1) })}
        </InlineItem>
    );
};
