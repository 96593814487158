import React from 'react';
import { IDataInput, InputPhone, InputPhoneMobile } from '@indriver/nova';
import type { CountriesCodesType } from '@indriver/nova-core';

interface IProps {
    name: string;
    value: string;
    label: string;
    hint?: string;
    invalid?: boolean;
    country: CountriesCodesType;
    onChangeCountry: (code: CountriesCodesType) => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement>, data: IDataInput) => void;
    onFocus: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
    onBlur: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
    isMobile: boolean;
}

export const InputPhoneAdaptive = ({
    name,
    value,
    label,
    hint,
    invalid,
    country,
    onChangeCountry,
    onChange,
    onFocus,
    onBlur,
    isMobile,
}: IProps) => {
    return isMobile ? (
        <InputPhoneMobile
            name={name}
            value={value}
            label={label}
            hint={hint}
            invalid={invalid}
            required
            country={country}
            flagListZIndex='20'
            onChangeCountry={onChangeCountry}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
        />
    ) : (
        <InputPhone
            name={name}
            value={value}
            label={label}
            hint={hint}
            invalid={invalid}
            required
            country={country}
            className='inputPhoneDesktop'
            onChangeCountry={onChangeCountry}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
        />
    );
};
