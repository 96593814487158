import { Button, IButtonProps } from '@indriver/nova';

interface IScrollToFormButtonProps extends IButtonProps {
    anchorId: string;
}

export const ScrollToAnchorButton = ({ anchorId, onClick, ...otherProps }: IScrollToFormButtonProps) => (
    <Button
        {...otherProps}
        onClick={(e) => {
            onClick?.(e);
            if (!e.defaultPrevented) {
                document.getElementById(anchorId)?.scrollIntoView({ behavior: 'smooth' });
            }
        }}
    />
);
