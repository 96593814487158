import { captureException, captureMessage } from '@sentry/minimal';
import axios from 'axios';
import getConfig from 'next/config';
import { z, ZodIssueCode } from 'zod';
import { INearCountriesResponse, IGeoAutocompleteResponse, IRecommendedCity, IGeoAutocompleteItem } from './models';

const { serverRuntimeConfig } = getConfig();

export const apiInstance = axios.create({
    baseURL: serverRuntimeConfig?.GEO_HOST ?? '',
    timeout: 3000,
    headers: {
        'X-Api-Key': serverRuntimeConfig?.GEO_API_KEY ?? '',
    },
});

apiInstance.interceptors.response.use(null, (error) => {
    captureException(error);
    throw error;
});

export const getNearCities = async (ip: string | null): Promise<IRecommendedCity[]> => {
    try {
        const response = await apiInstance.get<INearCountriesResponse>('/api/recommendedCity', {
            headers: { 'x-forwarded-for': ip ?? 0 },
        });

        if (!response.data.nearCities) {
            captureMessage('Empty Geo response for ip', { extra: { ip } });
            return [];
        }

        return response.data.nearCities;
    } catch (e) {
        return [];
    }
};

export const GeoAutocompleteQueryParamsSchema = z.object({
    locale: z.string(),
    cityId: z
        .string()
        .transform((val) => Number.parseInt(val, 10))
        .superRefine((val, ctx) => {
            if (Number.isNaN(val)) {
                ctx.addIssue({
                    code: ZodIssueCode.invalid_type,
                    expected: 'number',
                    received: 'string',
                });
            }
        }),
    query: z.string(),
});

export type IAddressAutocompleteParams = z.infer<typeof GeoAutocompleteQueryParamsSchema>;

export const getClientAddressAutocomplete = async (params: IAddressAutocompleteParams, signal?: AbortSignal) => {
    const response = await axios.get<IGeoAutocompleteItem[]>('/api/geo-autocomplete', { params, signal });
    return response.data;
};

export const getAddressAutocomplete = async ({ locale, cityId, query }: IAddressAutocompleteParams) => {
    const response = await apiInstance.get<IGeoAutocompleteResponse>('/api/autocomplete', {
        params: {
            locale,
            cid: cityId,
            text: query,
            requester: 'handyman',
        },
    });
    return response.data.response.items;
};
