import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { Button, IButtonProps } from '@indriver/nova';
import { useBreakpoints } from 'common/lib';
import * as Styled from './jumbotron-404.styles';

export const Jumbotron404 = () => {
    const { t } = useTranslation();
    const media = useBreakpoints();
    const { locale } = useRouter();

    const buttonProps = useMemo<IButtonProps>(() => {
        if (media.isGtM) {
            return { size: 'l' };
        }

        if (media.isGtS) {
            return { size: 'm' };
        }

        return { size: 'm', stretched: true };
    }, [media.isGtM, media.isGtS]);

    return (
        <Styled.Wrapper>
            <Styled.Content>
                <Styled.Heading type='h1' size='xxl' fontType='marketing' margin='s'>
                    <Trans i18nKey={'error:jumbotron.heading'} components={{ span: <span /> }} />
                </Styled.Heading>
                <Styled.Subtitle>{t('error:jumbotron.description')}</Styled.Subtitle>
                <Button
                    {...buttonProps}
                    onClick={() => {
                        window.location.assign(`/${locale}`);
                    }}>
                    {t('error:jumbotron.button')}
                </Button>
            </Styled.Content>
            <Styled.SadGuy />
        </Styled.Wrapper>
    );
};
