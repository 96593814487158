import { createToggleSlice } from 'common/lib';

const { actions, reducer } = createToggleSlice('notificationWhatsApp');

export const {
    setTrue: showNotificationWhatsApp,
    setFalse: hideNotificationWhatsApp,
    toggle: toggleNotificationWhatsApp,
} = actions;
export const notificationWhatsAppReducer = reducer;
