import type { IWizardReview } from 'common/api';
import { formatName } from '../format-name';

export const getReviews = (reviews: IWizardReview[]) =>
    reviews.map((review) => ({
        author: formatName(review.author),
        datePublished: review.created_at,
        reviewBody: review.message,
        reviewRating: {
            ratingValue: review.rating,
            bestRating: 5,
        },
    }));
