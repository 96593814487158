import { useQuery } from '@tanstack/react-query';
import { getOrder } from 'common/api/order';

interface IGetOrderParams {
    locale: string;
    cityId: number;
    orderId: string;
}

export const useGetOrder = ({ locale, cityId, orderId }: IGetOrderParams) =>
    useQuery({
        queryKey: ['getOrder', { locale, orderId }],
        queryFn: async () => getOrder({ locale, cityId, orderId }),
    });
