import React from 'react';
import { hasRefreshToken } from '../auth';

export const useAuthorizedUser = () => {
    const [isAuthorizedUser, setIsAuthorizedUser] = React.useState<boolean>(false);

    React.useEffect(() => {
        setIsAuthorizedUser(hasRefreshToken());
    }, []);

    return isAuthorizedUser;
};
