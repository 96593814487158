import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDefaultAnalyticsPayload } from './types';

const { actions, reducer } = createSlice({
    name: 'analytics',
    initialState: null as unknown as IDefaultAnalyticsPayload,
    reducers: {
        setAnalyticsPayload: (_, action: PayloadAction<IDefaultAnalyticsPayload>) => action.payload,
    },
});

export const { setAnalyticsPayload } = actions;
export const analyticsPayloadReducer = reducer;
