import * as Styled from './progress-bar-pill.styles';

interface IProps {
    title?: string;
    isActive?: boolean;
    isFull?: boolean;
}

// TODO: rewrite using `state: 'empty' | 'active' | 'full'`, see
export const ProgressBarPill = ({ title, isActive, isFull }: IProps) => (
    <Styled.ProgressBarPill>
        <Styled.PillWrapper>
            {isActive && <Styled.Filler />}
            {isFull && <Styled.Filler isFull />}
            <Styled.Pill />
        </Styled.PillWrapper>
        {title && <Styled.Title isActive={isActive}>{title}</Styled.Title>}
    </Styled.ProgressBarPill>
);
