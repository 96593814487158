import { ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { isSSR } from 'common/lib';

interface IPortal {
    children: ReactNode;
    portalId?: string;
}

export const Portal = ({ children, portalId = 'portal' }: IPortal) => {
    const [container] = useState(() => !isSSR && document.createElement('div'));
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        (container as HTMLDivElement).setAttribute('id', portalId);
        document.body.appendChild(container as HTMLDivElement);
        setMounted(true);
        return () => {
            document.body.removeChild(container as HTMLDivElement);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return mounted && !isSSR ? ReactDOM.createPortal(children, container as HTMLDivElement) : null;
};
