import { getCookie, setCookie, deleteCookie, CookieValueTypes } from 'cookies-next';
import { getDateFromTimestamp } from './date';
import { parseJwt } from './parse-jwt';

export const ACCESS_TOKEN_KEY = 'SERVICES_AT';
export const REFRESH_TOKEN_KEY = 'SERVICES_RT';

export const getAccessToken = (): CookieValueTypes => getCookie(ACCESS_TOKEN_KEY);
export const getRefreshToken = (): CookieValueTypes => getCookie(REFRESH_TOKEN_KEY);

const getTokenExpDate = (jwt: string): Date | undefined => {
    const { exp } = parseJwt(jwt);
    return exp !== undefined ? getDateFromTimestamp(exp) : undefined;
};

export const setAccessToken = ({
    accessToken,
    refreshToken,
}: {
    accessToken?: string;
    refreshToken?: string;
}): void => {
    if (accessToken) {
        setCookie(ACCESS_TOKEN_KEY, accessToken, { expires: getTokenExpDate(accessToken) });
    }
    if (refreshToken) {
        setCookie(REFRESH_TOKEN_KEY, refreshToken, { expires: getTokenExpDate(refreshToken) });
    }
};
export const removeAccessToken = (): void => {
    deleteCookie(ACCESS_TOKEN_KEY);
    deleteCookie(REFRESH_TOKEN_KEY);
};

export const isAuthorizedUser = (): boolean => typeof getAccessToken() === 'string';

export const canRefreshToken = (): boolean => !isAuthorizedUser() && typeof getRefreshToken() === 'string';

export const hasRefreshToken = (): boolean => typeof getRefreshToken() === 'string';
