import { getRSAHashedImportParamsFromJWK } from './get-rsa-hashed-import-params-from-jwk';

export const createSignature = async (privateJwk: JsonWebKey, data: string): Promise<string> => {
    const algorithm = getRSAHashedImportParamsFromJWK(privateJwk);
    const privateKey = await crypto.subtle.importKey('jwk', privateJwk, algorithm, true, ['sign']);
    const signature = await crypto.subtle.sign(algorithm, privateKey, new TextEncoder().encode(data));
    return Buffer.from(signature).toString('base64');
};

export const verifySignature = async (publicJwk: JsonWebKey, signature: string, data: string): Promise<boolean> => {
    const algorithm = getRSAHashedImportParamsFromJWK(publicJwk);
    const publicKey = await crypto.subtle.importKey('jwk', publicJwk, algorithm, true, ['verify']);
    return crypto.subtle.verify(algorithm, publicKey, Buffer.from(signature, 'base64'), new TextEncoder().encode(data));
};
