import { ITaskerInfo, IUser } from 'common/api';
import { anonymizeName } from 'common/lib';
import { ITasker } from '../model/types';
import { getDescription } from './description';
import { getPortfolio } from './portfolio';

export const fromTaskerInfo = (taskerUser: IUser, taskerInfo: ITaskerInfo): ITasker => ({
    avatarUrl: taskerUser.avatar,
    name: anonymizeName(taskerUser),
    userId: taskerUser.user_id,

    completedOrders: taskerInfo.completed_orders,
    description: getDescription(taskerInfo),
    joinedAt: taskerInfo.joined_at,
    portfolio: getPortfolio(taskerInfo),
    rating: taskerInfo.rating,
    reviewsCount: taskerInfo.count_review,
});
