import { setCookie } from 'cookies-next';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useSyncStoreToCookie = <TState = unknown, Selected = unknown>(
    name: string,
    selector: (state: TState) => Selected,
) => {
    const nextValue = useSelector(selector);

    useEffect(() => {
        setCookie(name, nextValue);
    }, [name, nextValue]);
};
