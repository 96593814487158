import { useMutation, useQueryClient } from '@tanstack/react-query';
import { patchUserNotifications } from 'common/api/notification';

export const usePatchUserNotifications = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (enable: boolean) =>
            patchUserNotifications({
                notifications: [
                    {
                        enable,
                        platform: 'whatsapp',
                    },
                ],
            }),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['getUserNotifications'] });
        },
    });
};
