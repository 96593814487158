import { useEffect, useState } from 'react';

const matchersCache: Record<string, MediaQueryList> = {};

const getMatcher = (query: string): MediaQueryList => {
    if (matchersCache[query] === undefined) {
        matchersCache[query] = window.matchMedia(query);
    }

    return matchersCache[query];
};

const supportsChangeEvent = () =>
    // eslint-disable-next-line @typescript-eslint/unbound-method
    Boolean(typeof window !== 'undefined' && window?.matchMedia('(min-width: 0px)').addEventListener);

const addChangeListener: (matchMedia: MediaQueryList, cb: () => void) => void = supportsChangeEvent()
    ? (matchMedia, cb) => matchMedia.addEventListener('change', cb)
    : (matchMedia, cb) => matchMedia.addListener(cb);

const removeChangeListener: (matchMedia: MediaQueryList, cb: () => void) => void = supportsChangeEvent()
    ? (matchMedia, cb) => matchMedia.removeEventListener('change', cb)
    : (matchMedia, cb) => matchMedia.removeListener(cb);

export type Queries = Record<string, string>;
export type QueryMatches<Q extends Queries> = Partial<Record<keyof Q, boolean>>;

export function useMediaQueries<Q extends Queries>(queries: Q, fallback: QueryMatches<Q> = {}): QueryMatches<Q> {
    const [matches, setMatches] = useState(fallback);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        const matcherEntries = Object.entries(queries).map(
            ([key, query]) => [key, getMatcher(query)] as [keyof Queries, MediaQueryList],
        );

        function updateMatches() {
            setMatches(
                Object.fromEntries(matcherEntries.map(([key, matcher]) => [key, matcher.matches])) as QueryMatches<Q>,
            );
        }

        updateMatches();

        matcherEntries.forEach(([, matcher]) => {
            addChangeListener(matcher, updateMatches);
        });

        // eslint-disable-next-line consistent-return
        return () => {
            matcherEntries.forEach(([, matcher]) => {
                removeChangeListener(matcher, updateMatches);
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return matches;
}
