import { useQuery } from '@tanstack/react-query';
import { user } from 'common/api';

interface IUseTermsParams {
    countryIso: string;
    locale: string;
}

export const useTerms = ({ countryIso, locale }: IUseTermsParams) =>
    useQuery({
        queryKey: ['getTerms', { countryIso, locale }],
        queryFn: async () => user.getTerms(countryIso, locale),
        retry: false,
    });
