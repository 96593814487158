import { useTranslation } from 'next-i18next';
import React, { ReactNode } from 'react';
import { useBoolean } from 'usehooks-ts';
import { Text } from '@indriver/nova';
import { variables } from '@indriver/yrel';
import { ITaskerInfo, IUser } from 'common/api';
import { useBreakpoints, useClampedText } from 'common/lib';
import { AvatarWithBadge, ClampedText } from 'common/ui';
import { Experience, hasPhotos, hasReviews, OrdersCount, Portfolio, TaskerReferences } from 'entities/tasker';
import { fromTaskerInfo } from '../../lib/from-tasker-info';
import { CardLayout } from '../card-layout';
import * as Styled from './compact-tasker-card.styles';

interface IProps {
    taskerUser: IUser;
    taskerInfo: ITaskerInfo;
    buttonsSlot?: ReactNode;
}

// TODO: Сделать verticalCenter в DesktopButtons
// eslint-disable-next-line complexity
export const CompactTaskerCard = ({ taskerUser, taskerInfo, buttonsSlot }: IProps) => {
    const { t } = useTranslation(['common', 'active_order']);
    const media = useBreakpoints();
    const tasker = fromTaskerInfo(taskerUser, taskerInfo);

    const isTruncated = useBoolean(true);
    const { textRef, isOverflowing } = useClampedText(3);

    return (
        <CardLayout
            avatarSlot={
                <AvatarWithBadge
                    avatarSize={media.isGtS ? 'xxxl' : 'xxl'}
                    badgeSize='m'
                    content={tasker.name}
                    iconSize={16}
                    image={tasker.avatarUrl}
                    rating={tasker.rating}
                />
            }
            headerSlot={
                <>
                    <Text size='xl' weight='bold'>
                        {tasker.name}
                    </Text>
                    <Text size={media.isGtS ? 'ml' : 's'} color={variables['text-and-icon-secondary']}>
                        {tasker.completedOrders ? <OrdersCount tasker={tasker} /> : <Portfolio tasker={tasker} />}
                        <Experience tasker={tasker} />
                    </Text>
                </>
            }
            contentSlot={
                tasker.description || hasReviews(tasker) || hasPhotos(tasker) ? (
                    <>
                        {tasker.description && (
                            <Text size={media.isGtS ? 'ml' : 's'} color={variables['text-and-icon-secondary']}>
                                <ClampedText
                                    ref={textRef}
                                    lines={isTruncated.value ? 3 : undefined}
                                    onClick={isTruncated.toggle}>
                                    {tasker.description}
                                </ClampedText>
                                {isOverflowing && !isTruncated.value && (
                                    <Styled.DescriptionTrigger onClick={isTruncated.setTrue}>
                                        {t('active_order:bid_tasker_should_show_less')}
                                    </Styled.DescriptionTrigger>
                                )}
                            </Text>
                        )}
                        <TaskerReferences tasker={tasker} disableReviews defaultVisible />
                    </>
                ) : null
            }
            buttonsSlot={buttonsSlot}
        />
    );
};
