import throttle from 'lodash/throttle';
import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { useIsomorphicLayoutEffect } from 'usehooks-ts';

export const useIsElementOverflown = (ref: React.RefObject<HTMLElement>) => {
    const [isOverflown, setIsOverflown] = React.useState(false);

    useIsomorphicLayoutEffect(() => {
        if (typeof window === 'undefined') {
            return undefined;
        }

        const updateOverflow = throttle(() => {
            if (ref.current) {
                setIsOverflown(ref.current.scrollHeight > ref.current.clientHeight);
            }
        }, 40);

        const resizeObserver = new ResizeObserver(updateOverflow);
        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [ref]);

    return isOverflown;
};
