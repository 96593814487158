import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useBoolean } from 'usehooks-ts';
import { RefreshRepeatRedoRotate } from '@indriver/mireska';
import { Button } from '@indriver/nova';
import { useIsElementOverflown } from 'common/lib';
import { ChipLink, ChipLinks } from 'common/ui';
import { setCurrentCity } from '../../model/city-slice';
import { ICityInfo } from '../../model/types';
import * as Styled from './cities-chips.styles';

interface IProps {
    cities: ICityInfo[];
    buildLink: (city: ICityInfo) => string;
}

export const CitiesChips = ({ cities, buildLink }: IProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const containerRef = React.useRef<HTMLDivElement>(null);
    const isOverflown = useIsElementOverflown(containerRef);
    const isCollapsed = useBoolean(true);

    React.useEffect(
        () => {
            isCollapsed.setTrue();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps -- isCollapsed is not stable and will trigger effect when changed
        [cities],
    );

    return (
        <>
            <ChipLinks ref={containerRef} collapsed={isCollapsed.value}>
                {cities.map((city) => (
                    <Link key={city.id} href={buildLink(city)} prefetch={false} passHref>
                        <ChipLink onClick={() => dispatch(setCurrentCity(city))}>{city.name}</ChipLink>
                    </Link>
                ))}
            </ChipLinks>
            {isCollapsed.value && isOverflown && (
                <Styled.ToggleButton>
                    <Button
                        size='s'
                        design='ghost'
                        prefix={<RefreshRepeatRedoRotate size={20} />}
                        onClick={isCollapsed.setFalse}>
                        {t('entities.city.show_all')}
                    </Button>
                </Styled.ToggleButton>
            )}
        </>
    );
};
