const CURRENCY_CODE_TO_HUMAN_CURRENCY_MAP = {
    BR: ' R$',
    CO: ' $',
    MX: ' MXN',
    KZ: ' ₸',
    PE: ' S/',
} as const;

export type CountryCode = keyof typeof CURRENCY_CODE_TO_HUMAN_CURRENCY_MAP;

export const mapCurrency = (currencyCode: CountryCode): string => {
    return CURRENCY_CODE_TO_HUMAN_CURRENCY_MAP[currencyCode];
};
