import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { hideMixedModelModal, showMixedModelModal } from './redux-slice';

export const useBoundActions = () => {
    const dispatch = useDispatch();
    return {
        showModal: useCallback((opener: string) => dispatch(showMixedModelModal(opener)), [dispatch]),
        hideModal: useCallback(() => dispatch(hideMixedModelModal()), [dispatch]),
    };
};
