import { ICityInfo } from '../../model/types';
import * as Styled from './city-button.styles';

interface IProps {
    city: ICityInfo;
    onClick: (city: ICityInfo) => void;
}

export const CityButton = ({ city, onClick }: IProps) => (
    <Styled.Button type='button' onClick={() => onClick(city)}>
        {city.name} <Styled.Country>{city.countryName}</Styled.Country>
    </Styled.Button>
);
