import groupBy from 'lodash/groupBy';
import { IExtendedOrder, OrderStatus } from 'common/api';
import { OrdersSection } from './types';

const OrderStatusToSection: Record<OrderStatus, OrdersSection> = {
    [OrderStatus.new]: OrdersSection.search,
    [OrderStatus.published]: OrdersSection.choose,
    [OrderStatus.inWork]: OrdersSection.inProgress,
    [OrderStatus.expired]: OrdersSection.expired,
    [OrderStatus.canceled]: OrdersSection.canceled,
    [OrderStatus.completed]: OrdersSection.completed,
} as const;

export const getOrderSection = ({ order, bids_count }: IExtendedOrder): OrdersSection => {
    if (!Object.values(OrderStatus).includes(order.status)) {
        // eslint-disable-next-line no-console
        console.error(`Unknown order status: ${order.status}`);
        return OrdersSection.unknown;
    }
    if (order.status === OrderStatus.published && !bids_count) {
        return OrdersSection.search;
    }

    return OrderStatusToSection[order.status];
};

export const selectOrdersBySection = (data?: IExtendedOrder[]) =>
    groupBy(data, getOrderSection) as Record<OrdersSection, IExtendedOrder[]>;
