import { useTranslation } from 'next-i18next';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Calendar } from '@indriver/mireska';
import { Button, Cell } from '@indriver/nova';
import { recordEvent } from 'common/lib';
import { useAnalyticsPayload } from 'entities/analytics';
import { showExtendOrderModal } from './model/extend-order-modal.slice';

interface IProps {
    variant: 'inCell' | 'inBottomMenu' | 'inButtonsRow' | 'inDropdown';
    bidsCount?: number;
    onClick: () => void;
}

export const ExtendOrder = ({ variant, bidsCount = 0, onClick }: IProps) => {
    const { t } = useTranslation('active_order');

    const defaultAnalyticsPayload = useAnalyticsPayload();
    const dispatch = useDispatch();

    const handleClick = () => {
        recordEvent({
            actionGA: 'masters_customer_extend_order_click',
            actionAmplitude: 'masters_customer_extend_order_click',
            actionAF: 'masters.customer.extend_order.click',
            payload: {
                ...defaultAnalyticsPayload,
            },
        });
        dispatch(showExtendOrderModal());
        onClick();
    };

    const renderButton = () => {
        if (variant === 'inCell') {
            return (
                <Button
                    design='secondary'
                    size='m'
                    prefix={<Calendar size={24} color='var(--text-and-icon-primary)' />}
                    onClick={handleClick}>
                    {t('extend_button')}
                </Button>
            );
        }
        if (variant === 'inBottomMenu' && bidsCount > 0) {
            return (
                <Button design='secondary' size='l' onClick={handleClick}>
                    {t('extend_order_button')}
                </Button>
            );
        }
        if (variant === 'inBottomMenu' && bidsCount === 0) {
            return (
                <Button design='primary' size='l' onClick={handleClick}>
                    {t('extend_order_button')}
                </Button>
            );
        }
        if (variant === 'inButtonsRow') {
            return (
                <Button
                    design='secondary'
                    size='m'
                    prefix={<Calendar size={24} color='var(--text-and-icon-primary)' />}
                    onClick={handleClick}>
                    {t('extend_button')}
                </Button>
            );
        }
        if (variant === 'inDropdown') {
            return (
                <Cell size='m' onClick={handleClick}>
                    {t('extend_order_button')}
                </Cell>
            );
        }

        return null;
    };

    return renderButton();
};
