import React from 'react';
import { BackArrowLeft } from '@indriver/mireska';
import { RoundButton } from '@indriver/nova';
import { variables } from '@indriver/yrel';

export const BackButton = () => (
    <RoundButton
        size='l'
        design='secondary'
        prefix={<BackArrowLeft size={24} color={variables['text-and-icon-primary']} />}
        style={{ boxShadow: 'none', margin: '0' }}
    />
);
