import { useCallback, useState } from 'react';

export const useTabs = <T extends string>(initialTab?: T | (() => T | undefined)) => {
    const [tab, setTab] = useState<T | undefined>(initialTab);

    const toggleTab = useCallback((tabId: T) => {
        setTab((value) => (value === tabId ? undefined : tabId));
    }, []);

    return { tab, setTab, toggleTab };
};
