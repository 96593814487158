const stableIndices = (prng: () => number, range: number, size: number): Set<number> => {
    const indices = new Set<number>();

    while (indices.size < size) {
        indices.add(Math.floor(prng() * range));
    }

    return indices;
};

export const stableSample = <T>(prng: () => number, array: T[], size: number): T[] =>
    [...stableIndices(prng, array.length, Math.min(size, array.length))].map((index) => array[index]);
