import { superMastersApi } from './base';
import { ICatalogServiceResponse, ICatalogSubServicesResponse } from './models';

export interface IGetServiceByCatalogIdParams {
    locale: string;
    catalogId: number;
    subServiceSlug?: string;
}

export const getServiceByCatalogId = async ({ locale, catalogId, subServiceSlug }: IGetServiceByCatalogIdParams) => {
    const response = await superMastersApi.get<ICatalogServiceResponse>(`public/catalog/service/${catalogId}`, {
        headers: { 'Accept-Language': locale },
        params: { subServiceSlug },
    });
    return subServiceSlug ? { ...response.data, sub_service_slug: subServiceSlug } : response.data;
};

export interface IGetSubServicesByCatalogIdParams {
    locale: string;
    catalogId: number;
}

export const getSubServicesByCatalogId = async ({ locale, catalogId }: IGetSubServicesByCatalogIdParams) => {
    const response = await superMastersApi.get<ICatalogSubServicesResponse>(`public/catalog/sub-service/${catalogId}`, {
        headers: { 'Accept-Language': locale },
        validateStatus: (status) => (status >= 200 && status < 300) || status === 404,
    });
    return response.data.collection;
};
