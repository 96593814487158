import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from '@indriver/nova';
import { RootState } from 'app/store';
import { usePageCity } from 'entities/city';
import { useCompleteOrder } from 'entities/order';
import { hideCompleteOrderModal } from './model/complete-order-modal.slice';

export const CompleteOrderModal = () => {
    const { t } = useTranslation('active_order');

    const isCompleteOrderModalOpen = useSelector((state: RootState) => state.completeOrderModal);
    const { id: cityId } = usePageCity();
    const dispatch = useDispatch();

    const router = useRouter();
    const { orderId } = router.query;

    const completeOrderMutation = useCompleteOrder({
        onSettled: () => dispatch(hideCompleteOrderModal()),
        cityId,
    });

    return (
        <Modal
            opened={isCompleteOrderModalOpen}
            rootSelector='#modal-root'
            title={t('modal_complete_order_heading')}
            footer={
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <Button size='s' design='error-low' onClick={() => dispatch(hideCompleteOrderModal())}>
                        {t('modal_complete_order_secondary_button')}
                    </Button>
                    <Button
                        size='s'
                        onClick={() => {
                            completeOrderMutation.mutate(orderId as string);
                        }}>
                        {t('modal_complete_order_primary_button')}
                    </Button>
                </div>
            }
            onClose={() => dispatch(hideCompleteOrderModal())}
        />
    );
};
