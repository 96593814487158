import { NextPageContext } from 'next';
import { sanitizeCanonicalUrl } from 'common/lib';
import { getDomainI18nConfig } from 'entities/domain';

export const getCanonicalLink = ({ req, asPath = '', locale }: NextPageContext) => {
    if (req === undefined) {
        return undefined;
    }

    const { domain, defaultLocale } = getDomainI18nConfig(req);
    return sanitizeCanonicalUrl(
        new URL(`https://${domain}/${locale?.toLowerCase()}${asPath}`),
        defaultLocale,
    ).toString();
};
