import { useQuery } from '@tanstack/react-query';
import { getServiceOffers, IGetCategoryOffersProps } from 'common/api/offer';

export const getGetServiceOffersKey = (params: IGetCategoryOffersProps) => ['getServiceOffers', params];

export const getGetServiceOffersOptions = (params: IGetCategoryOffersProps) => ({
    queryKey: getGetServiceOffersKey(params),
    queryFn: async () => getServiceOffers(params),
});

export const useGetServiceOffers = (params: IGetCategoryOffersProps) => useQuery(getGetServiceOffersOptions(params));
