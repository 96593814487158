import { useQuery } from '@tanstack/react-query';
import keyBy from 'lodash/keyBy';
import { getOrders } from 'common/api/order';
import { getProfileReviewIds, getReviewTags, postCheckReviewForExistence, getProfileReviews } from 'common/api/review';
import { chunkedRequest } from 'common/lib';

interface IUseGetTaskerReviewsParams {
    locale: string;
    cityId: number;
    taskerUserId: number;
    enabled: boolean;
}

export const useGetTaskerReviews = ({ locale, cityId, taskerUserId, enabled }: IUseGetTaskerReviewsParams) =>
    useQuery({
        queryKey: ['getTaskerReviews', { locale, cityId, taskerUserId }],
        queryFn: async () => {
            const reviews = await chunkedRequest(20, await getProfileReviewIds(taskerUserId, locale), async (ids) =>
                getProfileReviews({ mode: 'profile', ids }, locale),
            );
            const validReviews = reviews.filter((review) => review.order_id);

            const orders = await chunkedRequest(
                20,
                validReviews.map((review) => review.order_id),
                async (ids) => getOrders({ mode: 'profile', locale, cityId, ids }),
            );
            const ordersById = keyBy(orders, 'order.id');
            return validReviews.map((review) => ({ review, order: ordersById[review.order_id].order }));
        },
        enabled,
    });

export const usePostCheckReviewForExistence = (orderId: string) =>
    useQuery({
        queryKey: ['postCheckReviewForExistence', { orderId }],
        queryFn: async () => postCheckReviewForExistence(orderId),
    });

export const useGetReviewTags = (locale: string) =>
    useQuery({
        queryKey: ['getReviewTags', { locale }],
        queryFn: async () => getReviewTags(locale),
    });
