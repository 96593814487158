import { getAccessToken } from './auth';
import { parseJwt } from './parse-jwt';

export const getUserId = () => {
    const accessToken = getAccessToken();
    if (typeof accessToken !== 'string') {
        return undefined;
    }

    try {
        return parseJwt(accessToken).uid;
    } catch {
        return undefined;
    }
};
