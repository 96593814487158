import React from 'react';
import { StyledCustomInput, StyledIconWrapper } from './custom-date-time-input.styles';

interface IProps {
    value?: string;
    onClick?: () => void;
    placeholderText?: string;
    icon: React.ReactNode;
    invalid?: boolean;
    className?: string;
}

export const CustomDateTimeInput = React.forwardRef<HTMLButtonElement, IProps>((props, ref) => (
    <StyledCustomInput
        ref={ref}
        type='button'
        value={props.value as string}
        invalid={props.invalid}
        onClick={props.onClick}>
        <StyledIconWrapper>{props.icon}</StyledIconWrapper>
        {props.value ? props.value : props.placeholderText}
    </StyledCustomInput>
));
