const DOMAINS = [
    {
        domain: 'inservices.com.br',
        countryIso: 'br',
    },
    {
        domain: 'services.indrive.cl',
        countryIso: 'cl',
    },
    {
        domain: 'services.indrive.com.co',
        countryIso: 'co',
    },
    {
        domain: 'services.indrive.ec',
        countryIso: 'ec',
    },
    {
        domain: 'services.indrive.mx',
        countryIso: 'mx',
    },
    {
        domain: 'services.indrive.pe',
        countryIso: 'pe',
    },
    {
        domain: 'inservices.kz',
        countryIso: 'kz',
    },
];

export const getDomains = () => DOMAINS;
