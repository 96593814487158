import partition from 'lodash/partition';
import { ICity, ICountry, IRecommendedCity } from 'common/api';
import type { ICityInfo } from './types';

export const cityInfoFromCountryAndCity = (country: ICountry, city: ICity): ICityInfo => ({
    id: city.id,
    name: city.name,
    citySlug: city.slug,
    countryIso: country.iso,
    countryName: country.name,
    countryId: country.id,
    countrySlug: country.slug,
});

const fromRecommendedCity = (recommendedCity: IRecommendedCity): ICityInfo => ({
    id: recommendedCity.id,
    name: recommendedCity.name,
    citySlug: '',
    countryIso: recommendedCity.country.code.String,
    countryName: recommendedCity.country.name,
    countryId: recommendedCity.country.id,
    countrySlug: '',
});

export const citySelector = (countries: ICountry[], citySlug?: string): ICityInfo | undefined => {
    if (citySlug === undefined) {
        return undefined;
    }

    for (const country of countries) {
        for (const city of country.cities) {
            if (city.slug === citySlug) {
                return cityInfoFromCountryAndCity(country, city);
            }
        }
    }

    return undefined;
};

export const pickTopCities = (countries: ICountry[], countryIso: string): ICityInfo[] => {
    const foundCountry = countries.find((country) => country.iso === countryIso);
    return foundCountry?.cities.map((city) => cityInfoFromCountryAndCity(foundCountry, city)) ?? [];
};

export const aggregateCities = (countries: ICountry[]): ICityInfo[] =>
    countries.flatMap((country) => country.cities.map((city) => cityInfoFromCountryAndCity(country, city)));

export const citiesBySlugSelector = (countries: ICountry[]): Record<ICityInfo['citySlug'], ICityInfo> =>
    Object.fromEntries(aggregateCities(countries).map((cityInfo) => [cityInfo.citySlug, cityInfo]));

export const domainCitiesSelector = (countries: ICountry[], countryIso: ICountry['iso']): ICityInfo[] => {
    const domainCountry = countries.find((country) => country.iso === countryIso);
    return aggregateCities(domainCountry ? [domainCountry] : countries);
};

export const priorityCitiesSelector = (countries: ICountry[], countryIso?: ICountry['iso']): ICityInfo[] =>
    aggregateCities(countryIso ? partition(countries, (country) => country.iso === countryIso).flat() : countries);

export interface ICityRecommendationNotFound {
    type: 'city_not_found';
    cityInfo: undefined;
}

export interface ICityRecommendationSuccess {
    type: 'city_success' | 'city_not_present';
    cityInfo: ICityInfo;
}

export type ICityRecommendation = ICityRecommendationNotFound | ICityRecommendationSuccess;

export const cityRecommendationSelector = (
    recommendedCity?: IRecommendedCity,
    citiesList?: ICityInfo[],
): ICityRecommendation => {
    if (recommendedCity === undefined) {
        return { type: 'city_not_found', cityInfo: undefined };
    }

    const targetCity = citiesList?.find((cityInfo) => cityInfo.id === recommendedCity.id);
    if (!targetCity) {
        return { type: 'city_not_present', cityInfo: fromRecommendedCity(recommendedCity) };
    }

    return { type: 'city_success', cityInfo: targetCity };
};

export const recommendedCitySelector = (
    recommendedCity?: IRecommendedCity,
    citiesList?: ICityInfo[],
): ICityInfo | undefined => {
    if (recommendedCity === undefined) {
        return undefined;
    }

    return citiesList?.find((cityInfo) => cityInfo.id === recommendedCity.id) ?? fromRecommendedCity(recommendedCity);
};
