import { superMastersApi } from './base';
import { ITerms, IUserShort } from './models';

export const getUserInfo = async () => {
    const response = await superMastersApi.get<IUserShort>('user');
    return response.data;
};

export const updateUserInfo = async (infoUser: IUserShort) => {
    const response = await superMastersApi.patch('user', infoUser);
    return response.data;
};

export const updateUserLocale = async (locale: string) => {
    const response = await superMastersApi.patch('user/locale', { locale });
    return response.data;
};

export const getTerms = async (country_iso: string, lang_iso: string) => {
    const response = await superMastersApi.get<ITerms>('public/web/terms', {
        params: { country_iso: country_iso.toUpperCase(), lang_iso },
    });
    return response.data;
};
