import { useMutation, useQuery } from '@tanstack/react-query';
import { NextRouter } from 'next/router';
import { v4 as uuidv4 } from 'uuid';
import { IOrderData } from 'common/api/models';
import { getCustomerOrderIds, getOrders, postImage, postOrder } from 'common/api/order';
import { chunkedRequest, IImage } from 'common/lib';
import { imageToFormData } from 'common/lib/data-uri-to-blob';
import { selectOrdersBySection } from './query-selectors';

interface IUseGetOrdersParams {
    locale: string;
    cityId: number;
}

export const useGetOrders = ({ locale, cityId }: IUseGetOrdersParams) =>
    useQuery({
        queryKey: ['getOrders', { locale, cityId }],
        queryFn: async () => {
            const orderIds = await getCustomerOrderIds({ locale, cityId });
            return chunkedRequest(20, orderIds, async (ids) => getOrders({ mode: 'customer', locale, cityId, ids }));
        },
        retry: false,
        enabled: false,
    });

interface IUseGetOrdersBySectionParams {
    locale: string;
    cityId: number;
}

export const useGetOrdersBySection = ({ locale, cityId }: IUseGetOrdersBySectionParams) =>
    useQuery({
        queryKey: ['getOrders', { locale, cityId }],
        queryFn: async () => {
            const orderIds = await getCustomerOrderIds({ locale, cityId });
            return chunkedRequest(20, orderIds, async (ids) => getOrders({ mode: 'customer', locale, cityId, ids }));
        },
        select: selectOrdersBySection,
        retry: false,
    });

export const useAddImagesMutation = (cityId: number) =>
    useMutation({
        mutationKey: ['uploadImage', { cityId }],
        mutationFn: async (images: IImage[] = []) =>
            Promise.all(images.map(async (image) => postImage(imageToFormData(image), cityId))),
    });

interface IUseAddOrderMutationParams {
    locale: string;
    cityId: number;
    router: NextRouter;
}

export const useAddOrderMutation = ({ locale, cityId, router }: IUseAddOrderMutationParams) =>
    useMutation({
        mutationFn: async (orderData: Omit<IOrderData, 'idempotency_key' | 'time_zone'>) =>
            postOrder(
                {
                    ...orderData,
                    idempotency_key: uuidv4(),
                    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
                locale,
                cityId,
            ),
        onSuccess: async (data) => {
            if (localStorage.getItem('whatsapp_notification_seen') === null) {
                localStorage.setItem('whatsapp_notification_seen', 'true');
            }
            await router.push({
                pathname: `/orders/${data.id}`,
                query: { ...router.query, from: 'order mutation' },
            });
        },
    });
