interface IParams {
    url: string;
    focus?: boolean;
}

export const attemptOpenInNewTab = ({ url, focus }: IParams) => {
    const newTab = window.open(url, '_blank');
    if (focus && newTab) {
        newTab.focus();
    }
};
