import chunk from 'lodash/chunk';

export const chunkedRequest = async <T, R>(
    size: number,
    params: T[],
    fn: (params: T[]) => Promise<R[]>,
): Promise<R[]> => {
    const chunks = await Promise.all(chunk(params, size).map(fn));
    return chunks.flat();
};
