import { Close } from '@indriver/mireska';
import { StyledCloseButton } from './close-button.styles';

interface IProps {
    size?: number;
    onClick: () => void;
}

export const CloseButton = ({ size = 20, onClick }: IProps) => {
    return (
        <StyledCloseButton onClick={onClick}>
            <Close size={size} color='#626262' />
        </StyledCloseButton>
    );
};
