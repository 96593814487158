import { IImage } from './db';

const dataURIToBlob = (dataURI: string) => {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].includes('base64') ? window.atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ia], { type: mimeString });

    return new File([blob], `image.${mimeString.split('/')[1]}`, {
        lastModified: new Date().getTime(),
        type: mimeString,
    });
};

export const imageToFormData = (image: IImage) => {
    const formData = new FormData();
    formData.append('file', dataURIToBlob(image.imageFile));
    return formData;
};
