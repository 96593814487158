import { useMemo } from 'react';
import { useScript } from 'usehooks-ts';
import { isSSR } from 'common/lib';

const AF_SCRIPT_URL = 'https://onelinksmartscript.appsflyer.com/onelink-smart-script-v2.7.3.js';

export const useSmartScript = () => {
    const oneLinkScriptStatus = useScript(AF_SCRIPT_URL);
    return useMemo(
        () => (!isSSR && oneLinkScriptStatus === 'ready' ? window?.AF_SMART_SCRIPT : undefined),
        [oneLinkScriptStatus],
    );
};
