import { useTranslation } from 'next-i18next';
import { useCallback, useMemo } from 'react';
import { FavFilled } from '@indriver/mireska';
import { useBreakpoints } from 'common/lib';
import { Landscape } from 'common/ui/icons';
import { getPhotos, hasPhotos, hasReviews, ITasker } from 'entities/tasker';
import { Content as PortfolioContent } from './content/portfolio';
import { Content as ReviewsContent } from './content/reviews';
import { ITab, Tabs } from './tabs';

interface IProps {
    tasker: ITasker;
    defaultVisible?: boolean;
    disableReviews?: boolean;
    onReviewsModalOpen?: () => void;
    onReviewCardClick?: () => void;
    onGalleryModalOpen?: () => void;
    onGallerySlideClick?: () => void;
}

export const TaskerReferences = ({
    tasker,
    defaultVisible = false,
    disableReviews = false,
    onGallerySlideClick,
    onGalleryModalOpen,
    onReviewsModalOpen,
    onReviewCardClick,
}: IProps) => {
    const { t } = useTranslation();
    const media = useBreakpoints();
    const photos = useMemo(() => getPhotos(tasker), [tasker]);
    const getInitialTab = useCallback(() => {
        if (defaultVisible && photos.length > 0) {
            return 'portfolio';
        }
        if (defaultVisible && !disableReviews && tasker.reviewsCount) {
            return 'reviews';
        }

        return undefined;
    }, [defaultVisible, photos.length, disableReviews, tasker.reviewsCount]);

    const tabsById = useMemo(() => {
        const tabs: Record<ITab['id'], Omit<ITab, 'id'>> = {};

        if (hasPhotos(tasker)) {
            tabs.portfolio = {
                title: (
                    <>
                        <Landscape size={24} />
                        {t('entities.tasker.portfolio', { count: photos.length })}
                    </>
                ),
                content: (
                    <PortfolioContent
                        tasker={tasker}
                        onGallerySlideClick={onGallerySlideClick}
                        onGalleryModalOpen={onGalleryModalOpen}
                    />
                ),
            };
        }

        if (hasReviews(tasker)) {
            tabs.reviews = {
                disabled: disableReviews,
                title: (
                    <>
                        <FavFilled size={24} style={{ marginBottom: '1px' }} />
                        {t('entities.tasker.reviews', { count: tasker.reviewsCount })}
                    </>
                ),
                content: (
                    <ReviewsContent
                        tasker={tasker}
                        onReviewsModalOpen={onReviewsModalOpen}
                        onReviewCardClick={onReviewCardClick}
                    />
                ),
            };
        }

        return tabs;
    }, [
        disableReviews,
        onGalleryModalOpen,
        onGallerySlideClick,
        onReviewCardClick,
        onReviewsModalOpen,
        photos.length,
        t,
        tasker,
    ]);

    const tabsByOrder = useMemo(() => {
        const order = disableReviews ? ['reviews', 'portfolio'] : ['portfolio', 'reviews'];
        return order.filter((id) => tabsById[id]).map((id) => ({ id, ...tabsById[id] }));
    }, [disableReviews, tabsById]);

    return <Tabs size={media.isGtS ? 'm' : 'xs'} tabs={tabsByOrder} initialTab={getInitialTab} />;
};
