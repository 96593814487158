import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface IWizardState {
    currentStep: number;
}

const initialState: IWizardState = {
    currentStep: 0,
};

const { actions, reducer } = createSlice({
    name: 'wizard',
    initialState,
    reducers: {
        setCurrentStep: (state, action: PayloadAction<number>) => {
            state.currentStep = action.payload;
        },
        resetState: (state) => {
            state.currentStep = 0;
        },
    },
});

export const { setCurrentStep, resetState } = actions;

export const wizardReducer = reducer;
