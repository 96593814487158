import { i18n } from 'next-i18next';
import { DataIntersection } from 'common/api/models';

// eslint-disable-next-line complexity
export const renderValue = (type: string, field: DataIntersection) => {
    if (field.data && type === 'date') {
        return i18n?.t('intlDateTime', {
            val: new Date(field.data.time),
            formatParams: {
                val: {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                },
            },
        });
    }
    if (field.data && (type === 'address' || type === 'description' || type === 'text_field')) {
        return field.data.text;
    }
    if (field.data && type === 'price') {
        return field.data.price;
    }
    if (field.data && type === 'single_choice') {
        return field.data.choice.name;
    }
    if (field.data && type === 'multi_choice') {
        return field.data.choice_list.map((choice) => choice.name).join(', ');
    }

    return null;
};
