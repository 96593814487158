import Image from 'next/image';
import React from 'react';
import { FavFilled } from '@indriver/mireska';
import { Heading } from '@indriver/nova';
import {
    StyledAvatarWrapper,
    StyledBody,
    StyledBodyText,
    StyledFeaturePill,
    StyledMetaDataWrapper,
    StyledRatingWrapper,
    StyledReviewCard,
    StyledReviewCardFooter,
    StyledReviewCardHeader,
    StyledServiceText,
} from './legacy-review-card.styles';

interface IProps {
    imageSrc: string;
    name: string;
    serviceName: string;
    reviewText: string;
    pills: string[];
}

export const LegacyReviewCard = ({ imageSrc, name, serviceName, reviewText, pills }: IProps) => {
    return (
        <StyledReviewCard>
            <StyledReviewCardHeader>
                <StyledAvatarWrapper>
                    <Image src={imageSrc} alt='' width='40' height='40' />
                </StyledAvatarWrapper>
                <StyledMetaDataWrapper>
                    <Heading type='h3' size='m'>
                        {name}
                    </Heading>
                    <StyledRatingWrapper>
                        <FavFilled size={16} color='#FFCE51' />
                        <FavFilled size={16} color='#FFCE51' />
                        <FavFilled size={16} color='#FFCE51' />
                        <FavFilled size={16} color='#FFCE51' />
                        <FavFilled size={16} color='#FFCE51' />
                    </StyledRatingWrapper>
                    <StyledServiceText>{serviceName}</StyledServiceText>
                </StyledMetaDataWrapper>
            </StyledReviewCardHeader>
            <StyledBody>
                <StyledBodyText>{reviewText}</StyledBodyText>
            </StyledBody>
            <StyledReviewCardFooter>
                {pills.map((pill, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <StyledFeaturePill key={index}>{pill}</StyledFeaturePill>
                ))}
            </StyledReviewCardFooter>
        </StyledReviewCard>
    );
};
