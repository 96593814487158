import ClickAwayListener from '@mui/base/ClickAwayListener';
import { useRouter } from 'next/router';
import React from 'react';
import { usePopper } from 'react-popper';
import { ChevronRightSmall, MoreHoriz } from '@indriver/mireska';
import { Group, RoundButton, Text } from '@indriver/nova';
import { OrderStatusAsUnion } from 'common/api';
import { CategoryIcon, Portal } from 'common/ui';
import * as Styled from './active-order-cell.styles';

interface IOrder {
    catalogName: string;
    dateTime: string;
    categoryIconUrl: string;
}

interface IActiveOrderCellMobileProps {
    order: IOrder;
    loading: boolean;
    status: OrderStatusAsUnion;
}

export const ActiveOrderCellMobile = ({ order, loading, status }: IActiveOrderCellMobileProps) => {
    const router = useRouter();
    const { orderId } = router.query;

    const handleClick = async () => {
        if (status === 'in_work' || status === 'completed') {
            await router.push(`/orders/${orderId}/details`);
        } else {
            await router.push(`/orders/${orderId}/edit`);
        }
    };

    return (
        <Styled.MobileCell
            prefix={<CategoryIcon src={order.categoryIconUrl} alt='' size={24} />}
            size='m'
            loading={loading}
            subtitle={order.dateTime}
            postfix={<ChevronRightSmall size={24} color='var(--text-and-icon-secondary)' />}
            onClick={handleClick}>
            {order.catalogName}
        </Styled.MobileCell>
    );
};

interface IActiveOrderCellDesktopProps {
    order: IOrder;
    editOrderButton: React.ReactNode;
    droplistContent: React.ReactNode;
    additionalButton: React.ReactNode;
    isDroplistOpen: boolean;
    setIsDroplistOpen: React.Dispatch<React.SetStateAction<boolean>>;
    shouldShowMoreButton: boolean;
}

export const ActiveOrderCellDesktop = ({
    order,
    editOrderButton,
    droplistContent,
    additionalButton,
    isDroplistOpen,
    setIsDroplistOpen,
    shouldShowMoreButton,
}: IActiveOrderCellDesktopProps) => {
    const [referenceElement, setReferenceElement] = React.useState<HTMLButtonElement | null>(null);
    const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'top-end',
        strategy: 'fixed',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            },
        ],
    });

    return (
        <>
            <Styled.DesktopCell>
                <Group gap={4} direction='column'>
                    <Text size='l' color='var(--text-and-icon-primary)' weight='bold'>
                        {order.catalogName}
                    </Text>
                    <Text size='ml'>{order.dateTime}</Text>
                </Group>
                <Styled.Buttons>
                    {additionalButton}
                    {editOrderButton}
                    {shouldShowMoreButton && (
                        <ClickAwayListener onClickAway={() => setIsDroplistOpen(false)}>
                            <div>
                                <RoundButton
                                    size='m'
                                    design='secondary'
                                    prefix={<MoreHoriz size={24} />}
                                    ref={setReferenceElement}
                                    style={{ boxShadow: 'none' }}
                                    onClick={() => setIsDroplistOpen((value) => !value)}
                                />
                                {isDroplistOpen ? (
                                    <Portal portalId='popper'>
                                        <Styled.Droplist
                                            ref={setPopperElement}
                                            {...attributes.popper}
                                            style={styles.popper}>
                                            {droplistContent}
                                        </Styled.Droplist>
                                    </Portal>
                                ) : null}
                            </div>
                        </ClickAwayListener>
                    )}
                </Styled.Buttons>
            </Styled.DesktopCell>
        </>
    );
};
