import React from 'react';
import { useBoolean } from 'usehooks-ts';
import { ChevronDown, ChevronUp } from '@indriver/mireska';
import { useBreakpoints } from '../../lib';
import * as Styled from './details.styles';

export interface IDetailsProps {
    summary: React.ReactNode;
    details: React.ReactNode;
    expanded?: boolean;
}

export const Details = ({ summary, details, expanded }: IDetailsProps) => {
    const isExpanded = useBoolean(expanded);
    const media = useBreakpoints();

    return (
        <Styled.Box expanded={isExpanded.value}>
            <Styled.Content>
                <Styled.Summary>{summary}</Styled.Summary>
                {isExpanded.value && <Styled.Text>{details}</Styled.Text>}
            </Styled.Content>
            <Styled.Button
                design='secondary'
                size={media.isGtL ? '2xl' : 'm'}
                withoutShadow
                prefix={
                    isExpanded.value ? (
                        <ChevronUp size={media.isGtL ? 24 : 16} />
                    ) : (
                        <ChevronDown size={media.isGtL ? 24 : 16} />
                    )
                }
                onClick={isExpanded.toggle}
            />
        </Styled.Box>
    );
};
