import { digestId, lerp } from 'common/lib';
import { superMastersApi } from './base';
import { ICatalogPromotionResponse, ICatalogResponse, ICatalogServiceSearchResult } from './models';

export interface IGetCatalogParams {
    cityId: number;
    locale: string;
}

export const getCatalog = async ({ cityId, locale }: IGetCatalogParams) => {
    const response = await superMastersApi.get<ICatalogResponse>('public/catalog', {
        params: { cityID: cityId },
        headers: { 'Accept-Language': locale },
    });
    return response.data.collection;
};

export interface IGetCatalogPromotionParams {
    cityId: number;
    locale: string;
}

export const getCatalogPromotion = async ({ cityId, locale }: IGetCatalogPromotionParams) => {
    const response = await superMastersApi.get<ICatalogPromotionResponse>('public/catalog/promotion', {
        params: { cityID: cityId },
        headers: { 'Accept-Language': locale },
    });
    return response.data.collection;
};

export interface IServiceRatingParams {
    catalogId: number;
}

interface ISearchCatalogParams {
    locale: string;
    cityId: number;
    query: string;
}

export const searchCatalog = async ({ locale, cityId, query }: ISearchCatalogParams, signal?: AbortSignal) => {
    const response = await superMastersApi.get<ICatalogServiceSearchResult>('public/catalog/search', {
        params: {
            cityID: cityId,
            query,
        },
        headers: { 'Accept-Language': locale },
        signal,
    });

    return response.data.collection;
};

export const getServiceRating = async ({ catalogId }: IServiceRatingParams) =>
    lerp((await digestId(catalogId))[0] / 0xff, 4.1 / 5, 4.9 / 5);

export interface IServiceReviewsCountParams {
    catalogId: number;
}

export const getServiceReviewsCount = async ({ catalogId }: IServiceReviewsCountParams) =>
    Math.round(lerp((await digestId(catalogId))[1] / 0xff, 300, 5000));
