import type { Locale } from 'date-fns';
import { ar, kk, ru, es, ptBR, enGB } from 'date-fns/locale';
import { useRouter } from 'next/router';

export const LANG: Record<string, Locale> = {
    ar,
    kk,
    ru,
    en: enGB,
    'es-MX': es,
    'pt-BR': ptBR,
} as const;

export const getDateFnsLocale = (locale: string) => LANG[locale];

export const useDateFnsLocale = () => LANG[useRouter().locale ?? 'en'];
