import throttle from 'lodash/throttle';
import React from 'react';
import { useLatest } from 'common/lib';

export type FetchDataFn<Result = unknown> = (query: string, signal: AbortSignal) => Promise<Result | null>;

export interface IUseThrottledFetchReturnType<T> {
    data: T | null;
    throttledFetch: (query: string) => Promise<void> | void;
    resetData: () => void;
    isPending: boolean;
}

export const useThrottledFetch = <T>(fetchData: FetchDataFn<T>, delay = 500): IUseThrottledFetchReturnType<T> => {
    const [data, setData] = React.useState<T | null>(null);
    const [isPending, setPending] = React.useState(false);

    const abortControllersRef = React.useRef(new Set<AbortController>());
    const fetchDataRef = useLatest(fetchData); // custom hook based on useRef to prevent frequent fetchData updates

    // Cancel all the controllers before actualController or all the controllers
    const cancelAllPreviousRequests = React.useCallback((actualController?: AbortController) => {
        for (const controller of Array.from(abortControllersRef.current)) {
            if (controller === actualController) {
                break;
            }

            controller.abort();
        }
    }, []);

    const throttledFetch = React.useMemo(
        () =>
            throttle(async (query: string) => {
                const abortController = new AbortController();
                abortControllersRef.current.add(abortController);

                try {
                    setPending(true);
                    const response = await fetchDataRef.current(query, abortController.signal);
                    setData(response);
                    cancelAllPreviousRequests(abortController); // cancel all the requests before the current abortController
                } finally {
                    abortControllersRef.current.delete(abortController);
                    setPending(abortControllersRef.current.size > 0);
                }
            }, delay),
        [cancelAllPreviousRequests, fetchDataRef, delay],
    );

    const resetData = React.useCallback(() => {
        cancelAllPreviousRequests();
        throttledFetch.cancel();
        setData(null);
    }, [cancelAllPreviousRequests, throttledFetch]);

    return {
        data,
        throttledFetch,
        resetData,
        isPending,
    };
};
