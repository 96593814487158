import { countries, CountriesCodesType } from '@indriver/nova-core';

export const parsePhoneNumber = (fullNumber: string, country: CountriesCodesType): [string, string] => {
    const areaCode = countries[country].code.replace(/\D/g, '');

    if (!fullNumber.startsWith(areaCode)) {
        throw new Error("Number doesn't start with proper area code");
    }

    return [areaCode, fullNumber.slice(areaCode.length)];
};
