import { Avatar, IAvatarProps } from '@indriver/nova';
import { IUser } from 'common/api';

interface IProps extends IAvatarProps {
    user: Pick<IUser, 'avatar' | 'first_name'>;
}

export const UserAvatar = ({ user, ...props }: IProps) => (
    <Avatar image={user.avatar} content={user.first_name} {...props} />
);
