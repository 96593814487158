import round from 'lodash/round';
import React from 'react';
import { FavFilled } from '@indriver/mireska';
import { Avatar, Badge } from '@indriver/nova';

interface IAvatarWithBadgeProps {
    avatarSize: 'm' | 'xxl' | 'xxxl';
    badgeSize: 's' | 'm';
    content?: string | JSX.Element;
    iconSize: 12 | 16;
    image?: string;
    rating?: number;
    overrideTopNotification?: JSX.Element;
}

export const AvatarWithBadge: React.FC<IAvatarWithBadgeProps> = ({
    avatarSize,
    badgeSize,
    content,
    iconSize,
    image,
    rating,
    overrideTopNotification,
}: IAvatarWithBadgeProps) => {
    let topNotification = rating ? (
        <Badge size={badgeSize} prefix={<FavFilled size={iconSize} />}>
            {round(rating, 1).toFixed(1)}
        </Badge>
    ) : undefined;

    if (overrideTopNotification) {
        topNotification = overrideTopNotification;
    }

    return <Avatar size={avatarSize} image={image} content={content} topNotification={topNotification} />;
};
