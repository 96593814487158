import { createContext, useContext } from 'react';
import { IDomainContext } from './types';

const defaultValue: IDomainContext = {
    domain: 'services.indrive.com',
    locales: ['en', 'es-MX', 'kk', 'pt-BR', 'ru'],
    defaultLocale: 'en',
    defaultCitySlug: 'almaty',
};

const Context = createContext<IDomainContext>(defaultValue);

export const useDomainInfo = () => useContext(Context);

export const ContextProvider = Context.Provider;
