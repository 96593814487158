import { ReactNode, useCallback, useState } from 'react';
import { IInputProps, Input } from '@indriver/nova';

interface IProps<T> extends IInputProps {
    items: T[];
    filter: (searchBy: string, item: T) => boolean;
    children: (items: T[]) => ReactNode;
}

export const AutocompleteFlat = <T,>({ items, filter, children, ...inputProps }: IProps<T>) => {
    const [searchBy, setSearchBy] = useState('');
    const handleChange = useCallback((e, { value }) => setSearchBy(value), []);
    const filteredItems = items.filter((item) => filter(searchBy, item));
    return (
        <>
            <Input autoComplete='off' value={searchBy} onChange={handleChange} {...inputProps} />
            {children(filteredItems)}
        </>
    );
};
