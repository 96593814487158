export const stripUrlLocale = (url: URL, locale: string) => {
    const [, urlLocale] = url.pathname.split('/');
    if (urlLocale?.toLowerCase() !== locale.toLowerCase()) {
        return url;
    }

    url.pathname = url.pathname.slice(locale.length + 1);
    return url;
};

export const stripUrlTrailingSlash = (url: URL) => {
    url.pathname = url.pathname.replace(/\/$/, '');
    return url;
};

export const stripUrlQueryString = (url: URL) => {
    url.search = '';
    return url;
};

export const stripCitySlug = (url: URL) => {
    url.searchParams.delete('citySlug');
    return url;
};

export const lowercasePathname = (url: URL) => {
    url.pathname = url.pathname.toLowerCase();
    return url;
};

export const sanitizeCanonicalUrl = (url: URL, defaultLocale?: string) => {
    if (defaultLocale) {
        stripUrlLocale(url, defaultLocale);
    }
    stripUrlTrailingSlash(url);
    lowercasePathname(url);
    stripUrlQueryString(url);
    return url;
};

export const sanitizeRedirectUrl = (url: URL, defaultLocale?: string) => {
    if (defaultLocale) {
        stripUrlLocale(url, defaultLocale);
    }
    stripUrlTrailingSlash(url);
    lowercasePathname(url);
    stripCitySlug(url);
    return url;
};
