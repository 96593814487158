export const MASTERS_LANDINGS_BY_COUNTRY: Record<string, string> = {
    kz: 'https://lp-services.indrive.com/specialist-ru',
    br: 'https://lp-services.indrive.com/specialist-pt',
    co: 'https://lp-services.indrive.com/specialist-co',
    mx: 'https://lp-services.indrive.com/specialist-mx',
    en: 'https://lp-services.indrive.com/specialist-en',
};

export const getLandingLink = (countryIso: string) =>
    MASTERS_LANDINGS_BY_COUNTRY[countryIso] ?? MASTERS_LANDINGS_BY_COUNTRY.en;
