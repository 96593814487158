import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from '@indriver/nova';
import { RootState } from 'app/store';
import type { OrderField } from 'common/api';
import { toastSuccess } from 'common/ui';
import { usePageCity } from 'entities/city';
import { useGetOrder, useUpdateOrder } from 'entities/order';
import { DatetimeField } from 'features/datetime-field';
import * as Styled from './extend-order.styles';
import { hideExtendOrderModal } from './model/extend-order-modal.slice';

export const ExtendOrderModal = () => {
    const [date, setDate] = React.useState<Date | null>(new Date());

    const isExtendOrderModalOpen = useSelector((state: RootState) => state.extendOrderModal);
    const { id: cityId } = usePageCity();

    const dispatch = useDispatch();

    const { t, i18n } = useTranslation('active_order');

    const router = useRouter();
    const { orderId } = router.query;

    const orderQuery = useGetOrder({ orderId: orderId as string, locale: i18n.resolvedLanguage, cityId });
    const dateField = orderQuery.data?.order.fields.find((field) => field.type === 'date');

    const updateOrderMutation = useUpdateOrder({
        onSuccess: () => {
            toastSuccess({
                message: t('toast_order_extended_success'),
                toastId: 'update-order-success',
                position: 'top-right',
            });
            dispatch(hideExtendOrderModal());
        },
        cityId,
    });

    const handleFormSubmit = (values: { datetime_extend: Record<string, unknown> }) => {
        const prevOrderFields = orderQuery.data?.order.fields;
        const orderFieldsWithReplacedDate = prevOrderFields?.map((field) => {
            if (field.type === 'date') {
                return {
                    ...field,
                    data: {
                        ...values.datetime_extend,
                    },
                };
            }

            return field;
        });

        updateOrderMutation.mutate({
            orderId: orderId as string,
            updateOrderBody: {
                fields: orderFieldsWithReplacedDate as OrderField[],
            },
        });
    };

    const handleModalClose = () => {
        dispatch(hideExtendOrderModal());
    };

    return (
        <Modal
            opened={isExtendOrderModalOpen}
            rootSelector='#modal-root'
            title={t('modal_extend_order_heading')}
            onClose={handleModalClose}>
            <Form subscription={{ submitting: true, dirty: true }} onSubmit={handleFormSubmit}>
                {({ handleSubmit, dirty }) => (
                    <form onSubmit={handleSubmit}>
                        <DatetimeField
                            name='datetime_extend'
                            label={dateField?.description as string}
                            required
                            date={date}
                            setDate={setDate}
                            inline
                            startDateForOrderExtension={new Date(dateField?.data.time as string)}
                        />
                        <Styled.Footer>
                            <Button size='s' design='error-low' onClick={handleModalClose}>
                                {t('modal_extend_order_secondary_button')}
                            </Button>
                            <Button size='s' type='submit' disabled={!dirty} loading={updateOrderMutation.isLoading}>
                                {t('modal_extend_order_primary_button')}
                            </Button>
                        </Styled.Footer>
                    </form>
                )}
            </Form>
        </Modal>
    );
};
