import { useTranslation } from 'next-i18next';
import { useMemo, useState } from 'react';
import { useBoolean } from 'usehooks-ts';
import { Carousel, Gallery, Portal } from 'common/ui';
import { getPhotos } from '../../../lib/photos';
import { ITasker } from '../../../model/types';
import * as Styled from './portfolio.styles';

interface IProps {
    tasker: ITasker;
    onGalleryModalOpen?: () => void;
    onGallerySlideClick?: () => void;
}

export const Content = ({ tasker, onGalleryModalOpen, onGallerySlideClick }: IProps) => {
    const { t } = useTranslation();

    const photos = useMemo(() => getPhotos(tasker), [tasker]);

    const galleryVisibility = useBoolean();
    const [galleryIndex, setGalleryIndex] = useState(0);

    const handleGalleryModalOpen = () => {
        galleryVisibility.setTrue();
        onGalleryModalOpen?.();
    };

    const handleGallerySlideClick = (i: number) => {
        setGalleryIndex(i);
        galleryVisibility.setTrue();
        onGallerySlideClick?.();
    };

    return (
        <div>
            <Carousel onFullscreenClick={handleGalleryModalOpen}>
                <Styled.Portfolio>
                    {photos.map((url, i) => (
                        <Styled.Slide key={url} onClick={() => handleGallerySlideClick(i)}>
                            <Styled.SlideImage src={url} />
                        </Styled.Slide>
                    ))}
                </Styled.Portfolio>
            </Carousel>
            {galleryVisibility.value && (
                <Portal portalId='gallery-portal'>
                    <Gallery
                        title={t('entities.tasker.portfolio_title', { name: tasker.name })}
                        images={photos}
                        index={galleryIndex}
                        onClose={galleryVisibility.setFalse}
                    />
                </Portal>
            )}
        </div>
    );
};
