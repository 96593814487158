import React from 'react';
import { useBreakpoints } from 'common/lib';
import * as Styled from './wizard-like-layout.styles';

interface IProps {
    field: React.ReactNode;
    buttons: React.ReactNode;
    hintDesktop: React.ReactNode;
    hintMobile: React.ReactNode;
    error?: React.ReactNode;
    step?: 'phone';
}

export const WizardLikeLayout = ({ field, buttons, hintDesktop, hintMobile, error, step }: IProps) => {
    const media = useBreakpoints();

    return (
        <Styled.WizardLikeLayout>
            <Styled.ChildrenWrapper>
                {field}
                {error}
                <Styled.ButtonsWrapper>{buttons}</Styled.ButtonsWrapper>
            </Styled.ChildrenWrapper>
            {media.isGtS ? (
                <>{hintDesktop}</>
            ) : (
                <Styled.InlineAlertWrapper className={step === 'phone' ? Styled.phoneAlertWrapper : undefined}>
                    {hintMobile}
                </Styled.InlineAlertWrapper>
            )}
        </Styled.WizardLikeLayout>
    );
};
