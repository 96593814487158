import { superMastersApi } from './base';
import { IWizardResponse } from './models';

interface IGetWizardByCatalogIdParams {
    locale: string;
    catalogId: number;
    subServiceSlug?: string;
}

export const getWizardByCatalogId = async ({ locale, catalogId, subServiceSlug }: IGetWizardByCatalogIdParams) => {
    const response = await superMastersApi.get<IWizardResponse>(`public/catalog/wizard/${catalogId}`, {
        headers: { 'Accept-Language': locale },
        params: { subServiceSlug },
    });
    return response.data;
};
