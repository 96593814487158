import { captureException } from '@sentry/minimal';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import getConfig from 'next/config';
import { getAccessToken, getRefreshToken, setAccessToken } from 'common/lib/auth';
import { ICheckAuthCodeResponse } from './models';

const { publicRuntimeConfig } = getConfig();

export const superMastersApi = axios.create({
    baseURL: `${publicRuntimeConfig.API_URL_SUPER_SERVICES}/api/super-masters/v1web`,
    timeout: 3000,
    headers: {
        'X-App': 'web',
    },
});

superMastersApi.interceptors.request.use(async (config) => {
    const accessToken = getAccessToken() as string;
    if (accessToken && config?.headers) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
});

superMastersApi.interceptors.response.use(null, (error) => {
    captureException(error);
    throw error;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const refreshAuthLogic = async (failedRequest: any) => {
    return axios
        .post<ICheckAuthCodeResponse>('/api/auth/v1/token/renew', {
            refresh_token: getRefreshToken(),
        })
        .then(async (tokenRefreshResponse) => {
            setAccessToken({
                accessToken: tokenRefreshResponse.data.access_token,
                refreshToken: tokenRefreshResponse.data.refresh_token,
            });
            failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.data.access_token}`;
            return Promise.resolve();
        });
};

createAuthRefreshInterceptor(superMastersApi, refreshAuthLogic, {
    pauseInstanceWhileRefreshing: false,
});
