import { ITaskerInfo, ITaskerInfoDescriptionField, ITaskerInfoField } from 'common/api';

const isFieldDescription = (field: ITaskerInfoField): field is ITaskerInfoDescriptionField =>
    field.type === 'description';

const getFieldText = (field: ITaskerInfoDescriptionField): string => field.data.text.trim();

const isValidDescription = (field: ITaskerInfoField): field is ITaskerInfoDescriptionField =>
    isFieldDescription(field) && getFieldText(field).length !== 0;

export const hasDescription = (tasker: ITaskerInfo): boolean => tasker.fields.some(isValidDescription);

export const getDescription = (tasker: ITaskerInfo): string =>
    tasker.fields.filter(isValidDescription).map(getFieldText).join(' ');
