import { useTranslation } from 'next-i18next';
import React from 'react';
import { useBoolean } from 'usehooks-ts';
import { Skeleton } from '@indriver/nova';
import { Carousel } from 'common/ui';
import { usePageCity } from 'entities/city';
import { ReviewCard, useGetTaskerReviews } from 'entities/review';
import { ReviewsModal } from 'widgets/reviews-modal';
import { ITasker } from '../../../model/types';
import * as Styled from './reviews.styles';

interface IProps {
    tasker: ITasker;
    onReviewsModalOpen?: () => void;
    onReviewCardClick?: () => void;
}

export const Content = ({ tasker, onReviewsModalOpen, onReviewCardClick }: IProps) => {
    const { t, i18n } = useTranslation();
    const pageCity = usePageCity();
    const reviewsModalVisibility = useBoolean();
    const taskerReviewsQuery = useGetTaskerReviews({
        locale: i18n.resolvedLanguage,
        taskerUserId: tasker.userId,
        cityId: pageCity.id,
        enabled: true,
    });

    const handleReviewsModalOpen = () => {
        reviewsModalVisibility.setTrue();
        onReviewsModalOpen?.();
    };

    const handleReviewCardClick = () => {
        reviewsModalVisibility.setFalse();
        onReviewCardClick?.();
    };

    if (taskerReviewsQuery.isError) {
        return <span>{t('common.error.unknown')}</span>;
    }

    if (taskerReviewsQuery.isLoading) {
        return (
            <div>
                <Carousel onFullscreenClick={handleReviewsModalOpen}>
                    <Styled.EmblaContainer>
                        <Styled.EmblaSlide>
                            <Skeleton width='100%' height='180px' rounding={8} />
                        </Styled.EmblaSlide>
                        <Styled.EmblaSlide>
                            <Skeleton width='100%' height='180px' rounding={8} />
                        </Styled.EmblaSlide>
                        <Styled.EmblaSlide>
                            <Skeleton width='100%' height='180px' rounding={8} />
                        </Styled.EmblaSlide>
                        <Styled.EmblaSlide>
                            <Skeleton width='100%' height='180px' rounding={8} />
                        </Styled.EmblaSlide>
                    </Styled.EmblaContainer>
                </Carousel>
                {reviewsModalVisibility.value && (
                    <ReviewsModal
                        taskerRating={tasker.rating}
                        reviewsCount={tasker.reviewsCount}
                        onClose={reviewsModalVisibility.setFalse}>
                        <Styled.EmblaSlide>
                            <Skeleton width='100%' height='180px' rounding={8} />
                        </Styled.EmblaSlide>
                        <Styled.EmblaSlide>
                            <Skeleton width='100%' height='180px' rounding={8} />
                        </Styled.EmblaSlide>
                        <Styled.EmblaSlide>
                            <Skeleton width='100%' height='180px' rounding={8} />
                        </Styled.EmblaSlide>
                    </ReviewsModal>
                )}
            </div>
        );
    }

    return (
        <div>
            <Carousel onFullscreenClick={handleReviewsModalOpen}>
                <Styled.EmblaContainer>
                    {taskerReviewsQuery.data?.map((review) => (
                        <Styled.EmblaSlide key={review.review.id}>
                            <ReviewCard review={review.review} order={review.order} onClick={handleReviewCardClick} />
                        </Styled.EmblaSlide>
                    ))}
                </Styled.EmblaContainer>
            </Carousel>
            {reviewsModalVisibility.value && (
                <ReviewsModal
                    taskerRating={tasker.rating}
                    reviewsCount={tasker.reviewsCount}
                    onClose={reviewsModalVisibility.setFalse}>
                    {taskerReviewsQuery.data?.map((review) => (
                        <ReviewCard key={review.review.id} review={review.review} order={review.order} isInModal />
                    ))}
                </ReviewsModal>
            )}
        </div>
    );
};
