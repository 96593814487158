import type { QueryClient } from '@tanstack/react-query';
import { setCookie } from 'cookies-next';
import type { GetServerSidePropsContext } from 'next/types';
import requestIp from 'request-ip';
import { logger } from '@indriver/elastic-logger';
import { city, geo } from 'common/api';
import { MAX_COOKIE_AGE } from 'common/constants/max-cookie-age';
import { getGetTogglesQueryOptions } from 'entities/ab';
import { getDeviceId, getUserId, DEVICE_ID_COOKIE } from './tracking';

export const getCommonParams = async (
    queryClient: QueryClient,
    { req, res }: GetServerSidePropsContext,
): Promise<void> => {
    const prefetches: Record<string, Promise<unknown>> = {
        countries: queryClient.fetchQuery({
            queryKey: ['getCountries'],
            queryFn: city.getCountries,
        }),
    };

    const ip = requestIp.getClientIp(req);
    if (typeof ip === 'string') {
        prefetches.recommendedCities = queryClient.fetchQuery({
            queryKey: ['getRecommendedCity'],
            queryFn: async () => geo.getNearCities(ip),
            staleTime: Infinity,
        });
    } else {
        logger.warn("Couldn't get ip from request");
    }

    const deviceId = getDeviceId(req);
    const userId = getUserId(req);

    // renews cookie with every request, making it effectively permanent
    setCookie(DEVICE_ID_COOKIE, deviceId, { req, res, maxAge: MAX_COOKIE_AGE });

    prefetches.toggles = queryClient.fetchQuery({
        ...getGetTogglesQueryOptions({ namespace: 'super_masters_web', device_id: deviceId, user_id: userId }),
        staleTime: Infinity,
    });

    await Promise.allSettled(
        Object.entries(prefetches).map(async ([key, promise]) => {
            try {
                return [key, await promise];
            } catch (error) {
                logger.error(error);
                throw error;
            }
        }),
    );
};
