import { useTranslation } from 'next-i18next';
import { InlineItem } from 'common/ui';
import { ITasker } from '../../model/types';

interface IProps {
    tasker: ITasker;
}

export const Portfolio = ({ tasker }: IProps) => {
    const { t } = useTranslation();
    return tasker.portfolio ? <InlineItem>{t('entities.tasker.has_portfolio')}</InlineItem> : null;
};
