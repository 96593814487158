import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import { Heading, RatingBar, Tag, Text } from '@indriver/nova';
import { IOrder, IReview } from 'common/api';
import { formatName } from 'common/lib';
import { useDateFnsLocale } from 'common/lib/date-fns-i18n';
import { InlineItem, ClampedText } from 'common/ui';
import { UserAvatar } from 'entities/user';
import * as Styled from './review-card.styles';

interface IProps {
    order: IOrder;
    review: IReview;
    isInModal?: boolean;
    onClick?: () => void;
}

export const ReviewCard = ({ order, review, isInModal = false, onClick }: IProps) => {
    const locale = useDateFnsLocale();
    return (
        <Styled.Card isInModal={isInModal} onClick={onClick}>
            <Styled.HeaderSection>
                <UserAvatar size='l' user={order.user} design={isInModal ? 'neutral' : 'neutral-inverse'} />
                <div>
                    <Heading type='h2' size='m'>
                        {formatName(order.user)}
                    </Heading>
                    <Text size='s' color='var(--text-and-icon-secondary)'>
                        {review.created_at && (
                            <InlineItem>
                                <Styled.Nowrap>
                                    {formatDistanceToNowStrict(new Date(review.created_at), {
                                        locale,
                                        addSuffix: true,
                                    })}
                                </Styled.Nowrap>
                            </InlineItem>
                        )}
                        <InlineItem>
                            <Styled.Wrap>{order.catalog_name}</Styled.Wrap>
                        </InlineItem>
                    </Text>
                </div>
            </Styled.HeaderSection>
            <Styled.MessageText>
                <ClampedText lines={isInModal ? undefined : 3}>{review.message}</ClampedText>
            </Styled.MessageText>
            {review.tags.length > 0 && (
                <Styled.TagsSection clamp={isInModal}>
                    {review.tags.map((tag) => (
                        <Tag key={tag.id} design='accent' label={tag.name} maxWidth='100%' />
                    ))}
                </Styled.TagsSection>
            )}
            <RatingBar size='m' value={review.rating} />
        </Styled.Card>
    );
};
