import { ITaskerInfo, ITaskerInfoField, ITaskerInfoPhotoField } from 'common/api';

const isFieldPortfolio = (field: ITaskerInfoField): field is ITaskerInfoPhotoField => {
    if (field.type !== 'photo') {
        return false;
    }

    return 'photos' in field.data && field.data.photos.length > 0;
};

export const getPortfolio = (tasker: ITaskerInfo) => tasker.fields.find<ITaskerInfoPhotoField>(isFieldPortfolio);
