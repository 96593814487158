import { ICityInfo } from '../../model/types';
import { CityButton } from '../city-button';
import * as Styled from './cities-list.styles';

interface IProps {
    cities: ICityInfo[];
    onCityClick: (city: ICityInfo) => void;
    dir?: 'ltr' | 'rtl';
}

export const CitiesList = ({ dir, cities, onCityClick }: IProps) => (
    <Styled.Cities dir={dir}>
        {cities.map((city) => (
            <Styled.City key={`${city.countryName}/${city.name}`}>
                <CityButton city={city} onClick={() => onCityClick(city)} />
            </Styled.City>
        ))}
    </Styled.Cities>
);
