import { ReactNode } from 'react';
import { Loader, Text } from '@indriver/nova';
import * as Styled from './dropdown-confirm.styles';

interface IProps {
    title: ReactNode;
    buttons: ReactNode;
    isOpen: boolean;
    isLoading?: boolean;
}

export const DropdownConfirm = ({ title, buttons, isOpen, isLoading }: IProps) => (
    <Styled.ConfirmDialog isOpen={isOpen}>
        <Styled.InnerWrapper>
            {isLoading ? (
                <Styled.LoaderWrapper>
                    <Loader size='m' />
                </Styled.LoaderWrapper>
            ) : (
                <>
                    <Text weight='semi-bold' size='s' style={{ textAlign: 'center' }}>
                        {title}
                    </Text>
                    <Styled.ButtonsWrapper>{buttons}</Styled.ButtonsWrapper>
                </>
            )}
        </Styled.InnerWrapper>
    </Styled.ConfirmDialog>
);
