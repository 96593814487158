import { ReactNode } from 'react';
import { IButtonProps } from '@indriver/nova';
import * as Styled from './tabs.styles';
import { useTabs } from './use-tabs';

export interface ITab {
    id: string;
    title: ReactNode;
    content: ReactNode;
    disabled?: boolean;
}

interface ITabsProps {
    size: IButtonProps['size'];
    tabs: ITab[];
    initialTab?: ITab['id'] | (() => ITab['id'] | undefined);
}

export const Tabs = ({ size, tabs, initialTab }: ITabsProps) => {
    const { tab: visibleTab, toggleTab: toggleVisibleTab } = useTabs(initialTab);

    if (tabs.length === 0) {
        return null;
    }

    return (
        <>
            <Styled.Buttons>
                {tabs.map((tab) => (
                    <Styled.Button
                        key={tab.id}
                        design='ghost'
                        size={size}
                        disabled={tab.disabled}
                        onClick={() => toggleVisibleTab(tab.id)}>
                        {tab.title}
                    </Styled.Button>
                ))}
            </Styled.Buttons>
            {tabs.find((tab) => tab.id === visibleTab)?.content}
        </>
    );
};
