import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const createToggleSlice = (name: string, initialState = false) =>
    createSlice({
        name,
        initialState,
        reducers: {
            setTrue: () => true,
            setFalse: () => false,
            toggle: (state: boolean) => !state,
            setValue: (_: boolean, action: PayloadAction<boolean>) => action.payload,
        },
    });
