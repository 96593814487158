import Dexie, { Table } from 'dexie';

export interface IImage {
    id?: number;
    imageFile: string;
}

export class ImagesDBDexie extends Dexie {
    images!: Table<IImage>;

    constructor() {
        super('imagesDB');
        this.version(1).stores({
            images: '++id, imageFile',
        });
    }
}

export const db = new ImagesDBDexie();
