import { superMastersApi } from './base';
import { IOffersResponse } from './models';

export interface IGetOffersProps {
    orderId: string;
}

export const getOffers = async ({ orderId }: IGetOffersProps) => {
    const response = await superMastersApi.get<IOffersResponse>(`order/${orderId}/offers`);
    return response.data.collection;
};

export interface ISendOfferProps {
    orderId: string;
    taskerId: number;
}

export const sendOffer = async ({ orderId, taskerId }: ISendOfferProps) => {
    const response = await superMastersApi.post(`order/${orderId}/offer`, { tasker_id: taskerId });
    return response.data;
};

export interface IGetCategoryOffersProps {
    catalogId: number;
}

export const getServiceOffers = async ({ catalogId }: IGetCategoryOffersProps) => {
    const response = await superMastersApi.get<IOffersResponse>(`public/offers/${catalogId}`);
    return response.data.collection;
};
