import React, { ChangeEvent, Fragment, MouseEvent } from 'react';
import { FavFilled } from '@indriver/mireska';
import * as Styled from './rating.styles';

interface IRatingProps {
    size?: number;
    name: string;
    value?: number;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onClick?: (e: MouseEvent<HTMLInputElement>) => void;
}

const randomId = () => Math.round(Math.random() * 0xffff).toString(16);

export const Rating = ({ size = 35, value, ...props }: IRatingProps) => {
    const id = randomId();

    return (
        <Styled.RatingWrapper>
            {[5, 4, 3, 2, 1].map((itemValue) => (
                <Fragment key={itemValue}>
                    <input
                        id={`rating-${id}-${itemValue}`}
                        type='radio'
                        value={itemValue}
                        defaultChecked={value === itemValue}
                        {...props}
                    />
                    <label htmlFor={`rating-${id}-${itemValue}`}>
                        <FavFilled size={size} />
                    </label>
                </Fragment>
            ))}
        </Styled.RatingWrapper>
    );
};
