import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from '@indriver/nova';
import { RootState } from 'app/store';
import { usePageCity } from 'entities/city';
import { useCancelOrder } from 'entities/order';
import { hideCancelOrderModal } from './model/cancel-order-modal.slice';

export const CancelOrderModal = () => {
    const { t } = useTranslation('active_order');

    const isCancelOrderModalOpen = useSelector((state: RootState) => state.cancelOrderModal);
    const { id: cityId } = usePageCity();
    const dispatch = useDispatch();

    const router = useRouter();
    const { orderId } = router.query;

    const cancelOrderMutation = useCancelOrder({
        onSettled: () => dispatch(hideCancelOrderModal()),
        cityId,
    });

    return (
        <Modal
            opened={isCancelOrderModalOpen}
            rootSelector='#modal-root'
            title={t('modal_cancel_order_heading')}
            footer={
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <Button
                        size='s'
                        design='error-low'
                        onClick={() => {
                            cancelOrderMutation.mutate(orderId as string);
                        }}>
                        {t('modal_cancel_order_primary_button')}
                    </Button>
                    <Button size='s' onClick={() => dispatch(hideCancelOrderModal())}>
                        {t('modal_cancel_order_secondary_button')}
                    </Button>
                </div>
            }
            onClose={() => dispatch(hideCancelOrderModal())}
        />
    );
};
