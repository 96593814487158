import { useMutation } from '@tanstack/react-query';
import { IOrderReviewData } from 'common/api';
import { postOrderReview } from 'common/api/review';

export const usePostOrderReview = (
    orderId: string,
    onSuccess: (data: void, variables: IOrderReviewData) => void,
    onError: (error: Error, variables: IOrderReviewData) => void,
) =>
    useMutation({
        mutationFn: async (data: IOrderReviewData) => postOrderReview(orderId, data),
        onSuccess,
        onError,
    });
