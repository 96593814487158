import { INotificationQueue, INotificationResponse } from '.';
import { superMastersApi } from './base';

export const getUserNotifications = async () => {
    const response = await superMastersApi.get<INotificationResponse>('user/notifications');
    return response.data;
};

export const patchUserNotifications = async (data: INotificationQueue) => {
    const response = await superMastersApi.patch('user/notifications', data);
    return response.data;
};
