import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { AutocompleteAsync, IAutocompleteAsyncProps } from './autocomplete-async';

export type IAutocompleteAdapterProps<T> = Omit<FieldRenderProps<T, HTMLInputElement>, 'type'> &
    IAutocompleteAsyncProps<T>;

export const AutocompleteAsyncAdapter = <T extends object>({ input, ...props }: IAutocompleteAdapterProps<T>) => (
    <AutocompleteAsync<T> {...input} {...props} />
);
