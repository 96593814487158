import useEmblaCarousel from 'embla-carousel-react';
import { useTranslation } from 'next-i18next';
import { ReactNode, useEffect, useReducer } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { Close, BackArrowLeft, ForwardArrowRight } from '@indriver/mireska';
import { RoundButton } from '@indriver/nova';
import { useBreakpoints } from 'common/lib';
import { InlineItem } from '../inline-items';
import * as Styled from './gallery.styles';

interface IProps {
    title?: ReactNode;
    images: string[];
    index?: number;
    onClose: () => void;
}

export const Gallery = ({ title, index = 0, images, onClose }: IProps) => {
    const { t } = useTranslation();
    const media = useBreakpoints();

    const [viewportRef, carousel] = useEmblaCarousel({ align: 'center' });
    const [, forceUpdate] = useReducer((x: number) => x + 1, 0);
    useEffect(() => {
        if (carousel) {
            forceUpdate();
            carousel.on('scroll', forceUpdate);
            carousel.scrollTo(index);
        }

        return () => {
            if (carousel) {
                carousel.off('scroll', forceUpdate);
            }
        };
    }, [carousel, index]);

    return (
        <RemoveScroll>
            <Styled.Backdrop>
                <Styled.Header>
                    <Styled.Title>
                        {title && <InlineItem>{title}</InlineItem>}
                        {carousel && (
                            <InlineItem>
                                {t('common_count_of_total', {
                                    count: carousel.selectedScrollSnap() + 1,
                                    total: carousel.slideNodes().length,
                                })}
                            </InlineItem>
                        )}
                    </Styled.Title>
                    <Styled.Close onClick={onClose}>
                        {media.isGtS ? <Close size={24} /> : <BackArrowLeft size={20} />}
                    </Styled.Close>
                </Styled.Header>
                <Styled.Content>
                    <Styled.Nav align='left'>
                        <RoundButton
                            size='2xl'
                            prefix={<BackArrowLeft size={40} />}
                            disabled={!carousel?.canScrollPrev()}
                            onClick={() => carousel?.scrollPrev()}
                        />
                    </Styled.Nav>
                    <Styled.Viewport ref={viewportRef}>
                        <Styled.Container>
                            {images.map((src) => (
                                <Styled.Slide key={src}>
                                    <Styled.Image src={src} />
                                </Styled.Slide>
                            ))}
                        </Styled.Container>
                    </Styled.Viewport>
                    <Styled.Nav align='right'>
                        <RoundButton
                            size='2xl'
                            prefix={<ForwardArrowRight size={40} />}
                            disabled={!carousel?.canScrollNext()}
                            onClick={() => carousel?.scrollNext()}
                        />
                    </Styled.Nav>
                </Styled.Content>
            </Styled.Backdrop>
        </RemoveScroll>
    );
};
