import axios from 'axios';
import getConfig from 'next/config';
import { CountriesCodesType } from '@indriver/nova-core';
import { createSignature, isSSR, parsePhoneNumber } from 'common/lib';
import { ICheckAuthCodeResponse, IRequestAuthCodeResponse } from './models';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const { AUTH_JWK = '' } = publicRuntimeConfig;

const getServerHttpClientConfig = () => ({
    baseURL: `${serverRuntimeConfig?.CAS_BACKEND_HOST}`,
    timeout: 3000,
});

const getBrowserHttpClientConfig = () => ({
    baseURL: `${publicRuntimeConfig?.CAS_BACKEND_HOST}`,
    timeout: 3000,
});

export const casApi = axios.create(isSSR ? getServerHttpClientConfig() : getBrowserHttpClientConfig());

export interface IPostRequestAuthCodeParams {
    phone: string;
    country: CountriesCodesType;
    captcha: string;
    locale: string;
}

export const requestAuthCode = async ({ phone, country, captcha, locale }: IPostRequestAuthCodeParams) => {
    const [areaCode, nationalNumber] = parsePhoneNumber(phone, country);

    // NOTE: Encoding here to ensure that we sign the same data we are sending
    const payload = new URLSearchParams({
        phone: nationalNumber,
        phoneCode: areaCode,
        aud: 'services',
    }).toString();

    const { data } = await casApi.post<IRequestAuthCodeResponse>('/api/sms', payload, {
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
            'accept-language': locale,
            'x-captcha': captcha,
            'x-signature': await createSignature(AUTH_JWK, payload),
        },
    });

    if (data.error) {
        throw new Error(data.error.text);
    }

    return data;
};

export interface IPostCheckAuthCodeParams {
    phone: string;
    authcode: string;
    locale?: string;
}

export const checkAuthCode = async ({ phone, authcode, locale }: IPostCheckAuthCodeParams) => {
    const response = await casApi.post<ICheckAuthCodeResponse>(
        '/api/v2/checkauthcode',
        {
            aud: 'services',
            phone: phone.replace(/\D/g, ''),
            authcode,
        },
        {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'accept-language': locale,
            },
        },
    );

    if (response.data.error) {
        throw new Error(response.data.error.text);
    }

    return response.data;
};
