import { AnchorHTMLAttributes, ButtonHTMLAttributes, MouseEventHandler } from 'react';
import * as Styled from './seo-link.styles';

type ISeoLinkProps = ButtonHTMLAttributes<HTMLButtonElement> &
    Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'target'>;

export const SeoLink = ({ href, onClick, target = '_self', ...props }: ISeoLinkProps) => {
    const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        onClick?.(e);
        window.open(href, target);
    };

    return <Styled.Link role='link' data-href={href} onClick={handleClick} {...props} />;
};
