import { useUtmParams } from './use-utm-params';

export const usePassUtmParams = (url: string) => {
    const utmParams = useUtmParams();
    const augmentedUrl = new URL(url);

    Object.entries(utmParams).forEach(([key, value]) => {
        augmentedUrl.searchParams.append(key, value);
    });

    return augmentedUrl.toString();
};
