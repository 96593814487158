import { Avatar, RatingBar } from '@indriver/nova';
import { IWizardReview } from 'common/api';
import * as Styled from './common-review.styles';

interface ICommonReviewProps {
    review: Pick<IWizardReview, 'created_at' | 'message' | 'rating' | 'author'>;
}

export const CommonReview = ({ review }: ICommonReviewProps) => (
    <Styled.Container>
        <Styled.Avatar>
            <Avatar design='neutral-inverse' image={review.author.avatar} content={review.author.first_name} />
        </Styled.Avatar>
        <Styled.Content size='s' weight='medium'>
            {review.message}
        </Styled.Content>
        <Styled.Rating>
            <RatingBar size='m' value={review.rating} />
        </Styled.Rating>
    </Styled.Container>
);
