import { IToggle } from 'common/api/ab';
import { IVariationTypes } from './types';

export const selectToggle = <K extends keyof IVariationTypes>(
    toggles: IToggle[],
    name: K,
): Partial<IVariationTypes[K]> => {
    const foundToggle = toggles.find((toggle) => toggle.name === name);
    return foundToggle
        ? (Object.fromEntries(foundToggle.variations.map(({ key, value }) => [key, value])) as IVariationTypes[K])
        : {};
};
