import { superMastersApi } from '../base';
import { ISeoResponse, ISeoResponseEntry } from '../models';

interface IGetSeoTextsParams {
    locale: string;
    citySlug: string;
    categorySlug: string;
    serviceSlug: string;
    subServiceSlug?: string;
}

export const getSeoTexts = async ({
    locale,
    citySlug,
    categorySlug,
    serviceSlug,
    subServiceSlug,
}: IGetSeoTextsParams): Promise<ISeoResponseEntry[]> => {
    const response = await superMastersApi.get<ISeoResponse>('/public/seo', {
        headers: { 'Accept-Language': locale },
        params: {
            citySlug,
            categorySlug,
            serviceSlug,
            subServiceSlug,
        },
    });
    return response.data.collection;
};
