import { generatePath } from './generate-path';
import { parseLocale } from './parse-locale';

const LOCALE_BY_LANG: Record<string, string> = {
    ar: 'ar-eg',
    es: 'es-mx',
    // NOTE: main website improperly suppose `kz` as language code for Kazakh language (which is `kk`)
    kk: 'kz-kz',
    pt: 'pt-br',
    ru: 'ru-kz',
};

const getLocale = (locale: string) => {
    const { lang } = parseLocale(locale);
    return LOCALE_BY_LANG[lang.toLowerCase()];
};

export const getSupportPageUrl = (countryIso: string | undefined, locale: string) =>
    `https://indrive.com${generatePath('/:locale?/contacts/support', { locale: getLocale(locale) ?? null })}`;
