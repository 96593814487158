import { track } from '@amplitude/analytics-browser';
import { captureException } from '@sentry/minimal';
import { getCookie } from 'cookies-next';
import { getUserId } from './get-user-id';

type IAnalyticsPayload = Record<string, unknown>;

interface IRecordEventParams {
    actionGA: string;
    actionAF?: string;
    actionAmplitude?: string;
    payload?: IAnalyticsPayload;
}

export const recordEvent = ({ actionGA, actionAF, actionAmplitude, payload }: IRecordEventParams) => {
    try {
        const actionPayload = { ...payload, cookie_user_id: getCookie('abidv0'), user_id: getUserId() };
        if (process.env.DISABLE_ANALYTICS === 'true') {
            // eslint-disable-next-line no-console
            console.log('analytics', { action: actionGA, ...actionPayload });
            return;
        }
        window.dataLayer.push({ event: actionGA, ...actionPayload });
        if (actionAmplitude) {
            track(actionAmplitude, actionPayload);
        }
        if (actionAF) {
            window.AF('pba', 'event', {
                eventType: 'EVENT',
                eventName: actionAF,
                eventValue: actionPayload,
            });
        }
    } catch (error) {
        captureException(error);
    }
};

export const recordEventShort = (action: string, payload?: IAnalyticsPayload) =>
    recordEvent({
        actionAF: action,
        actionGA: action,
        actionAmplitude: action,
        payload,
    });
