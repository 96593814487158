const COUNTRY_ISO_TO_JUMBOTRON_BG_MAP: Record<string, string> = {
    KZ: 'jumbotron-1-desktop.png',
    CO: 'jumbotron-1-desktop-co-mx.png',
    MX: 'jumbotron-1-desktop-co-mx.png',
    PE: 'jumbotron-1-desktop-co-mx.png',
    BR: 'jumbotron-1-desktop-br.png',
    EG: 'jumbotron-1-desktop-eg.png',
};

const getDesktopBg = (countryIso: string) =>
    COUNTRY_ISO_TO_JUMBOTRON_BG_MAP[countryIso.toUpperCase()] || COUNTRY_ISO_TO_JUMBOTRON_BG_MAP.CO;

const COUNTRY_ISO_TO_JUMBOTRON_BG_TABLET_MAP: Record<string, string> = {
    KZ: 'jumbotron-1-desktop.png',
    CO: 'jumbotron-1-co-mx.png',
    MX: 'jumbotron-1-co-mx.png',
    PE: 'jumbotron-1-co-mx.png',
    BR: 'jumbotron-1-desktop-br.png',
    EG: 'jumbotron-1-eg.png',
};

const getTabletBg = (countryIso: string) =>
    COUNTRY_ISO_TO_JUMBOTRON_BG_TABLET_MAP[countryIso.toUpperCase()] || COUNTRY_ISO_TO_JUMBOTRON_BG_TABLET_MAP.CO;

export const mapCountryIsoToJumbotron = (countryIso: string, isTablet = false) =>
    isTablet ? getTabletBg(countryIso) : getDesktopBg(countryIso);
