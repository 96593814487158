import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IMixedModelTaskerModalState {
    isVisible: boolean;
    opener: string;
}

const initialState: IMixedModelTaskerModalState = {
    isVisible: false,
    opener: '',
};

const { actions, reducer } = createSlice({
    name: 'mixedModelTaskerModal',
    initialState,
    reducers: {
        show: (_, action: PayloadAction<string>) => ({ isVisible: true, opener: action.payload }),
        hide: () => ({ isVisible: false, opener: '' }),
    },
});

export const { show: showMixedModelModal, hide: hideMixedModelModal } = actions;
export const mixedModelTaskerModalReducer = reducer;
