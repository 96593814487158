import { useTranslation } from 'next-i18next';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Cell } from '@indriver/nova';
import { recordEvent } from 'common/lib';
import { useAnalyticsPayload } from 'entities/analytics';
import { showCancelOrderModal } from './model/cancel-order-modal.slice';

interface IProps {
    variant: 'inDropdown';
    onClick: () => void;
}

export const CancelOrder = ({ variant, onClick }: IProps) => {
    const { t } = useTranslation();

    const defaultAnalyticsPayload = useAnalyticsPayload();
    const dispatch = useDispatch();

    const handleCancelOrderClick = () => {
        recordEvent({
            actionGA: 'masters_customer_cancel_order_click',
            actionAmplitude: 'masters_customer_cancel_order_click',
            actionAF: 'masters.customer.cancel_order.click',
            payload: { ...defaultAnalyticsPayload },
        });
        dispatch(showCancelOrderModal());
        onClick();
    };

    if (variant === 'inDropdown') {
        return (
            <Cell size='m' design='error' onClick={handleCancelOrderClick}>
                {t('active_order:cancel_order_button')}
            </Cell>
        );
    }

    return null;
};
