import type { IncomingMessage } from 'http';
import getConfig from 'next/config';
import { getHostname } from 'common/lib';
import { IDomainI18nConfig } from '../types';

const { serverRuntimeConfig } = getConfig();
const domains = (serverRuntimeConfig.domains ?? []) as IDomainI18nConfig[];

const domainConfigByDomain: Partial<Record<string, IDomainI18nConfig>> = Object.fromEntries(
    domains.map((config) => [config.domain, config]),
);
export const getDomainConfigByDomain = (domain: string) => domainConfigByDomain[domain];

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const defaultConfig = domains.find((config) => config.countryIso === undefined)!;

export const getDomainI18nConfig = (req?: IncomingMessage): IDomainI18nConfig => {
    if (req === undefined) {
        return defaultConfig;
    }

    const hostname = getHostname(req);
    if (hostname === undefined) {
        return defaultConfig;
    }

    return getDomainConfigByDomain(hostname) ?? defaultConfig;
};

const domainConfigByCountry: Partial<Record<string, IDomainI18nConfig>> = Object.fromEntries(
    domains.map((config) => [config.countryIso, config]),
);
export const getDomainConfigByCountry = (countryIso: string) => domainConfigByCountry[countryIso];
