import { useTranslation } from 'next-i18next';
import { Switcher, Text } from '@indriver/nova';
import { useBreakpoints } from 'common/lib';
import { usePatchUserNotifications } from '..';
import * as Styled from './notification-whatsapp.styles';

interface IProps {
    isOpen: boolean;
    positionX: {
        left: string;
        right: string;
    };
    enable: boolean | undefined;
    desktopMode?: boolean;
}

export const NotificationWhatsApp = ({ isOpen, positionX, enable, desktopMode }: IProps) => {
    const { t } = useTranslation(['common']);
    const media = useBreakpoints();
    const updateNotifications = usePatchUserNotifications();

    const handleCheck = (value: boolean) => {
        updateNotifications.mutate(value);
    };

    if (!isOpen) {
        return null;
    }

    if (!media.isGtS || desktopMode) {
        return (
            <Styled.MobileWrapper>
                <Styled.DescriptionAndSwitcherMobileWrapper>
                    <Styled.TitleAndSwitcherContainer>
                        <Text size='m'>{t('masters_notification_popup_whatsapp_title')}</Text>
                        <Switcher size='l' checked={enable} onChange={(_, { value }) => handleCheck(value)} />
                    </Styled.TitleAndSwitcherContainer>
                    <Text size='s' color='var(--text-and-icon-secondary)'>
                        {t('masters_notification_popup_whatsapp_text')}
                    </Text>
                </Styled.DescriptionAndSwitcherMobileWrapper>
                <Styled.MessageWrapper>
                    <Styled.Message>
                        <Text size='xxs' color='var(--information-medium-earthmedium)'>
                            {t('masters_notification_popup_whatsapp_text_title_example')}
                        </Text>
                        <Text size='xxs'>{t('masters_notification_popup_whatsapp_text_example')}</Text>
                    </Styled.Message>
                </Styled.MessageWrapper>
            </Styled.MobileWrapper>
        );
    }

    return (
        <Styled.DesktopWrapper positionX={positionX}>
            <Styled.DescriptionAndSwitcherWrapper>
                <Styled.DescriptionWrapper>
                    <Text size='m'>{t('masters_notification_popup_whatsapp_title')}</Text>
                    <Text size='s' color='var(--text-and-icon-secondary)'>
                        {t('masters_notification_popup_whatsapp_text')}
                    </Text>
                </Styled.DescriptionWrapper>
                <Styled.SwitcherWrapper>
                    <Switcher size='l' checked={enable} onChange={(_, { value }) => handleCheck(value)} />
                </Styled.SwitcherWrapper>
            </Styled.DescriptionAndSwitcherWrapper>
            <Styled.MessageWrapper>
                <Styled.Message>
                    <Text size='xxs' color='var(--information-medium-earthmedium)'>
                        {t('masters_notification_popup_whatsapp_text_title_example')}
                    </Text>
                    <Text size='xxs'>{t('masters_notification_popup_whatsapp_text_example')}</Text>
                </Styled.Message>
            </Styled.MessageWrapper>
        </Styled.DesktopWrapper>
    );
};
