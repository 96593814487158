export const createCachedIndex = <RawData extends object, Index>(indexFn: (data: RawData) => Index) => {
    const cache = new WeakMap<RawData, Index>();

    return (data: RawData) => {
        if (!cache.has(data)) {
            cache.set(data, indexFn(data));
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we've just added data to cache
        return cache.get(data)!;
    };
};
