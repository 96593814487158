import { TFunction } from 'next-i18next';
import { IDatetime } from 'common/api';

export const formatOrderDate = (t: TFunction, { is_time_detailed, time, time_alias = 'exact_time' }: IDatetime) => {
    if (time_alias === 'exact_time') {
        return t('intlDateTime', {
            val: new Date(time),
            formatParams: {
                val: {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    ...(is_time_detailed && {
                        hour: 'numeric',
                        minute: 'numeric',
                    }),
                },
            },
        });
    }

    return t(`common_order_date_${time_alias}`);
};
