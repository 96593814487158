import { ICityInfo } from './types';

export const DEFAULT_CITY: ICityInfo = {
    id: 169,
    name: 'Алматы',
    citySlug: 'almaty',
    countryIso: 'KZ',
    countryId: 2,
    countryName: 'Казахстан',
    countrySlug: 'kazakhstan',
};
