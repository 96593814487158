import { i18n } from 'next-i18next';

const CUSTOM_CURRENCIES: Record<string, string> = {
    KZT: '₸',
};

const getCurrency = (currency: string) => CUSTOM_CURRENCIES[currency] ?? currency;

export const formatPrice = (price: number, currency: string) =>
    Intl.NumberFormat(i18n?.resolvedLanguage, {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    })
        .formatToParts(price)
        .map(({ type, value }) => (type === 'currency' ? getCurrency(value) : value))
        .join('')
        .replace(/\s+(?=\d)/g, '\u00a0');
