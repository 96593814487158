const JWK_ALG_TO_RSA_HASHED_IMPORT_PARAMS = {
    RS256: { name: 'RSASSA-PKCS1-v1_5', hash: 'SHA-256' },
    RS384: { name: 'RSASSA-PKCS1-v1_5', hash: 'SHA-384' },
    RS512: { name: 'RSASSA-PKCS1-v1_5', hash: 'SHA-512' },
    PS256: { name: 'RSA-PSS', hash: 'SHA-256' },
    PS384: { name: 'RSA-PSS', hash: 'SHA-384' },
    PS512: { name: 'RSA-PSS', hash: 'SHA-512' },
    ES256: { name: 'ECDSA', hash: 'SHA-256' },
    ES384: { name: 'ECDSA', hash: 'SHA-384' },
    ES512: { name: 'ECDSA', hash: 'SHA-512' },
    RS1: { name: 'RSASSA-PKCS1-v1_5', hash: 'SHA-1' },
    HS256: { name: 'HMAC', hash: 'SHA-256' },
    HS384: { name: 'HMAC', hash: 'SHA-384' },
    HS512: { name: 'HMAC', hash: 'SHA-512' },
    'RSA-OAEP-256': { name: 'RSA-OAEP', hash: 'SHA-256' },
    'RSA-OAEP': { name: 'RSA-OAEP', hash: 'SHA-1' },
} as const;

const isKnownAlg = (alg: string): alg is keyof typeof JWK_ALG_TO_RSA_HASHED_IMPORT_PARAMS =>
    JWK_ALG_TO_RSA_HASHED_IMPORT_PARAMS.hasOwnProperty(alg);

const JWK_KTY_TO_RSA_HASHED_IMPORT_PARAMS = {
    RSA: { name: 'RSASSA-PKCS1-v1_5', hash: 'SHA-256' },
    EC: { name: 'ECDSA', hash: 'SHA-256' },
    oct: { name: 'HMAC', hash: 'SHA-256' },
} as const;

const isKnownKty = (kty: string): kty is keyof typeof JWK_KTY_TO_RSA_HASHED_IMPORT_PARAMS =>
    JWK_KTY_TO_RSA_HASHED_IMPORT_PARAMS.hasOwnProperty(kty);

export const getRSAHashedImportParamsFromJWK = (jwk: JsonWebKey) => {
    if (jwk.alg !== undefined) {
        if (!isKnownAlg(jwk.alg)) {
            throw new Error(`Unsupported JWK algorithm: ${jwk.alg}`);
        }

        return JWK_ALG_TO_RSA_HASHED_IMPORT_PARAMS[jwk.alg] as RsaHashedImportParams;
    }

    if (jwk.kty === undefined) {
        throw new Error("Missing required JWK field: 'kty'");
    }

    if (!isKnownKty(jwk.kty)) {
        throw new Error(`Unsupported JWK key type: ${jwk.kty}`);
    }

    return JWK_KTY_TO_RSA_HASHED_IMPORT_PARAMS[jwk.kty] as RsaHashedImportParams;
};
