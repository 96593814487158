import { useTranslation } from 'next-i18next';
import { InlineItem } from 'common/ui';
import { ITasker } from '../../model/types';

interface IProps {
    tasker: ITasker;
}

export const OrdersCount = ({ tasker }: IProps) => {
    const { t } = useTranslation();
    return tasker.completedOrders ? (
        <InlineItem>{t('entities.tasker.orders', { count: tasker.completedOrders })}</InlineItem>
    ) : null;
};
