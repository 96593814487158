import { ReactNode } from 'react';
import { Button, IButtonProps } from '@indriver/nova';
import { ITaskerInfo, IWizardReviewUser } from 'common/api';
import { toastInfo } from 'common/ui';
import { useSelectedTasker } from '../../model/use-selected-tasker';

interface IChooseTaskerButtonProps extends IButtonProps {
    taskerUser: IWizardReviewUser;
    taskerInfo: ITaskerInfo;
    taskerId: number;
    selectText: ReactNode;
    cancelText: ReactNode;
    snackbarMessage: string;
    onTaskerChoose?: (taskerId: number) => void;
    onTaskerCancel?: (taskerId: number) => void;
}

export const ChooseTaskerButton = ({
    taskerId,
    taskerUser,
    taskerInfo,
    selectText,
    cancelText,
    snackbarMessage,
    onTaskerChoose,
    onTaskerCancel,
    ...otherProps
}: IChooseTaskerButtonProps) => {
    const [mixedModelTasker, setMixedModelTasker] = useSelectedTasker();

    const handleTaskerChoose = () => {
        onTaskerChoose?.(taskerId);

        setMixedModelTasker({
            first_name: taskerUser.first_name,
            last_name: taskerUser.last_name,
            rating: taskerInfo.rating,
            joined_at: taskerInfo.joined_at,
            completed_orders: taskerInfo.completed_orders,
            avatar: taskerUser.avatar,
            tasker_id: taskerId,
        });

        toastInfo({
            message: snackbarMessage,
            toastId: 'mixed-model-info',
        });
    };

    const handleTaskerCancel = () => {
        onTaskerCancel?.(taskerId);
        setMixedModelTasker(null);
    };

    return mixedModelTasker?.tasker_id === taskerId ? (
        <Button {...otherProps} design='error-low' onClick={handleTaskerCancel}>
            {cancelText}
        </Button>
    ) : (
        <Button {...otherProps} onClick={handleTaskerChoose}>
            {selectText}
        </Button>
    );
};
