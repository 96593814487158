import { cx } from '@linaria/core';
import { ReactNode } from 'react';
import * as Styled from './promo-card.styles';

export interface IPromoCardProps {
    header?: ReactNode;
    content?: ReactNode;
    footer?: ReactNode;
    className?: string;
    design?: 'green' | 'white';
    onClick?: () => void;
}

export const PromoCard = ({ header, content, footer, className, design = 'white', onClick }: IPromoCardProps) => (
    <Styled.Box
        className={cx(design === 'green' && Styled.designGreen, onClick && Styled.hoverable, className)}
        onClick={onClick}>
        {header && <Styled.Header>{header}</Styled.Header>}
        {content && <Styled.Content>{content}</Styled.Content>}
        {footer && <Styled.Footer>{footer}</Styled.Footer>}
    </Styled.Box>
);
