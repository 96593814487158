import { ReactNode } from 'react';
import * as Styled from './mini-cell.styles';

interface IProps {
    prefix: ReactNode;
    content: ReactNode;
    suffix: ReactNode;
    onClick?: () => void;
}

export const MiniCell = ({ prefix, content, suffix, onClick }: IProps) => (
    <Styled.Box onClick={onClick}>
        {!!prefix && <Styled.Prefix>{prefix}</Styled.Prefix>}
        <Styled.Content>{content}</Styled.Content>
        {!!suffix && <Styled.Suffix>{suffix}</Styled.Suffix>}
    </Styled.Box>
);
