import React from 'react';

export const PlayStoreOnColor = ({ color = '#fff', ...props }: React.SVGProps<SVGSVGElement>): React.ReactElement => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 162 48' {...props}>
        <path
            fill={color}
            d='M162 10.1v-1c0-.9 0-1.7-.2-2.5a7.9 7.9 0 0 0-2.2-4.2 7.8 7.8 0 0 0-4.3-2.2 16.7 16.7 0 0 0-3.5-.2H10.2a45.5 45.5 0 0 0-3.5.2 8 8 0 0 0-4.3 2.2A7.6 7.6 0 0 0 .2 6.6L0 9V38a22.9 22.9 0 0 0 .2 3.5 8 8 0 0 0 2.2 4.2 8 8 0 0 0 4.3 2.2L9 48h143.8c.8 0 1.6 0 2.4-.2a8 8 0 0 0 4.3-2.2 7.8 7.8 0 0 0 2.2-4.2l.2-2.4V10Zm-1 26.5V39l-.3 2.2a6.5 6.5 0 0 1-1.9 3.6c-.5.6-1 1-1.7 1.3-.6.3-1.2.5-2 .6-.8.2-1.6.2-2.2.2H9.2c-.8 0-1.6 0-2.4-.2A7.2 7.2 0 0 1 3.2 45c-.6-.5-1-1-1.3-1.7-.3-.6-.5-1.3-.6-2-.2-.8-.2-1.6-.2-2.3V10.1a75.5 75.5 0 0 1 .2-3.3A6.6 6.6 0 0 1 4.8 2a7 7 0 0 1 2-.6C7.7 1 8.5 1 9.1 1h143.7c.7 0 1.5 0 2.3.2a7.2 7.2 0 0 1 3.7 1.9 6.6 6.6 0 0 1 2 3.6l.1 2.3v27.4Z'
        />
        <path
            fill={color}
            d='M85.3 15.9c-1 0-2-.4-2.7-1.1a3.9 3.9 0 0 1-1-2.8 3.6 3.6 0 0 1 3.8-3.9c1 0 2 .4 2.7 1.2.7.7 1 1.6 1 2.7s-.3 2-1 2.7c-.8.8-1.7 1.2-2.8 1.2Zm-32.2 0c-1 0-2-.4-2.7-1.1a3.7 3.7 0 0 1-1.1-2.8c0-1.1.4-2 1.1-2.8.8-.7 1.7-1 2.7-1 .6 0 1 0 1.6.2.5.2.8.5 1.1.9h.1l-.8.8c-.5-.6-1.1-.9-2-.9-.7 0-1.3.3-1.8.8s-.8 1.2-.8 2 .3 1.5.8 2 1.1.7 1.8.7c.8 0 1.5-.2 2-.7.3-.3.4-.7.5-1.3H53v-1h3.7l.1.6c0 1-.3 1.8-1 2.5-.6.7-1.5 1-2.6 1Zm42.8-.2h-1.1l-3.5-5.6v5.6h-1.1V8.3h1.3l3.3 5.3V8.3h1.1v7.4Zm-19.1 0h-1.2V9.4h-2V8.3h5.2v1.1h-2v6.3Zm-4.1 0h-1.2V8.3h1.2v7.4Zm-6.5 0H65V9.4h-2V8.3h5.2v1.1h-2v6.3Zm-4 0H58V8.3h4.4v1.1H59v2h3v1.1h-3v2h3.3v1.2ZM83.6 14a2.5 2.5 0 0 0 3.7 0c.5-.5.8-1.2.8-2s-.3-1.5-.8-2-1.1-.7-1.9-.7c-.7 0-1.3.2-1.8.7-.5.5-.8 1.2-.8 2s.3 1.5.8 2Z'
        />
        <path
            fill={color}
            fillRule='evenodd'
            clipRule='evenodd'
            d='M81.4 26.1a5 5 0 0 0-5 5.1 5 5 0 0 0 5 5.1 5 5 0 0 0 5.1-5 5 5 0 0 0-5-5.2Zm0 8.2c-1.5 0-2.8-1.3-2.8-3.1s1.3-3 2.8-3c1.6 0 2.9 1.2 2.9 3s-1.3 3-2.9 3ZM70.3 26a5 5 0 0 0-5.1 5.1 5 5 0 0 0 5.1 5.1 5 5 0 0 0 5.1-5 5 5 0 0 0-5.1-5.2Zm0 8.2c-1.5 0-2.9-1.3-2.9-3.1s1.4-3 2.9-3 2.9 1.2 2.9 3-1.4 3-2.9 3Zm-13.2-6.6v2.1h5.1a4.6 4.6 0 0 1-1.1 2.7c-.8.8-2 1.6-4 1.6a5.7 5.7 0 0 1-5.7-5.7 5.7 5.7 0 0 1 9.6-4.2l1.5-1.6a7.5 7.5 0 0 0-5.4-2.2 8 8 0 0 0-8 8 8 8 0 0 0 8 7.9c2.3 0 4.1-.8 5.5-2.2a7.2 7.2 0 0 0 1.9-5.1l-.1-1.3H57Zm54.1 1.6c-.4-1.1-1.7-3.2-4.4-3.2-2.6 0-4.7 2-4.7 5.1a5 5 0 0 0 5 5.1 5 5 0 0 0 4.2-2.3l-1.7-1.1c-.6.8-1.4 1.4-2.5 1.4s-2-.5-2.4-1.5l6.7-2.9-.2-.5Zm-7 1.7c0-2 1.6-3 2.7-3 1 0 1.7.5 2 1.2l-4.6 1.8Zm-5.5 5h2.3V21h-2.3v15Zm-3.6-8.8a3.5 3.5 0 0 0-2.8-1.1 5 5 0 0 0-4.9 5.1 5 5 0 0 0 5 5c1.1 0 2.1-.5 2.6-1v.7c0 1.9-1 3-2.6 3-1.4 0-2.2-1-2.6-1.9l-2 .9c.6 1.3 2.1 3 4.6 3 2.6 0 4.8-1.6 4.8-5.3v-9.2h-2.1v.8Zm-2.6 7c-1.5 0-2.8-1.2-2.8-3s1.3-3.1 2.8-3.1c1.6 0 2.7 1.3 2.7 3.1s-1.1 3-2.7 3ZM116.3 21v15h2.3v-5.7h3c2.5 0 5-1.8 5-4.6 0-2.9-2.5-4.7-5-4.7h-5.3Zm5.4 7.2h-3.1v-5.1h3.1c1.7 0 2.6 1.4 2.6 2.6 0 1.1-1 2.5-2.6 2.5Zm13.8-2.1c-1.6 0-3.3.7-4 2.3l2 .8c.4-.8 1.2-1.1 2-1.1 1.2 0 2.4.7 2.4 2a5 5 0 0 0-2.3-.5c-2.2 0-4.3 1.2-4.3 3.4 0 2 1.7 3.3 3.7 3.3 1.5 0 2.3-.7 2.8-1.5V36h2.2v-5.8c0-2.6-2-4.1-4.5-4.1Zm-.3 8.2c-.7 0-1.7-.4-1.7-1.3 0-1.2 1.3-1.6 2.3-1.6 1 0 1.5.2 2 .5a2.7 2.7 0 0 1-2.6 2.4Zm12.7-7.9-2.6 6.5-2.7-6.5h-2.4l4 9-2.3 5.1h2.3l6.1-14.1H148ZM127.8 36h2.2V21h-2.2v15Z'
        />
        <path
            fill={color}
            d='M12.1 9.6 26.5 24 12.1 38.4l-.2-1.1V10.7c0-.4 0-.8.2-1.1ZM13 8.7l14.4 14.4 4.5-4.5-17-9.6c-.7-.5-1.4-.5-2-.3ZM33 19.2 28.1 24l4.7 4.8 5.3-3c1.7-1 1.7-2.6 0-3.5l-5.3-3ZM31.9 29.4l-4.5-4.5L13 39.3c.5.2 1.2.2 2-.3l16.9-9.6Z'
        />
    </svg>
);
