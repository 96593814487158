import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo } from 'react';
import {
    BottomSheet,
    BottomSheetBody,
    BottomSheetCloseIcon,
    BottomSheetHeader,
    BottomSheetHeaderPostfix,
    Button,
    Modal,
    Text,
} from '@indriver/nova';
import { IOffer } from 'common/api';
import { useBreakpoints } from 'common/lib';
import { usePageCity } from 'entities/city';
import { useGetServiceOffers } from 'entities/offer';
import { CompactTaskerCard } from 'entities/tasker';
import { useBoundActions } from '../../model/bound-actions';
import { useMixedModelModalState } from '../../model/redux-selectors';
import { useSelectedTasker } from '../../model/use-selected-tasker';
import { ChooseTaskerButton } from '../choose-tasker-button';
import * as Styled from './mixed-model-modal.styles';

interface IProps {
    catalogId: number;
    onModalShow?: (props: { offers: IOffer[]; opener: string }) => void;
    onFooterButtonClick?: (opener: string) => void;
    onTaskerChoose?: (props: { opener: string; taskerId: number }) => void;
    onTaskerCancel?: (props: { opener: string; taskerId: number }) => void;
}

export const MixedModelModal = ({
    catalogId,
    onModalShow,
    onTaskerChoose,
    onTaskerCancel,
    onFooterButtonClick,
}: IProps) => {
    const { t } = useTranslation();
    const media = useBreakpoints();

    const { isVisible, opener } = useMixedModelModalState();
    const { hideModal } = useBoundActions();

    const currentCity = usePageCity();
    const [mixedModelTasker] = useSelectedTasker();
    const pickedTaskerId = mixedModelTasker?.tasker_id;

    const { data: offers = [] } = useGetServiceOffers({ catalogId });

    const sortedOffers = useMemo(() => {
        if (pickedTaskerId === undefined) {
            return offers;
        }
        const offerIndex = offers.findIndex((offer) => offer.tasker_id === pickedTaskerId);
        if (offerIndex === -1) {
            return offers;
        }

        const offersCopy = [...offers];
        // Moving element from offerIndex to the beginning of the array
        offersCopy.unshift(...offersCopy.splice(offerIndex, 1));
        return offersCopy;
    }, [offers, pickedTaskerId]);

    useEffect(() => {
        // TODO: check if offers is immutable and it doesn't run on each render
        if (isVisible) {
            onModalShow?.({ offers, opener });
        }
    }, [isVisible, offers, opener, onModalShow]);

    const handleTaskerChoose = useCallback(
        (taskerId: number) => {
            onTaskerChoose?.({ opener, taskerId });
            hideModal();
        },
        [hideModal, onTaskerChoose, opener],
    );

    const handleTaskerCancel = useCallback(
        (taskerId: number) => {
            onTaskerCancel?.({ opener, taskerId });
            hideModal();
        },
        [hideModal, onTaskerCancel, opener],
    );

    const handleFooterButtonClick = useCallback(() => {
        onFooterButtonClick?.(opener);
        hideModal();
    }, [hideModal, onFooterButtonClick, opener]);

    const commonContent = (
        <>
            <Styled.Content>
                {sortedOffers.map((offer) => (
                    <CompactTaskerCard
                        key={offer.tasker_id}
                        taskerUser={offer.user}
                        taskerInfo={offer.tasker_info}
                        buttonsSlot={
                            <ChooseTaskerButton
                                size={media.isGtS ? 'm' : 's'}
                                stretched={!media.isGtS}
                                taskerId={offer.tasker_id}
                                taskerInfo={offer.tasker_info}
                                taskerUser={offer.user}
                                selectText={t('features.mixed_model.tasker_button.select')}
                                cancelText={t('features.mixed_model.tasker_button.cancel')}
                                snackbarMessage={t('features.mixed_model.snackbar.cta')}
                                onTaskerChoose={handleTaskerChoose}
                                onTaskerCancel={handleTaskerCancel}
                            />
                        }
                    />
                ))}
            </Styled.Content>
            <Styled.Footer>
                <Text>{t('features.mixed_model.modal.footer_text', { city_name: currentCity.name })}</Text>
                <Styled.FooterButton>
                    <Button design='tertiary' size='s' onClick={handleFooterButtonClick}>
                        {t('features.mixed_model.modal.footer_button')}
                    </Button>
                </Styled.FooterButton>
            </Styled.Footer>
        </>
    );

    if (media.isGtS) {
        return (
            <Modal
                className={Styled.modal}
                rootSelector='#modal-root'
                // @ts-ignore
                title={
                    <Styled.ModalHeader>
                        <Styled.Heading>{t('features.mixed_model.modal.title')}</Styled.Heading>
                        <Text>{t('features.mixed_model.modal.subtitle')}</Text>
                    </Styled.ModalHeader>
                }
                opened={isVisible}
                onClose={hideModal}>
                <Styled.ModalWrapper>{commonContent}</Styled.ModalWrapper>
            </Modal>
        );
    }

    return (
        <BottomSheet
            className={Styled.bottomSheet}
            position='fixed'
            rootSelector='#modal-root'
            opened={isVisible}
            zIndex={10}>
            <BottomSheetHeader>
                <BottomSheetHeaderPostfix aria-label={t('common_close_aria_label')} onClick={hideModal}>
                    <BottomSheetCloseIcon />
                </BottomSheetHeaderPostfix>
            </BottomSheetHeader>
            <BottomSheetBody>
                <Styled.BottomSheetWrapper>
                    <Styled.BottomSheetHeader>
                        <Styled.Heading>{t('features.mixed_model.modal.title')}</Styled.Heading>
                        <Text>{t('features.mixed_model.modal.subtitle')}</Text>
                    </Styled.BottomSheetHeader>
                    {commonContent}
                </Styled.BottomSheetWrapper>
            </BottomSheetBody>
        </BottomSheet>
    );
};
