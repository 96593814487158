export interface IWebsiteJsonLdProps {
    name: string;
    url: string;
    alternateName?: string | string[];
}

export const WebsiteJsonLd = ({ name, url, alternateName }: IWebsiteJsonLdProps) => (
    <script
        type='application/ld+json'
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{
            __html: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'WebSite',
                url,
                name,
                alternateName,
            }),
        }}
    />
);
