import React from 'react';
import { useBreakpoints } from 'common/lib';
import * as Styled from './logo.styles';

export interface ILogoProps {
    locale: string;
}

export const Logo = ({ locale }: ILogoProps) => {
    const media = useBreakpoints();
    return <Styled.Logo locale={locale} height={media.isGtS ? 40 : 32} />;
};
