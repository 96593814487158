import useEmblaCarousel, { EmblaOptionsType } from 'embla-carousel-react';
import { useTranslation } from 'next-i18next';
import { ReactNode, useEffect, useState } from 'react';
import { BackArrowLeft, ForwardArrowRight } from '@indriver/mireska';
import { Button } from '@indriver/nova';
import { Fullscreen } from 'common/ui/icons';
import * as Styled from './carousel.styles';

interface IProps extends EmblaOptionsType {
    onFullscreenClick: () => void;
    children: ReactNode;
}

const defaultOptions: EmblaOptionsType = {
    align: 'start',
    skipSnaps: true,
    containScroll: 'trimSnaps',
};

export const Carousel = ({ children, onFullscreenClick, ...props }: IProps) => {
    const { t } = useTranslation();
    const [viewportRef, carousel] = useEmblaCarousel({ ...defaultOptions, ...props });

    const [[canScrollPrev, canScrollNext], setFlags] = useState([false, false]);

    useEffect(() => {
        if (carousel) {
            setFlags([carousel.canScrollPrev(), carousel.canScrollNext()]);
            carousel.on('scroll', () => {
                setFlags([carousel.canScrollPrev(), carousel.canScrollNext()]);
            });
        }
    }, [carousel]);

    const handlePrev = () => {
        carousel?.scrollPrev();
    };

    const handleNext = () => {
        carousel?.scrollNext();
    };

    return (
        <>
            <Styled.Viewport ref={viewportRef}>{children}</Styled.Viewport>
            <Styled.Controls>
                <Button design='secondary' prefix={<Fullscreen size={24} />} onClick={onFullscreenClick}>
                    {t('common.carousel.show_all')}
                </Button>
                {(canScrollPrev || canScrollNext) && (
                    <>
                        <Button
                            design='secondary'
                            prefix={<BackArrowLeft size={24} />}
                            disabled={!canScrollPrev}
                            onClick={handlePrev}
                        />
                        <Button
                            design='secondary'
                            prefix={<ForwardArrowRight size={24} />}
                            disabled={!canScrollNext}
                            onClick={handleNext}
                        />
                    </>
                )}
            </Styled.Controls>
        </>
    );
};
