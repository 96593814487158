import React, { ReactNode } from 'react';
import { useBoolean } from 'usehooks-ts';
import { Text } from '@indriver/nova';
import { useBreakpoints } from 'common/lib';
import * as Styled from './tooltip.styles';

interface IProps {
    children: ReactNode;
    tooltipOffsetRight?: number;
    onClick?: () => void;
    text?: string;
}

export const Tooltip = ({ children, tooltipOffsetRight, text, onClick }: IProps) => {
    const media = useBreakpoints();
    const visibility = useBoolean();

    return (
        <Styled.TooltipContainer
            onMouseEnter={() => {
                visibility.setTrue();
            }}
            onMouseLeave={() => {
                visibility.setFalse();
            }}
            onClick={onClick}>
            {children}
            {visibility.value && media.isGtS && (
                <Styled.TooltipPopup offsetRight={tooltipOffsetRight}>
                    <Text size='s' color='var(--text-and-icon-oncolor)'>
                        {text}
                    </Text>
                </Styled.TooltipPopup>
            )}
        </Styled.TooltipContainer>
    );
};
