import { superMastersApi } from './base';
import type {
    IOrderData,
    IImageResponse,
    IOrdersResponse,
    IOrderResponse,
    OrderField,
    OrdersFilterMode,
} from './models';

interface IGetOrdersParams {
    locale: string;
    ids: string[];
    cityId: number;
    mode: OrdersFilterMode;
}

export const getOrders = async ({ locale, cityId, mode, ids }: IGetOrdersParams) => {
    const response = await superMastersApi.post<IOrdersResponse>(
        'orders',
        { mode, ids },
        {
            headers: {
                'Accept-Language': locale,
                'X-City-Id': cityId,
            },
        },
    );
    return response.data.collection;
};

interface IGetOrderParams {
    locale: string;
    cityId?: number;
    orderId: string;
}

export const getOrder = async ({ locale, cityId, orderId }: IGetOrderParams) => {
    const response = await superMastersApi.get<IOrderResponse>(`order/${orderId}`, {
        headers: {
            'Accept-Language': locale,
            'X-City-Id': cityId,
        },
    });
    return response.data;
};

interface ICustomerOrderIds {
    locale: string;
    cityId: number;
}

export const getCustomerOrderIds = async ({ locale, cityId }: ICustomerOrderIds) => {
    const response = await superMastersApi.get<{ collection: string[] }>('orders/customer', {
        headers: {
            'Accept-Language': locale,
            'X-City-Id': cityId,
        },
    });
    return response.data.collection;
};

export const postOrder = async (data: IOrderData, locale: string, cityId: number) => {
    const response = await superMastersApi.post<{ id: string }>('order', data, {
        headers: {
            'Accept-Language': locale,
            'X-City-Id': cityId,
        },
    });
    return response.data;
};

export const postImage = async (data: FormData, cityId: number) => {
    const response = await superMastersApi.post<IImageResponse>('image', data, {
        headers: {
            'X-City-Id': cityId,
        },
        timeout: 0,
    });
    return response.data;
};

export const postCancelOrder = async (orderId: string, cityId: number) => {
    const response = await superMastersApi.post<{ id: string }>(
        `order/${orderId}/cancel`,
        {},
        {
            headers: {
                'X-City-Id': cityId,
            },
        },
    );
    return response.data;
};

export const postCompleteOrder = async (orderId: string, cityId: number) => {
    const response = await superMastersApi.post<{ id: string }>(
        `order/${orderId}/complete`,
        {},
        {
            headers: {
                'X-City-Id': cityId,
            },
        },
    );
    return response.data;
};

interface IPatchUpdateOrderParams {
    cityId: number;
    orderId: string;
    updateOrderBody: { fields: OrderField[] };
}

export const patchUpdateOrder = async ({ cityId, orderId, updateOrderBody }: IPatchUpdateOrderParams) => {
    const response = await superMastersApi.patch<{ id: string }>(`order/${orderId}`, updateOrderBody, {
        headers: {
            'X-City-Id': cityId,
        },
    });
    return response.data;
};
