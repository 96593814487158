import React from 'react';
import { defaultMapValueToString, IInputProps, Input } from '@indriver/nova';
import { IAutocompleteProps } from '@indriver/nova/dist/types/autocomplete/autocomplete';
import * as Styled from './autocomplete-mobile.styles';

export const AutocompleteMobile = <T,>({
    onInputChange,
    children,
    mapValueToString = defaultMapValueToString,
    value,
    ...props
}: IAutocompleteProps<T>) => {
    const [inputValue, setInputValue] = React.useState(mapValueToString(value));

    const handleInputChange: IInputProps['onChange'] = (_, { value: autocompleteValue }) => {
        setInputValue(autocompleteValue);
        onInputChange?.(autocompleteValue);
    };

    React.useEffect(() => {
        if (value) {
            setInputValue(mapValueToString(value));
        } else if (value !== null) {
            setInputValue('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <>
            <Input value={inputValue} autoComplete='off' onChange={handleInputChange} {...props} />
            <Styled.Results>{children}</Styled.Results>
        </>
    );
};
