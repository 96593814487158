import { Router, useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { IDomainI18nConfig } from 'entities/domain';
import { shallowEqual } from '../shallow-equal';
import { stripUrlLocale } from '../url';

export const useDefaultLocale = (domainI18nConfig: IDomainI18nConfig) => {
    const router = useRouter();

    const last = useRef<unknown[] | undefined>();

    const deps = [router, domainI18nConfig];

    if (!last.current || !shallowEqual(last.current, deps)) {
        router.locales = domainI18nConfig.locales;
        router.defaultLocale = domainI18nConfig.defaultLocale;
        last.current = deps;
    }

    const { defaultLocale } = domainI18nConfig;
    useEffect(() => {
        const handleRouteChange = (uri: string) => {
            const { pathname, search } = stripUrlLocale(new URL(uri, 'http://n'), defaultLocale);
            window.history.replaceState(
                { locale: defaultLocale, scroll: false, shallow: true },
                '',
                `${pathname.toLowerCase()}${search}`,
            );
        };

        Router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            Router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [defaultLocale]);
};
