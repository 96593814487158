import { useRouter } from 'next/router';

export const useUtmParams = () => {
    const router = useRouter();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { utm_source, utm_medium, utm_campaign, utm_term, utm_content, adposition } = router.query;

    return {
        utm_source: utm_source as string,
        utm_medium: utm_medium as string,
        utm_campaign: utm_campaign as string,
        utm_term: utm_term as string,
        utm_content: utm_content as string,
        adposition: adposition as string,
    };
};
