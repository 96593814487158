import { useTranslation } from 'next-i18next';
import { renderTimeWorked } from 'common/lib';
import { InlineItem } from 'common/ui';
import { ITasker } from '../../model/types';

interface IProps {
    tasker: ITasker;
}

export const Experience = ({ tasker }: IProps) => {
    const { t } = useTranslation();
    return tasker.joinedAt ? <InlineItem>{renderTimeWorked(t, tasker.joinedAt)}</InlineItem> : null;
};
