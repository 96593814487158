import { useMemo, useRef } from 'react';

export const useClampedText = (lines: number) => {
    const textRef = useRef<HTMLDivElement>(null);
    const isOverflowing = useMemo(
        () => textRef.current && textRef.current.scrollHeight > textRef.current.clientHeight,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [textRef.current?.innerText, lines],
    );

    return { textRef, isOverflowing };
};
