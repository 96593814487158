import { useTranslation } from 'next-i18next';
import { FAQPageJsonLd } from 'next-seo';
import Image from 'next/image';
import { Heading, Text } from '@indriver/nova';
import { ISeoFaqContent } from 'common/api';
import { decodeEntities, nl2br, useBreakpoints } from 'common/lib';
import { usePageCity } from 'entities/city';
import * as Styled from './faq.styles';

interface IProps {
    contentFAQ: ISeoFaqContent | undefined;
    serviceName: string | undefined;
}

export const Faq = ({ contentFAQ, serviceName }: IProps) => {
    const { t } = useTranslation();
    const currentCity = usePageCity();
    const media = useBreakpoints();

    return (
        <section>
            <Styled.FAQContainer>
                <Styled.HeadingContainer>
                    <Heading
                        type='h2'
                        size={media.isGtS ? 'xxl' : 'xl'}
                        style={{ marginBottom: 8, whiteSpace: 'nowrap' }}>
                        {t('masters_wizard_form_faq_heading')}
                    </Heading>
                    <Text
                        size='m'
                        weight='medium'
                        color='var(--text-and-icon-secondary)'
                        style={{ marginBottom: media.isGtM ? 64 : 0 }}>
                        {t('masters_wizard_form_faq_city', {
                            catalog_name: serviceName,
                            city_name: currentCity.name,
                        })}
                    </Text>
                    {media.isGtM ? (
                        <Image
                            src='/images/main-page/thinking-faq.svg'
                            alt=''
                            layout='fixed'
                            width='230'
                            height='230'
                        />
                    ) : null}
                </Styled.HeadingContainer>
                <div>
                    <Styled.ContentFAQ>
                        {contentFAQ?.items.map((item) => (
                            <Styled.ItemContentFAQ key={item.question}>
                                <Heading type='h2' size={media.isGtS ? 'l' : 'ml'} style={{ marginBottom: 16 }}>
                                    {item.question}
                                </Heading>
                                <Text size='m' dangerouslySetInnerHTML={{ __html: nl2br(item.answer) }} />
                            </Styled.ItemContentFAQ>
                        ))}
                    </Styled.ContentFAQ>
                    <Styled.CreateOrderWrapper>
                        <Text size='ml' weight='regular'>
                            {t('masters_wizard_form_faq_create_task_description')}
                        </Text>
                        <Styled.CreateOrderButton
                            stretched={!media.isGtS}
                            size='m'
                            onClick={() => {
                                window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth', // для плавной прокрутки
                                });
                            }}>
                            {t('masters_wizard_form_faq_create_task_button_title')}
                        </Styled.CreateOrderButton>
                    </Styled.CreateOrderWrapper>
                </div>
            </Styled.FAQContainer>
            {contentFAQ && (
                <FAQPageJsonLd
                    mainEntity={contentFAQ?.items.map((item) => ({
                        questionName: item.question,
                        acceptedAnswerText: nl2br(decodeEntities(item.answer)),
                    }))}
                />
            )}
        </section>
    );
};
