import { QueryObserverOptions, useMutation } from '@tanstack/react-query';
import { user } from 'common/api';

// TODO: Why is `get` request a mutation?
export const useUserInfoMutation = () => useMutation(user.getUserInfo);

export const useUpdateUserInfoMutation = (onError: QueryObserverOptions['onError']) =>
    // TODO: Errors should be treated explicitly in-place
    useMutation(user.updateUserInfo, { onError });

export const useUserLocaleMutation = () => useMutation(user.updateUserLocale);
