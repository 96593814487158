import React from 'react';
import { Text } from '@indriver/nova';
import { useBreakpoints } from 'common/lib';
import * as Styled from './inline-alert.styles';

export type InlineAlertDesign = 'lightwarning' | 'success';

interface IProps {
    design: InlineAlertDesign;
    icon: React.ReactNode;
    children: React.ReactNode;
}

export const InlineAlert = ({ design, icon, children }: IProps) => {
    const media = useBreakpoints();

    return (
        <Styled.InlineAlert design={design}>
            <Styled.IconWrapper>{icon}</Styled.IconWrapper>
            <div>
                <Text size={media.isGtS ? 'ml' : 's'}>{children}</Text>
            </div>
        </Styled.InlineAlert>
    );
};
