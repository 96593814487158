import React from 'react';
import { CountryCode, mapCurrency } from 'common/lib/map-currency';
import { usePageCity } from 'entities/city';
import { StyledField, StyledFieldLabel, StyledFieldValue } from './order-field.styles';

interface IOrderFieldProps {
    label: string;
    value?: string | number | null;
    isPrice?: boolean;
    children?: React.ReactNode;
}

export const OrderField = ({ label, value, isPrice = false, children }: IOrderFieldProps) => {
    const countryIso = usePageCity().countryIso.toUpperCase() as CountryCode;
    return (
        <StyledField>
            <StyledFieldLabel>{label}</StyledFieldLabel>
            {children ?? (
                <StyledFieldValue>
                    {value}
                    {isPrice && mapCurrency(countryIso)}
                </StyledFieldValue>
            )}
        </StyledField>
    );
};
