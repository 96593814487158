export const BREAKPOINTS = { xxs: 360, xs: 480, s: 768, m: 1024, l: 1280, xl: 1440, xxl: 1920 } as const;

export const xxs = `(max-width: ${BREAKPOINTS.xxs - 1}px)`;
export const gtXxs = `(min-width: ${BREAKPOINTS.xxs}px)`;

export const xs = `(max-width: ${BREAKPOINTS.xs - 1}px)`;
export const gtXs = `(min-width: ${BREAKPOINTS.xs}px)`;

export const s = `(max-width: ${BREAKPOINTS.s - 1}px)`;
export const gtS = `(min-width: ${BREAKPOINTS.s}px)`;

export const m = `(max-width: ${BREAKPOINTS.m - 1}px)`;
export const gtM = `(min-width: ${BREAKPOINTS.m}px)`;

export const l = `(max-width: ${BREAKPOINTS.l - 1}px)`;
export const gtL = `(min-width: ${BREAKPOINTS.l}px)`;

export const xl = `(max-width: ${BREAKPOINTS.xl - 1}px)`;
export const gtXl = `(min-width: ${BREAKPOINTS.xl}px)`;

export const xxl = `(max-width: ${BREAKPOINTS.xxl - 1}px)`;
export const gtXxl = `(min-width: ${BREAKPOINTS.xxl}px)`;
