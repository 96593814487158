import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { seo } from 'common/api';
import { useCitiesBySlug } from 'entities/city';

interface IUseGetTopCitiesByCategory {
    countryIso: string;
    categorySlug: string;
}

export const useGetTopCitiesByCategory = ({ countryIso, categorySlug }: IUseGetTopCitiesByCategory) => {
    const { data: citiesBySlug } = useCitiesBySlug();
    const { data: citySlugs } = useQuery({
        queryKey: ['getTopCitiesByCategory', { countryIso, categorySlug }],
        queryFn: async () => seo.getTopCitiesByCategory(countryIso, categorySlug),
    });

    return React.useMemo(() => {
        if (citiesBySlug === undefined || citySlugs === undefined) {
            return [];
        }

        return citySlugs.map((slug) => citiesBySlug[slug]).filter((city) => city !== undefined);
    }, [citiesBySlug, citySlugs]);
};
