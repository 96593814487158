import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { persistReducer, persistStore } from 'redux-persist';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/lib/constants';
import storage from 'redux-persist/lib/storage';
import { isSSR } from 'common/lib';
import { analyticsPayloadReducer } from 'entities/analytics';
import { currentCityReducer } from 'entities/city/model/city-slice';
import { notificationWhatsAppReducer } from 'entities/notification-whatsapp';
import { cancelOrderModalReducer } from 'features/cancel-order';
import { completeOrderModalReducer } from 'features/complete-order';
import { extendOrderModalReducer } from 'features/extend-order';
import { mixedModelTaskerModalReducer } from 'features/mixed-model';
import { wizardReducer } from 'features/wizard/model/wizard.slice';

const reducers = combineReducers({
    notificationWhatsApp: notificationWhatsAppReducer,
    currentCity: currentCityReducer,
    cancelOrderModal: cancelOrderModalReducer,
    completeOrderModal: completeOrderModalReducer,
    extendOrderModal: extendOrderModalReducer,
    analyticsPayload: analyticsPayloadReducer,
    mixedModelTaskerModal: mixedModelTaskerModalReducer,
    wizard: wizardReducer,
});

const makeConfiguredStore = () => configureStore({ reducer: reducers, devTools: true });

const persistedReducer = persistReducer(
    {
        key: 'root',
        storage,
        whitelist: ['currentCity'],
    },
    reducers,
);

const makeConfiguredPersistorStore = () => {
    const store = configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                },
            }),
    });

    return { ...store, persistor: persistStore(store) };
};

export const makeStore = () => (isSSR ? makeConfiguredStore() : makeConfiguredPersistorStore());

export type RootStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<RootStore['getState']>;

export const wrapper = createWrapper(makeStore);
