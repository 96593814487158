import { useTranslation } from 'next-i18next';
import { NextSeo } from 'next-seo';

interface IProps {
    url: string;
    title?: string;
    description?: string;
}

export const OgGraph = ({ url, title, description }: IProps) => {
    const { t, i18n } = useTranslation('seo_meta');

    return (
        <NextSeo
            openGraph={{
                type: 'Website',
                url,
                locale: i18n.language,
                title: title ?? t('seo_meta:title_generic'),
                description: description ?? t('seo_meta:description_generic'),
                images: [{ url: '/images/common/seo_cover.jpg' }],
            }}
        />
    );
};
