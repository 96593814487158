import React from 'react';
import ReactFocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';
import { useEventListener } from 'usehooks-ts';
import { Portal } from 'common/ui';
import * as Styled from './mobile-drawer.styles';

interface IProps {
    children: React.ReactNode;
    isOpen?: boolean;
    onClose?: () => void;
}

export const MobileDrawer = ({ children, isOpen, onClose }: IProps) => {
    useEventListener('keydown', (event) => {
        if (event.key === 'Escape' && isOpen) {
            onClose?.();
        }
    });

    return (
        <Portal>
            <RemoveScroll enabled={isOpen}>
                <ReactFocusLock disabled={!isOpen}>
                    <Styled.Box isOpen={isOpen}>{children}</Styled.Box>
                </ReactFocusLock>
            </RemoveScroll>
        </Portal>
    );
};
