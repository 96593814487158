import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { ReactNode } from 'react';
import { Button } from '@indriver/nova';
import { mapCountryIsoToJumbotron, recordEvent, useBreakpoints } from 'common/lib';
import { usePageCity } from 'entities/city';
import * as Styled from './jumbotron.styles';

interface IProps {
    h1: ReactNode;
}

export const Jumbotron = ({ h1 }: IProps) => {
    const { t } = useTranslation();

    const media = useBreakpoints();

    const router = useRouter();
    const { citySlug = '' } = router.query;
    const currentCity = usePageCity();

    const onButtonClick = () => {
        void router.push(`/${citySlug}#catalog`);
        recordEvent({
            actionAF: 'masters.customer.main_page_find_master.click',
            actionAmplitude: 'masters_customer_main_page_find_master.click',
            actionGA: 'masters_customer_mp_find_master_click',
            payload: {
                city_id: currentCity.id,
                city_name: currentCity.name,
                country_id: currentCity.countryId,
                country_name: currentCity.countryName,
            },
        });
    };

    return (
        <Styled.Wrapper>
            <Styled.Content>
                <Styled.Heading type='h1' size='xxl' fontType='marketing' margin='s'>
                    {h1}
                </Styled.Heading>
                <Button size='l' stretched={!media.isGtS} onClick={onButtonClick}>
                    {t('main_page_jumbotron_button_title')}
                </Button>
            </Styled.Content>
            <Styled.ImageWrapper>
                {media.isGtL ? (
                    <Image
                        src={`/images/main-page/${mapCountryIsoToJumbotron(currentCity.countryIso)}`}
                        alt=''
                        layout='fixed'
                        width={682}
                        height={481}
                        quality={50}
                        priority
                    />
                ) : (
                    <Image
                        src={`/images/main-page/${mapCountryIsoToJumbotron(currentCity.countryIso, media.isGtS)}`}
                        alt=''
                        layout='fill'
                        objectFit='contain'
                        objectPosition='center bottom'
                        quality={50}
                        priority
                    />
                )}
            </Styled.ImageWrapper>
        </Styled.Wrapper>
    );
};
