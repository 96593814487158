const COUNTRY_UNITS: Record<string, { unit: string; ratio: number }> = {
    us: {
        unit: 'mi',
        ratio: 0.621371,
    },
    default: {
        unit: 'km',
        ratio: 1,
    },
};

export const getCountryUnit = (countryIso: string) => COUNTRY_UNITS[countryIso] ?? COUNTRY_UNITS.default;
