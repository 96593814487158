import { formatDistanceToNow } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { RatingBar, Text } from '@indriver/nova';
import { variables } from '@indriver/yrel';
import { IWizardReview } from 'common/api';
import { getDateFnsLocale } from 'common/lib/date-fns-i18n';
import { ClampedText } from 'common/ui';
import { UserAvatar } from 'entities/user';
import * as Styled from './review-card.styles';

interface IProps {
    review: IWizardReview;
}

export const ReviewCard = ({ review }: IProps) => {
    const { t, i18n } = useTranslation('common');

    return (
        <Styled.Wrapper>
            <Styled.Card>
                <Styled.Rating>
                    <RatingBar value={review.rating} size='m' />
                </Styled.Rating>
                <Text size='m' color={variables['text-and-icon-primary']}>
                    <ClampedText lines={6} expanded>
                        {review.message}
                    </ClampedText>
                </Text>
            </Styled.Card>
            <Styled.Footer>
                <Styled.Avatars>
                    <Styled.AuthorAvatar>
                        <UserAvatar size='m' design='earth' user={review.author} />
                    </Styled.AuthorAvatar>
                    <Styled.TargetAvatar>
                        <UserAvatar size='m' design='air' user={review.target} />
                    </Styled.TargetAvatar>
                </Styled.Avatars>
                <Text size='s' color={variables['text-and-icon-secondary']}>
                    {t('wizard_reviews_target_author', {
                        target: review.target.first_name,
                        author: review.author.first_name,
                        timeSince: formatDistanceToNow(new Date(review.created_at), {
                            locale: getDateFnsLocale(i18n.resolvedLanguage),
                            addSuffix: true,
                        }).replace(/(\d+) /g, '$1\u00a0'),
                    })}
                </Text>
            </Styled.Footer>
        </Styled.Wrapper>
    );
};

export const ReviewCardMobile = ({ review }: IProps) => {
    const { t, i18n } = useTranslation();

    return (
        <Styled.Wrapper>
            <Styled.Card style={{ borderRadius: '12px' }}>
                <Styled.Rating>
                    <RatingBar value={review.rating} size='m' />
                </Styled.Rating>
                <Text size='s' color={variables['text-and-icon-primary']}>
                    {review.message}
                </Text>
                <Styled.Footer>
                    <Text size='s' color={variables['text-and-icon-secondary']}>
                        {t('wizard_reviews_target_author', {
                            target: review.target.first_name,
                            author: review.author.first_name,
                            timeSince: formatDistanceToNow(new Date(review.created_at), {
                                locale: getDateFnsLocale(i18n.resolvedLanguage),
                                addSuffix: true,
                            }).replace(/(\d+) /g, '$1\u00a0'),
                        })}
                    </Text>
                    <Styled.Avatars>
                        <Styled.AuthorAvatar>
                            <UserAvatar size='m' design='earth' user={review.author} />
                        </Styled.AuthorAvatar>
                        <Styled.TargetAvatar>
                            <UserAvatar size='m' design='air' user={review.target} />
                        </Styled.TargetAvatar>
                    </Styled.Avatars>
                </Styled.Footer>
            </Styled.Card>
        </Styled.Wrapper>
    );
};
