import { useTranslation } from 'next-i18next';
import React, { ReactNode } from 'react';
import { SafetyFilled } from '@indriver/mireska';
import { Heading, Text } from '@indriver/nova';
import { variables } from '@indriver/yrel';
import { ITaskerInfo, IWizardReviewUser } from 'common/api';
import { anonymizeName } from 'common/lib';
import { AvatarWithBadge, InlineItem } from 'common/ui';
import * as Styled from './tasker-card.styles';

interface ITaskerCardProps {
    taskerUser: IWizardReviewUser;
    taskerInfo: ITaskerInfo;
    footer?: ReactNode;
}

export const TaskerCard = ({ taskerUser, taskerInfo, footer }: ITaskerCardProps) => {
    const { t } = useTranslation('common');
    return (
        <Styled.Container>
            <Styled.Header>
                <AvatarWithBadge
                    avatarSize='xxxl'
                    badgeSize='m'
                    content={taskerUser.first_name}
                    iconSize={16}
                    image={taskerUser.avatar}
                    rating={taskerInfo.rating}
                />
                <Heading
                    type='h3'
                    size='xl'
                    style={{ marginBlock: `${variables['spacers-s']} ${variables['spacers-2xs']}` }}>
                    {anonymizeName(taskerUser)}
                    <Styled.TooltipWrapper>
                        <Styled.RoundIcon size='xs' content={<SafetyFilled size={12} />} />
                        <Styled.Tooltip>
                            <Text size='s' weight='medium' color={variables['text-and-icon-oncolor']}>
                                {t('top_masters__passport_verified')}
                            </Text>
                        </Styled.Tooltip>
                    </Styled.TooltipWrapper>
                </Heading>
                <Text weight='medium'>
                    {taskerInfo.completed_orders > 1 && (
                        <InlineItem>{t('common_orders', { count: taskerInfo.completed_orders })}</InlineItem>
                    )}
                    {taskerInfo.count_review && taskerInfo.count_review > 1 && (
                        <InlineItem>{t('common_reviews', { count: taskerInfo.count_review })}</InlineItem>
                    )}
                </Text>
            </Styled.Header>

            {footer && <Styled.Footer>{footer}</Styled.Footer>}
        </Styled.Container>
    );
};
