import { addMinutes, set } from 'date-fns';
import { ceilTime } from './ceil-time';

export const getNearestAvailableTime = (date: Date) => {
    const currentDate = new Date();
    const updatedDate = set(date, {
        minutes: currentDate.getMinutes(),
        hours: currentDate.getHours(),
    });
    return addMinutes(ceilTime(updatedDate), 30);
};
