import React, { RefObject } from 'react';

type Handler = (event: MouseEvent | TouchEvent) => void;

/**
 * @deprecated For dropdowns that automatically close when clicking outside, please use the `DropList's` `onClickOutside` prop.
 */
export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
    ref: RefObject<T>,
    isOpen: boolean,
    handler: Handler,
    portalId = 'portal',
): void {
    React.useEffect(() => {
        const listener = (mouseEvent: MouseEvent | TouchEvent) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(mouseEvent.target as Node) || document.getElementById(portalId)) {
                return;
            }
            handler(mouseEvent);
        };
        if (isOpen) {
            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);
        }

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, isOpen, handler, portalId]);
}
