import { NextRouter, useRouter } from 'next/router';
import React, { FC } from 'react';
import { Body } from './app.styles';
import './global.styles';

const CURLY_PAGES = new Set(['/service/[serviceSlug]', '/service/[serviceSlug]/[subServiceSlug]']);

const getBackground = (router: NextRouter) => {
    if (CURLY_PAGES.has(router.pathname)) {
        return 'curly';
    }

    if (router.pathname === '/orders') {
        return 'secondary';
    }

    return 'primary';
};

export const App: FC = ({ children }) => {
    const router = useRouter();
    return (
        <Body id='app-body' background={getBackground(router)}>
            {children}
        </Body>
    );
};
