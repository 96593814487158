import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { Close, FavFilled } from '@indriver/mireska';
import { RoundButton, Text } from '@indriver/nova';
import { InlineItem, Portal } from 'common/ui';
import * as Styled from './reviews-modal.styles';

interface IProps {
    taskerRating: number | undefined;
    reviewsCount: number | undefined;
    children: ReactNode;
    onClose: () => void;
}

export const ReviewsModal = ({ taskerRating, reviewsCount, children, onClose }: IProps) => {
    const { t } = useTranslation();
    return (
        <Portal portalId='reviews'>
            <RemoveScroll>
                <Styled.Backdrop
                    onClick={(e) => {
                        if (e.target === e.currentTarget) {
                            onClose();
                        }
                    }}>
                    <Styled.Modal>
                        <Styled.Header>
                            <RoundButton
                                size='s'
                                prefix={<Close size={20} color='var(--text-and-icon-primary)' />}
                                style={{ margin: 0 }}
                                onClick={onClose}
                            />
                            <Text
                                size='ml'
                                weight='bold'
                                color='var(--text-and-icon-primary)'
                                style={{ display: 'inline-flex' }}>
                                <InlineItem style={{ display: 'inline-flex', alignItems: 'center', gap: '4px' }}>
                                    <FavFilled size={20} />
                                    {taskerRating}
                                </InlineItem>
                                <InlineItem>{t('entities.tasker.reviews', { count: reviewsCount })}</InlineItem>
                            </Text>
                        </Styled.Header>
                        <Styled.Content>{children}</Styled.Content>
                    </Styled.Modal>
                </Styled.Backdrop>
            </RemoveScroll>
        </Portal>
    );
};
