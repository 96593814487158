import { useTranslation, i18n } from 'next-i18next';
import React from 'react';
import { LegacyReviewCard } from '../legacy-review-card';
import {
    StyledReviewsCarousel,
    StyledReviewsSectionHeading,
    StyledReviewsSectionSubText,
} from './reviews-section.styles';

const REVIEWS = () => [
    {
        id: 1,
        imageSrc: '/images/main-page/review-avatar-1.jpg',
        name: i18n?.t('reviews_first_review_name') as string,
        serviceName: i18n?.t('reviews_first_review_specialist') as string,
        reviewText: i18n?.t('reviews_first_review_text') as string,
        pills: [
            i18n?.t('reviews_pill_politeness') as string,
            i18n?.t('reviews_pill_cleanliness') as string,
            i18n?.t('reviews_pill_excellent_work') as string,
            i18n?.t('reviews_pill_fast_work') as string,
        ],
    },
    {
        id: 2,
        imageSrc: '/images/main-page/review-avatar-2.jpg',
        name: i18n?.t('reviews_second_review_name') as string,
        serviceName: i18n?.t('reviews_second_review_specialist') as string,
        reviewText: i18n?.t('reviews_second_review_text') as string,
        pills: [
            i18n?.t('reviews_pill_professional_tools') as string,
            i18n?.t('reviews_pill_politeness') as string,
            i18n?.t('reviews_pill_cleanliness') as string,
        ],
    },
    {
        id: 3,
        imageSrc: '/images/main-page/review-avatar-3.jpg',
        name: i18n?.t('reviews_third_review_name') as string,
        serviceName: i18n?.t('reviews_third_review_specialist') as string,
        reviewText: i18n?.t('reviews_third_review_text') as string,
        pills: [
            i18n?.t('reviews_pill_politeness') as string,
            i18n?.t('reviews_pill_high_quality_work') as string,
            i18n?.t('reviews_pill_excellent_work') as string,
            i18n?.t('reviews_pill_fast_work') as string,
        ],
    },
    {
        id: 4,
        imageSrc: '/images/main-page/review-avatar-4.jpg',
        name: i18n?.t('reviews_fourth_review_name') as string,
        serviceName: i18n?.t('reviews_fourth_review_specialist') as string,
        reviewText: i18n?.t('reviews_fourth_review_text') as string,
        pills: [
            i18n?.t('reviews_pill_politeness') as string,
            i18n?.t('reviews_pill_cleanliness') as string,
            i18n?.t('reviews_pill_excellent_work') as string,
            i18n?.t('reviews_pill_fast_work') as string,
        ],
    },
];

export const ReviewsSection = () => {
    const { t } = useTranslation();
    return (
        <section>
            <StyledReviewsSectionHeading>{t('main_page_reviews_section_heading')}</StyledReviewsSectionHeading>
            <StyledReviewsSectionSubText>{t('main_page_reviews_section_description')}</StyledReviewsSectionSubText>
            <StyledReviewsCarousel>
                {REVIEWS().map((review) => (
                    <LegacyReviewCard
                        key={review.id}
                        imageSrc={review.imageSrc}
                        name={review.name}
                        serviceName={review.serviceName}
                        reviewText={review.reviewText}
                        pills={review.pills}
                    />
                ))}
            </StyledReviewsCarousel>
        </section>
    );
};
