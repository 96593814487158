import { useQuery } from '@tanstack/react-query';
import { getOffers, IGetOffersProps } from 'common/api/offer';

export const getGetOffersKey = (params: IGetOffersProps) => ['getOffers', params];

export const useGetOffersParams = (params: IGetOffersProps) => ({
    queryKey: getGetOffersKey(params),
    queryFn: async () => getOffers(params),
});

export const useGetOffers = (params: IGetOffersProps) => useQuery(useGetOffersParams(params));
