import { useQuery } from '@tanstack/react-query';
import { NextRouter, useRouter } from 'next/router';
import React from 'react';
import { city as cityApi } from 'common/api';
import { useDomainInfo } from 'entities/domain';
import { DEFAULT_CITY } from './default-city';
import {
    aggregateCities,
    citiesBySlugSelector,
    cityRecommendationSelector,
    citySelector,
    domainCitiesSelector,
    pickTopCities,
    priorityCitiesSelector,
    recommendedCitySelector,
} from './query-selectors';
import { useUserCity } from './redux-selectors';
import { ICityInfo } from './types';

interface ITopCitiesParams {
    countryIso: string;
}

export const useTopCities = ({ countryIso }: ITopCitiesParams) =>
    useQuery({
        queryKey: ['getCountries'],
        queryFn: cityApi.getCountries,
        select: React.useCallback((countries) => pickTopCities(countries, countryIso), [countryIso]),
    });

export const useCitiesList = (enabled = true) =>
    useQuery({
        queryKey: ['getCountries'],
        queryFn: cityApi.getCountries,
        select: aggregateCities,
        enabled,
    });

export const useCitiesBySlug = () =>
    useQuery({
        queryKey: ['getCountries'],
        queryFn: cityApi.getCountries,
        select: citiesBySlugSelector,
    });

export const useCityBySlugQuery = (citySlug?: string) =>
    useQuery({
        queryKey: ['getCountries'],
        queryFn: cityApi.getCountries,
        select: React.useCallback((data) => citySelector(data, citySlug), [citySlug]),
        enabled: !!citySlug,
    });

interface IUseOrderedCitiesListParams {
    domainCountryIso: string | undefined;
    pageCountryIso: string;
}

export const useOrderedCitiesList = ({ domainCountryIso, pageCountryIso }: IUseOrderedCitiesListParams) =>
    useQuery({
        queryKey: ['getCountries'],
        queryFn: cityApi.getCountries,
        select: React.useCallback(
            (data) =>
                domainCountryIso === undefined
                    ? priorityCitiesSelector(data, pageCountryIso)
                    : domainCitiesSelector(data, domainCountryIso),
            [domainCountryIso, pageCountryIso],
        ),
    });

export const useCityRecommendation = (enabled = true) => {
    const citiesListQuery = useCitiesList(enabled);
    return useQuery({
        queryKey: ['getRecommendedCity'],
        queryFn: cityApi.getRecommendedCity,
        select: React.useCallback(
            ([nearestCity]) => cityRecommendationSelector(nearestCity, citiesListQuery.data),
            [citiesListQuery.data],
        ),
        enabled: enabled && !!citiesListQuery.data,
    });
};

export const useRecommendedCity = (enabled = true) => {
    const citiesListQuery = useCitiesList(enabled);
    return useQuery({
        queryKey: ['getRecommendedCity'],
        queryFn: cityApi.getRecommendedCity,
        select: React.useCallback(
            ([nearestCity]) => recommendedCitySelector(nearestCity, citiesListQuery.data),
            [citiesListQuery.data],
        ),
        enabled: enabled && !!citiesListQuery.data,
    });
};

interface IPageCityInfo {
    source: 'url' | 'user' | 'geo_ip' | 'fallback' | 'failed';
    cityInfo: ICityInfo;
}

const matchesCountry = (countryIso: string | undefined, cityInfo: ICityInfo) =>
    countryIso === undefined || countryIso === cityInfo.countryIso;

export const usePageCityInfo = (citySlug?: string): IPageCityInfo => {
    const { defaultCitySlug, countryIso } = useDomainInfo();
    const defaultCityBySlugQuery = useCityBySlugQuery(defaultCitySlug);
    const cityBySlugQuery = useCityBySlugQuery(citySlug);
    const userCity = useUserCity();
    const recommendedCityQuery = useRecommendedCity(!citySlug && !userCity);

    if (cityBySlugQuery.data && matchesCountry(countryIso, cityBySlugQuery.data)) {
        return {
            source: 'url',
            cityInfo: cityBySlugQuery.data,
        };
    }

    if (userCity && matchesCountry(countryIso, userCity)) {
        return {
            source: 'user',
            cityInfo: userCity,
        };
    }

    if (recommendedCityQuery.data && matchesCountry(countryIso, recommendedCityQuery.data)) {
        return {
            source: 'geo_ip',
            cityInfo: recommendedCityQuery.data,
        };
    }

    if (defaultCityBySlugQuery.data && matchesCountry(countryIso, defaultCityBySlugQuery.data)) {
        return {
            source: 'fallback',
            cityInfo: defaultCityBySlugQuery.data,
        };
    }

    // last resort: falling back to global default city
    return {
        source: 'fallback',
        cityInfo: DEFAULT_CITY,
    };
};

const getCitySlug = (router: NextRouter) => {
    const { citySlug } = router.query as { citySlug?: string };
    return citySlug;
};

export const usePageCity = () => {
    const router = useRouter();
    const citySlug = getCitySlug(router);
    return usePageCityInfo(citySlug).cityInfo;
};
