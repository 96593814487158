import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { getUserNotifications } from 'common/api/notification';
import { selectIsWhatsAppEnabled } from '.';

export const useIsWhatsAppEnabled = (isAuthorizedUser: boolean) =>
    useQuery({
        queryKey: ['getUserNotifications'],
        queryFn: async () => getUserNotifications(),
        select: useCallback(selectIsWhatsAppEnabled, []),
        enabled: isAuthorizedUser,
    });
