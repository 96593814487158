import { cx } from '@linaria/core';
import React from 'react';
import * as Styled from './hint.styles';

interface IProps {
    icon: React.ReactNode;
    title?: string;
    description: React.ReactNode;
    isMixedModel?: boolean;
}

export const Hint = ({ icon, title, description, isMixedModel }: IProps) => {
    if (!title && !description) {
        return null;
    }

    return (
        <Styled.Hint className={cx(isMixedModel && Styled.mixedModel)}>
            <Styled.IconWrapper>{icon}</Styled.IconWrapper>
            {title && <Styled.Title>{title}</Styled.Title>}
            <Styled.Description>{description}</Styled.Description>
        </Styled.Hint>
    );
};
