import { useTranslation } from 'next-i18next';
import React, { MouseEvent } from 'react';
import { IDatetime, IDatetimeData, IExtendedOrder, IOrder } from 'common/api';
import { CategoryIcon, InlineItem, RoundedCell } from 'common/ui';
import { formatOrderDate } from '../../lib/format-order-date';
import { getOrderSection } from '../../model/query-selectors';
import { OrdersSection } from '../../model/types';

interface IProps {
    order: IExtendedOrder;
    onClick?: (e: MouseEvent<HTMLElement>) => void;
}

const SHOW_BIDS_COUNT = new Set([OrdersSection.expired, OrdersSection.search, OrdersSection.choose]);

const isFieldDatetime = (field: unknown): field is IDatetimeData => (field as { type: string }).type === 'date';

export const getDatetime = (order: IOrder): IDatetime | undefined => order.fields.find(isFieldDatetime)?.data;

export const OrderCell = ({ order, onClick }: IProps) => {
    const { t } = useTranslation();
    const datetime = getDatetime(order.order);
    const showBidsCount = SHOW_BIDS_COUNT.has(getOrderSection(order));
    return (
        <RoundedCell
            prefix={<CategoryIcon size={24} src={order.category.icon_url} />}
            subtitle={
                <>
                    {datetime && <InlineItem>{formatOrderDate(t, datetime)}</InlineItem>}
                    {showBidsCount && (
                        <InlineItem>{t('common_responses_count', { count: order.bids_count ?? 0 })}</InlineItem>
                    )}
                </>
            }
            withChevron
            onClick={onClick}>
            {order.order.sub_service_name ?? order.order.catalog_name}
        </RoundedCell>
    );
};
