import { useBoolean, useEventListener } from 'usehooks-ts';
import { UpArrow } from '@indriver/mireska';
import * as Styled from './scroll-to-top-button.styles';

interface IProps {
    anchorId: string;
}

export const ScrollToTopButton = ({ anchorId }: IProps) => {
    const visibility = useBoolean();

    useEventListener('scroll', () => {
        const componentAnchor = document.getElementById(anchorId);
        if (componentAnchor) {
            const componentPosition = componentAnchor.getBoundingClientRect().bottom;
            visibility.setValue(window.scrollY > componentPosition);
        }
    });

    const scrollToTop = () => {
        document.getElementById(anchorId)?.scrollIntoView({ behavior: 'smooth' });
    };

    if (!visibility.value) {
        return null;
    }

    return (
        <Styled.ScrollButton design='neutral-inverse' size='xl' content={<UpArrow size={24} />} onClick={scrollToTop} />
    );
};
