import { NextComponentType, NextPageContext } from 'next';
import { AppContext } from 'next/app';
import { getAlternateLinks } from './get-alternate-links';
import { getCanonicalLink } from './get-canonical-link';

type MastersPageComponentType = NextComponentType & {
    getCanonicalLink: (ctx: NextPageContext) => string;
    getAlternateLinks: (ctx: NextPageContext) => { hrefLang: string; href: string }[];
};

export const getMetaLinks = ({ Component, ctx }: AppContext) => ({
    canonicalLink: ((Component as MastersPageComponentType).getCanonicalLink ?? getCanonicalLink)(ctx),
    alternateLinks: ((Component as MastersPageComponentType).getAlternateLinks ?? getAlternateLinks)(ctx),
});
