export interface ICatalogResponse {
    collection: ICategory[];
}
export interface ICatalogPromotionResponse {
    collection: ICategoryPromotion[];
}

export interface ICategory {
    id: number;
    name: string;
    services: IService[];
    tasker_count: number;
    slug: string;
    link?: string;
    icon_url: string;
    title?: string;
}
export interface ICategoryPromotion {
    catalog_id: number;
    category_icon_url: string;
    category_name: string;
    category_slug: string;
    category_title: string;
    name: string;
    slug: string;
    tasker_count: number;
}

export interface IPrice {
    price_from: number;
    currency: string;
}

export interface IService {
    catalog_id: number;
    name: string;
    slug: string;
    sub_services?: IShallowSubService[];
}

export interface IWizardResponse {
    collection: IWizardStep[];
    reviews: IWizardReview[];
}

export interface IWizardReview {
    author: IWizardReviewUser;
    created_at: string;
    message: string;
    rating: number;
    target: IWizardReviewUser;
    tasker_info: ITaskerInfo;
    tasker_id: number;
}

export interface IWizardReviewUser {
    avatar: string;
    first_name: string;
    last_name: string;
}

export interface INotificationResponse {
    collection: INotificationCollection[];
}

export interface INotificationQueue {
    notifications: INotificationCollection[];
}

export interface INotificationCollection {
    enable: boolean;
    platform: string;
}

export interface IWizardStep {
    header: string;
    fields: IWizardField[];
}

export interface IWizardField {
    data: IWizardFieldData;
    description: string;
    field_id: number;
    placeholder: string;
    type: string;
}

export interface IWizardFieldData {
    choice_list?: {
        collection: { id: number; name: string }[];
        additional: { enabled: boolean; placeholder?: string };
    };
}

export interface IServiceField {
    id: number;
    name: string;
    type: string;
    editable: boolean;
    required: boolean;
    description: string;
    placeholder: string;
}

export interface IServiceTemplate {
    id: number;
    fields: IServiceField[];
}

export interface ICatalogServiceResponse {
    catalog_id: number;
    slug: string;
    sub_service_slug?: string;
    name: string;
    template: IServiceTemplate;
}

export interface IShallowSubService {
    name: string;
    slug: string;
}

export interface ICatalogSubServicesResponse {
    collection: IShallowSubService[];
}

export interface ICatalogServiceSearchItem {
    catalog_id: number;
    category_icon_url: string;
    category_name: string;
    category_slug: string;
    category_title: string;
    deeplink: string;
    name: string;
    slug: string;
}

export interface ICatalogServiceSearchResult {
    collection: ICatalogServiceSearchItem[];
}

export interface IActionNames {
    actionAF: string;
    actionGA: string;
    actionAmplitude: string;
}

export interface IRequestAuthCodeResponse {
    locale: string;
    title: string;
    mode: string;
    code: {
        placeholder: string;
        link: string;
        link_text: string;
        length: number;
    };
    resend: {
        text: string;
        timer: string;
        mode: string;
        timeout: number;
    };
    mode_from_request: string;
    phone: string;
    parse_call_status: string;
    whatsapp_installed: boolean;
    phone_without_country_code: string;
    phone_code: string;
    phone_modified_code: string;
    error: {
        text: string;
    };
}

export interface ICheckAuthCodeResponse {
    access_token: string;
    refresh_token: string;
    error: {
        text: string;
    };
}

export interface ITerms {
    privacy_policy_url: string;
    public_offer_url: string;
}

export interface IOrderData {
    catalog_id: number;
    sub_service_slug?: string;
    fields: OrderField[];
    idempotency_key: string;
    time_zone: string;
}

export interface IOrderReviewData {
    rating: number;
    comment: string;
    tag_ids: number[];
}

export interface IImageResponse {
    id: string;
}

export interface IAddressData {
    data: {
        text: string;
    };
}
export interface IAutocompleteAddressData {
    text: string;
    location: {
        latitude: number;
        longitude: number;
    };
}

export interface IDatetime {
    time: string;
    time_alias?: 'exact_time' | 'asap' | 'not_urgently';
    is_time_detailed: boolean;
}

export interface IDatetimeData {
    data: IDatetime;
}

export interface IDescriptionData {
    data: {
        text: string;
    };
}

export interface IMultiChoiceData {
    data: {
        choice_list: {
            id: number | null;
            name: string;
        }[];
    };
}

export interface IPhotosData {
    data: {
        photos: string[];
    };
}

export interface IPriceData {
    data: {
        price: number;
    };
}
export interface IContactData {
    data: {
        is_active: boolean;
    };
}

export interface ISingleChoiceData {
    data: {
        choice: {
            id: number | null;
            name: string;
        };
    };
}

export interface ITextFieldData {
    data: {
        text: string;
    };
}

export type DataIntersection = IAddressData & {
    data: IAutocompleteAddressData;
} & IDatetimeData &
    IPriceData &
    IContactData &
    IDescriptionData &
    ISingleChoiceData &
    IMultiChoiceData &
    ITextFieldData &
    IPhotosData;

/**
 * @deprecated
 * TODO: OrderField shouldn't be union of IServiceField and DataIntersection
 */
export type OrderField = IServiceField & DataIntersection;

export interface IUserShort {
    first_name: string;
    last_name: string;
    locale: string;
}

export interface IUser {
    user_id: number;
    first_name: string;
    last_name: string;
    avatar: string;
    phone: string;
}

export enum OrderStatus {
    new = 'new',
    published = 'published',
    inWork = 'in_work',
    expired = 'expired',
    canceled = 'canceled',
    completed = 'completed',
}

export type OrderStatusAsUnion = 'published' | 'new' | 'expired' | 'canceled' | 'completed' | 'in_work';

export interface IOrder {
    id: string;
    catalog_id: number;
    catalog_name: string;
    sub_service_slug?: string;
    sub_service_name?: string;
    user: IUser;
    fields: OrderField[];
    currency: string;
    status: OrderStatus;
    created_at: string;
}

export interface ICategoryInOrderResponse {
    icon_url: string;
}

export interface IOrderResponse {
    order: IOrder;
    category: ICategoryInOrderResponse;
    bid: IBid;
}

export interface ICity {
    id: number;
    name: string;
    slug: string;
}

export interface ICountry {
    id: number;
    name: string;
    iso: string;
    slug: string;
    cities: ICity[];
}

export interface ICountriesResponse {
    collection: ICountry[];
}

export interface ISeoResponse {
    collection: ISeoResponseEntry[];
}

export interface ISeoResponseEntry {
    content: ISeoFaqContent | ISeoTextContent;
    type: string;
}

export interface ISeoResponseFaqEntry extends ISeoResponseEntry {
    content: ISeoFaqContent;
    type: 'faq';
}

export interface ISeoFaqContent {
    items: ISeoFaqEntry[];
}

export interface ISeoFaqEntry {
    answer: string;
    question: string;
}

export interface ISeoResponseTextEntry extends ISeoResponseEntry {
    content: ISeoTextContent;
    type: 'title' | 'description' | 'h1' | 'h2' | 'long_description';
}

export interface ISeoTextContent {
    value: string;
}

export interface IRecommendedCity {
    id: number;
    name: string;
    radius: number;
    population: number;
    distance: number;
    region: {
        id: number;
        name: string;
    };
    country: {
        id: number;
        name: string;
        code: {
            String: string;
            Valid: boolean;
        };
    };
    subcity: boolean;
    type: string;
}

export interface INearCountriesResponse {
    nearCities: IRecommendedCity[];
}

export interface ICategoryShort {
    icon_url: string;
}

export interface IExtendedOrder {
    order: IOrder;
    bids_count?: number;
    category: ICategoryShort;
}

export interface IOrdersResponse {
    collection: IExtendedOrder[];
}

export type OrdersFilterMode = 'feed' | 'tasker' | 'customer' | 'profile';

export interface IReviewTag {
    id: number;
    name: string;
}

export interface IReview {
    created_at: string;
    id: number;
    message: string;
    order_id: string;
    rating: number;
    tags: IReviewTag[];
}
export interface ITimerResponse {
    target_id: string;
    timer: number;
}

export type BidStatus =
    | 'completed'
    | 'customer_canceled'
    | 'deleted'
    | 'tasker_canceled'
    | 'in_work'
    | 'new'
    | 'not_paid';

export interface ILocation {
    latitude: number;
    longitude: number;
}

export interface ITaskerInfoTag {
    name: string;
    image_url: string;
}

export interface ITaskerInfoField {
    id: string;
    data: { text: string } | { photos: { url: string }[] };
    title: string;
    placeholder: string;
    type: 'description' | 'photo';
    editable: boolean;
    status: 'approved' | 'denied' | 'checkin';
}

export interface ITaskerInfoPhotoField extends ITaskerInfoField {
    type: 'photo';
    data: { photos: { url: string }[] };
}

export interface ITaskerInfoDescriptionField extends ITaskerInfoField {
    type: 'description';
    data: { text: string };
}

export interface ITaskerInfo {
    joined_at: string;
    completed_orders: number;
    rating?: number;
    count_review?: number;
    subscriptions_count: number;
    tags: ITaskerInfoTag[];
    fields: ITaskerInfoField[];
}

export interface IBid {
    id: string;
    user: IUser;
    status: BidStatus;
    price: number;
    currency: string;
    comment: string;
    is_contacted: boolean;
    location: ILocation;
    created_at: string;
    tasker_info: ITaskerInfo;
}
export interface IOffer {
    is_sent: boolean;
    tasker_id: number;
    location: ILocation;
    comment?: string;
    user: IUser;
    tasker_info: ITaskerInfo;
    review?: IWizardReview;
}

export interface IBidsResponse {
    collection: IBid[];
}
export interface IOffersResponse {
    collection: IOffer[];
}
export interface IReviewExist {
    is_exist: boolean;
    order_id: string;
}
export interface IReviewsExistResponse {
    collection: IReviewExist[];
}

export interface ITag {
    id: number;
    name: string;
}

export interface IReviewTags {
    rating: number;
    tags: ITag[];
}

export interface IStream {
    id: number;
    app: string;
    event: 'customer_new_bid';
    payload: {
        count: number;
        order_id: number;
        order_uuid: string;
    };
}

export interface IStreamResponse {
    last_id: number;
    streams: IStream[];
}

export interface IProfileReviewsIdsResponse {
    collection: number[];
}

export interface IReviewsFilter {
    ids: number[];
    mode: 'profile';
}

export interface IReviewResponse {
    collection: IReview[];
}

export interface IAveragePrices {
    price_from: number;
    price_to: number;
    currency: string;
}

export interface IGeoAutocompleteItem {
    id: string;
    address: string;
    longitude: number;
    latitude: number;
    place_id: string;
    description: string;
    endpoint: string;
    parsed: boolean;
    adm_address: string;
    is_branch: boolean;
    postal_code: string;
    locality: string;
    distance_from_point: number;
    address_components: {
        street: string;
        building: string;
    };
}
export interface IGeoAutocompleteResponse {
    response: {
        items: IGeoAutocompleteItem[];
    };
}
