/* eslint-disable jsx-a11y/iframe-has-title */
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export const GtmNoScript = () => {
    return (
        <noscript>
            <iframe
                src={`https://www.googletagmanager.com/ns.html?id=${publicRuntimeConfig.GTM_KEY}`}
                height='0'
                width='0'
                style={{ display: 'none', visibility: 'hidden' }}></iframe>
        </noscript>
    );
};
