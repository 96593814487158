import { DropList as NovaDropList, IDropListProps } from '@indriver/nova';
import * as Styled from './drop-list.styles';

export type { IDropListProps } from '@indriver/nova';

export const DropList = ({ onClickOutside, ...props }: IDropListProps) => {
    /* TODO: there's no chance `e` is `undefined`,
             see https://github.com/inDriver/web-ui/issues/445#issuecomment-1646636259 */
    const handleClickOutside = (e?: MouseEvent) => {
        if (props.anchorRef.current?.contains(e?.target as Element)) {
            return;
        }
        onClickOutside?.(e);
    };

    return <NovaDropList className={Styled.dropList} onClickOutside={handleClickOutside} {...props} />;
};
