export const createLCG = (seed: number): (() => number) => {
    const a = 1664525;
    const c = 1013904223;
    const m = 0x100000000;
    let state = seed;
    return () => {
        state = (a * state + c) % m;
        return state / m;
    };
};
