import axios from 'axios';
import getConfig from 'next/config';
import { isSSR } from 'common/lib';
import { IFeatureToggleParams, IToggle } from './types';

export * from './types';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

const getServerHttpClientConfig = () => ({
    baseURL: `${serverRuntimeConfig?.AB_PLATFORM_HOST}/api/ab-platform/v2`,
    timeout: 3000,
});

const getBrowserHttpClientConfig = () => ({
    baseURL: `${publicRuntimeConfig?.AB_PLATFORM_HOST}/api/ab-platform/v2`,
});

const api = axios.create(isSSR ? getServerHttpClientConfig() : getBrowserHttpClientConfig());

export const getToggles = async (params: IFeatureToggleParams): Promise<IToggle[]> => {
    const { data } = await api.get<IToggle[]>('toggles', { params });
    return data;
};
