import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { getDeviceId, getUserId } from 'common/lib/tracking';
import { getGetTogglesQueryOptions } from './options';
import { selectToggle } from './selectors';
import { IVariationTypes } from './types';

export const useVariationsQuery = <K extends keyof IVariationTypes>(name: K) =>
    useQuery({
        ...getGetTogglesQueryOptions({
            namespace: 'super_masters_web',
            device_id: getDeviceId(),
            user_id: getUserId(),
        }),
        select: React.useCallback((toggles) => selectToggle(toggles, name), [name]),
        staleTime: Infinity,
    });
