import React from 'react';
import { Autocomplete, IAutocompleteProps, Loader } from '@indriver/nova';
import * as Styled from './autocomplete-async.styles';
import { useThrottledFetch } from './use-throttled-fetch';

export interface IAutocompleteAsyncProps<OptionType, FetchType = OptionType> extends IAutocompleteProps<OptionType> {
    fetchData: (query: string, signal: AbortSignal) => Promise<FetchType[] | null>;
    throttleDelay?: number;
    renderOption: (item: FetchType) => React.ReactElement;
    zeroScreen?: React.ReactNode;
    onClick?: () => void;
    onFocus?: () => void;
    autoFocus?: boolean;
}

export const AutocompleteAsync = <OptionType extends object, FetchType = OptionType>({
    prefix,
    onInputChange,
    fetchData,
    throttleDelay = 500,
    renderOption,
    zeroScreen,
    ...props
}: IAutocompleteAsyncProps<OptionType, FetchType>) => {
    const {
        data: options,
        resetData: resetOptions,
        throttledFetch,
        isPending,
    } = useThrottledFetch<FetchType[]>(fetchData, throttleDelay);

    const handleInputChange = React.useCallback(
        (value: string) => {
            onInputChange?.(value);
            void throttledFetch(value);
        },
        [onInputChange, throttledFetch],
    );

    const getPrefix = React.useCallback(() => {
        if (!prefix) {
            return null;
        }

        return isPending ? <Loader design='primary' /> : prefix;
    }, [isPending, prefix]);

    return (
        <Styled.PlaceholderFix>
            <Autocomplete<OptionType>
                prefix={getPrefix()}
                onInputChange={handleInputChange}
                onBlur={resetOptions}
                {...props}>
                {(() => {
                    if (options === null) {
                        return null;
                    }

                    if (options.length > 0) {
                        return options.map((item, i) => React.cloneElement(renderOption(item), { key: i }));
                    }

                    return zeroScreen;
                })()}
            </Autocomplete>
        </Styled.PlaceholderFix>
    );
};
