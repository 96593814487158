import { useTranslation } from 'next-i18next';
import { Avatar, Text } from '@indriver/nova';
import { variables } from '@indriver/yrel';
import * as Styled from './mixed-model-hint.styles';

interface IProps {
    count: number;
    firstName: string;
    avatar: string;
}

export const MixedModelHint = ({ count, firstName, avatar }: IProps) => {
    const { t } = useTranslation();

    return (
        <Styled.Wrapper>
            <Text size='s' color={variables['text-and-icon-secondary']}>
                {t('entities.tasker.mixed_model_hint_text', {
                    count,
                    tasker_first_name: firstName,
                })}
            </Text>
            <Avatar image={avatar} content={firstName} size='s' />
        </Styled.Wrapper>
    );
};
