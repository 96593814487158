import { useTranslation } from 'next-i18next';
import { useDispatch } from 'react-redux';
import { Button, Cell } from '@indriver/nova';
import { recordEvent } from 'common/lib';
import { useAnalyticsPayload } from 'entities/analytics';
import { showCompleteOrderModal } from './model/complete-order-modal.slice';

interface IProps {
    variant: 'inCell' | 'inBottomMenu' | 'inDropdown';
    onClick: () => void;
}

export const CompleteOrder = ({ variant, onClick }: IProps) => {
    const { t } = useTranslation(['active_order']);

    const defaultAnalyticsPayload = useAnalyticsPayload();
    const dispatch = useDispatch();

    const handleCompleteOrderClick = () => {
        recordEvent({
            actionGA: 'masters_customer_end_the_request_click',
            actionAmplitude: 'masters_customer_end_the_request_click',
            actionAF: 'masters.customer.end_the_request.click',
            payload: {
                ...defaultAnalyticsPayload,
            },
        });
        dispatch(showCompleteOrderModal());
        onClick();
    };

    if (variant === 'inCell') {
        return (
            <Button design='primary' size='m' onClick={handleCompleteOrderClick}>
                {t('active_order:complete_order_button')}
            </Button>
        );
    }
    if (variant === 'inBottomMenu') {
        return (
            <Button design='secondary' size='l' onClick={handleCompleteOrderClick}>
                {t('active_order:complete_order_button')}
            </Button>
        );
    }
    if (variant === 'inDropdown') {
        return (
            <Cell size='m' onClick={handleCompleteOrderClick}>
                {t('active_order:complete_order_button')}
            </Cell>
        );
    }

    return null;
};
