import { useTranslation } from 'next-i18next';
import { OrderField } from 'common/api';
import { renderValue } from 'common/lib';
import { ImagesGrid } from './images-grid';
import { OrderField as OrderFieldRow } from './order-field';
import * as Styled from './order-info.styles';

interface IProps {
    id?: string;
    createdAt?: string;
    fields?: OrderField[];
}

export const OrderDetails = ({ id, createdAt, fields }: IProps) => {
    const { t } = useTranslation('common');

    return (
        <Styled.Wrapper>
            {id && <OrderFieldRow label={t('order_id_page_order_id_label')} value={id.toUpperCase()} />}
            {createdAt && (
                <OrderFieldRow
                    label={t('order_id_page_order_created_at_label')}
                    value={t('intlDateTime', {
                        val: new Date(createdAt),
                        formatParams: {
                            val: {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            },
                        },
                    })}
                />
            )}
            {fields &&
                fields.length > 0 &&
                fields.map((field) =>
                    field.type === 'photo' ? (
                        <OrderFieldRow key={field.id} label={field.name}>
                            <ImagesGrid images={field.data.photos} />
                        </OrderFieldRow>
                    ) : (
                        <OrderFieldRow
                            key={field.id}
                            label={field.name}
                            value={renderValue(field.type, field)}
                            isPrice={field.type === 'price'}
                        />
                    ),
                )}
            {fields && fields.length > 0 && fields.find((field) => field.type === 'price') ? null : (
                <OrderFieldRow
                    label={t('common_price_label')}
                    value={t('service_id_page_new_price_negotiable_chip_title')}
                />
            )}
        </Styled.Wrapper>
    );
};
