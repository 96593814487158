import { gtXs, gtS, gtM, gtL, gtXl, gtXxl } from '../media-queries';
import { QueryMatches, useMediaQueries } from './use-media-queries';

const BREAKPOINTS = {
    isGtXs: gtXs,
    isGtS: gtS,
    isGtM: gtM,
    isGtL: gtL,
    isGtXl: gtXl,
    isGtXxl: gtXxl,
} as const;

export const useBreakpoints = (fallback: QueryMatches<typeof BREAKPOINTS> = {}) =>
    useMediaQueries(BREAKPOINTS, fallback);
