import { superMastersApi } from './base';
import {
    IProfileReviewsIdsResponse,
    IReviewResponse,
    IReviewsFilter,
    IReviewsExistResponse,
    IReviewTags,
    IOrderReviewData,
} from './models';

export const getProfileReviewIds = async (userId: number, locale: string) => {
    const response = await superMastersApi.get<IProfileReviewsIdsResponse>(`profile/${userId}/reviews`, {
        headers: {
            'Accept-Language': locale,
        },
    });
    return response.data.collection;
};

export const postCheckReviewForExistence = async (orderId: string) => {
    const response = await superMastersApi.post<IReviewsExistResponse>('orders/reviews/exist', {
        order_ids: [orderId],
    });
    return response.data;
};

export const getProfileReviews = async (filter: IReviewsFilter, locale: string) => {
    const response = await superMastersApi.post<IReviewResponse>('reviews', filter, {
        headers: {
            'Accept-Language': locale,
        },
    });
    return response.data.collection;
};

export const getReviewTags = async (locale: string) => {
    const response = await superMastersApi.get<IReviewTags[]>('review/tags', {
        headers: { 'Accept-Language': locale },
    });
    return response.data;
};

export const postOrderReview = async (orderId: string, data: IOrderReviewData) => {
    const response = await superMastersApi.post<void>(`review/order/${orderId}`, data);
    return response.data;
};
