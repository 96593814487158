import React from 'react';
import * as Styled from './card.styles';

export interface ICardProps {
    icon?: React.ReactNode;
    content?: React.ReactNode;
    title?: React.ReactNode;
    text?: React.ReactNode;
    footer?: React.ReactNode;
}

export const Card = ({ icon, content, title, text, footer }: ICardProps) => (
    <Styled.Box>
        {icon && <Styled.Icon>{icon}</Styled.Icon>}
        {content ?? (
            <>
                <Styled.Title>{title}</Styled.Title>
                {text && <Styled.Text>{text}</Styled.Text>}
            </>
        )}
        {footer && <Styled.Footer>{footer}</Styled.Footer>}
    </Styled.Box>
);
