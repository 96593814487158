import useEmblaCarousel, { EmblaOptionsType } from 'embla-carousel-react';
import { useTranslation } from 'next-i18next';
import { ReactNode, useEffect, useState } from 'react';
import { ChevronLeftSmall, ChevronRightSmall } from '@indriver/mireska';
import { Heading, RoundButton } from '@indriver/nova';
import * as Styled from './reviews-carousel.styles';

interface IProps extends EmblaOptionsType {
    children: ReactNode;
}

const defaultOptions: EmblaOptionsType = {
    align: 'start',
    skipSnaps: true,
    containScroll: 'trimSnaps',
};

export const ReviewsCarousel = ({ children, ...props }: IProps) => {
    const { t } = useTranslation('common');
    const [viewportRef, carousel] = useEmblaCarousel({ ...defaultOptions, ...props });

    const [[canScrollPrev, canScrollNext], setFlags] = useState([false, false]);

    useEffect(() => {
        if (carousel) {
            setFlags([carousel.canScrollPrev(), carousel.canScrollNext()]);
            carousel.on('scroll', () => {
                setFlags([carousel.canScrollPrev(), carousel.canScrollNext()]);
            });
        }
    }, [carousel]);

    const handlePrev = () => {
        carousel?.scrollPrev();
    };

    const handleNext = () => {
        carousel?.scrollNext();
    };

    return (
        <section>
            <Styled.Header>
                <Heading type='h2' size='xxl'>
                    {t('wizard_latest_reviews')}
                </Heading>
                <Styled.Controls>
                    {(canScrollPrev || canScrollNext) && (
                        <>
                            <RoundButton
                                design='secondary'
                                size='xs'
                                prefix={<ChevronLeftSmall size={20} />}
                                disabled={!canScrollPrev}
                                style={{ margin: 0, boxShadow: 'none' }}
                                onClick={handlePrev}
                            />
                            <RoundButton
                                design='secondary'
                                size='xs'
                                prefix={<ChevronRightSmall size={20} />}
                                disabled={!canScrollNext}
                                style={{ margin: 0, boxShadow: 'none' }}
                                onClick={handleNext}
                            />
                        </>
                    )}
                </Styled.Controls>
            </Styled.Header>
            <Styled.Viewport ref={viewportRef}>
                <Styled.Container>{children}</Styled.Container>
            </Styled.Viewport>
        </section>
    );
};
