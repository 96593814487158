import { useQuery } from '@tanstack/react-query';
import { seo, ISeoResponseEntry, ISeoResponseTextEntry } from 'common/api';
import { ISeoData } from './types';

const fields = new Set(['title', 'description', 'h1', 'h2', 'long_description']);
const isSeoResponseTextEntry = (o: object): o is ISeoResponseTextEntry => fields.has((o as ISeoResponseTextEntry).type);

const getSeoData = (seoData: ISeoResponseEntry[]): ISeoData =>
    Object.fromEntries(
        seoData?.map((item) => [item.type, isSeoResponseTextEntry(item) ? item.content.value : item.content]),
    );

interface IUseGetSeoTextsParams {
    locale: string;
    citySlug: string;
    categorySlug: string | undefined;
    serviceSlug: string;
    subServiceSlug?: string;
}

export const useGetSeoTexts = ({
    locale,
    citySlug,
    categorySlug,
    serviceSlug,
    subServiceSlug,
}: IUseGetSeoTextsParams) =>
    useQuery({
        queryKey: ['getSeoTexts', { locale, citySlug, categorySlug, serviceSlug, subServiceSlug }],
        queryFn: async () => {
            if (categorySlug === undefined) {
                throw new Error('Parameter <categorySlug> is not provided');
            }

            return seo.getSeoTexts({ locale, citySlug, categorySlug, serviceSlug, subServiceSlug });
        },
        select: getSeoData,
        enabled: categorySlug !== undefined,
        retry: false,
    });
