import React from 'react';
import { toast, ToastPosition } from 'react-toastify';
import type { ToastOptions } from 'react-toastify';
import { ChatCheckReceived, Close, DoneFilled, WarningTrOutline } from '@indriver/mireska';
import { RoundIcon } from '@indriver/nova';

const defaultOptions: ToastOptions = {
    position: 'bottom-right',
    autoClose: 1000 * 5,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'light',
    containerId: 'main',
};

interface IParams {
    message: string;
    toastId: string;
    position?: ToastPosition;
}

export const toastSuccess = ({ message, toastId, position }: IParams) =>
    toast.success(message, {
        icon: (
            <RoundIcon
                design='success'
                size='l'
                content={<ChatCheckReceived size={24} color='var(--extensions-text-and-icon-success)' />}
            />
        ),
        closeButton: (
            <RoundIcon design='neutral' size='s' content={<Close size={24} color='var(--text-and-icon-primary)' />} />
        ),
        toastId,
        ...defaultOptions,
        position,
    });

export const toastError = ({ message, toastId, position }: IParams) =>
    toast.error(message, {
        icon: (
            <RoundIcon
                design='error'
                size='l'
                content={<Close size={24} color='var(--extensions-text-and-icon-error)' />}
            />
        ),
        closeButton: (
            <RoundIcon design='neutral' size='s' content={<Close size={24} color='var(--text-and-icon-primary)' />} />
        ),
        toastId,
        ...defaultOptions,
        position,
    });

export const toastWarning = ({ message, toastId }: IParams) =>
    toast.warning(message, {
        icon: (
            <RoundIcon
                design='fire'
                size='l'
                content={<WarningTrOutline size={24} color='var(--extensions-text-and-icon-warning)' />}
            />
        ),
        closeButton: (
            <RoundIcon design='neutral' size='s' content={<Close size={24} color='var(--text-and-icon-primary)' />} />
        ),
        toastId,
        ...defaultOptions,
    });

export const toastInfo = ({ message, toastId }: IParams) =>
    toast.info(message, {
        icon: <DoneFilled size={32} color='#fff' />,
        closeButton: false,
        toastId,
        className: 'mixed-model-toast',
        ...defaultOptions,
        position: 'bottom-center',
        containerId: 'mixed-model',
    });
