import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICityInfo } from './types';

const { actions, reducer } = createSlice({
    name: 'currentCity',
    initialState: null as unknown as ICityInfo,
    reducers: {
        setCurrentCity: (_, action: PayloadAction<ICityInfo>) => action.payload,
    },
});

export const { setCurrentCity } = actions;
export const currentCityReducer = reducer;
