import SVGProps from '@indriver/mireska/dist/types/types';

export const Fullscreen = (props: SVGProps): JSX.Element => {
    const { color = 'currentColor', size, style } = props;
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 25'
            fill='none'
            width={size}
            height={size}
            style={style}
            {...props}>
            <g fill={color}>
                <path d='M20.2853 10.5854C20.2853 11.1377 20.7331 11.5854 21.2853 11.5854C21.8376 11.5854 22.2853 11.1377 22.2853 10.5854V3.54688V2.54688H21.2853H14.2468C13.6945 2.54688 13.2468 2.99459 13.2468 3.54688C13.2468 4.09916 13.6945 4.54688 14.2468 4.54688H18.8704L13.539 9.87832C13.1485 10.2688 13.1485 10.902 13.539 11.2925C13.9295 11.6831 14.5627 11.6831 14.9532 11.2925L20.2853 5.96039V10.5854Z' />
                <path d='M5.46124 20.7854L10.7927 15.454C11.1832 15.0635 11.1832 14.4303 10.7927 14.0398C10.4022 13.6493 9.76899 13.6493 9.37847 14.0398L4.04688 19.3714V14.7469C4.04688 14.1946 3.59916 13.7469 3.04688 13.7469C2.49459 13.7469 2.04688 14.1946 2.04688 14.7469V21.7854V22.7854H3.04688H10.0854C10.6377 22.7854 11.0854 22.3377 11.0854 21.7854C11.0854 21.2332 10.6377 20.7854 10.0854 20.7854H5.46124Z' />
            </g>
        </svg>
    );
};
