import axios from 'axios';
import { superMastersApi } from './base';
import { ICountriesResponse, INearCountriesResponse } from './models';

export const getCountries = async () => {
    const response = await superMastersApi.get<ICountriesResponse>('public/web/countries');
    return response.data.collection;
};

export const getRecommendedCity = async () => {
    const response = await axios.get<INearCountriesResponse>('/api/recommended-city');
    return response.data;
};
