import * as Styled from './images-grid.styles';

interface IProps {
    images: string[];
}

export const ImagesGrid = ({ images }: IProps) => (
    <Styled.ImagesWrapper>
        {images.map((image, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Styled.ImageWrapper key={index}>
                <Styled.Image src={image} />
            </Styled.ImageWrapper>
        ))}
    </Styled.ImagesWrapper>
);
