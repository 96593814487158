import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { seo } from 'common/api';
import { useCitiesBySlug } from 'entities/city';

interface IUseGetTopCitiesByService {
    countryIso: string;
    serviceSlug: string;
}

export const useGetTopCitiesByService = ({ countryIso, serviceSlug }: IUseGetTopCitiesByService) => {
    const { data: citiesBySlug } = useCitiesBySlug();
    const { data: citySlugs } = useQuery({
        queryKey: ['getTopCitiesByService', { countryIso, serviceSlug }],
        queryFn: async () => seo.getTopCitiesByService(countryIso, serviceSlug),
    });

    return React.useMemo(() => {
        if (citiesBySlug === undefined || citySlugs === undefined) {
            return [];
        }

        return citySlugs.map((slug) => citiesBySlug[slug]).filter((city) => city !== undefined);
    }, [citiesBySlug, citySlugs]);
};
