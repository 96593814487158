import { ForwardArrowRight } from '@indriver/mireska';
import { variables } from '@indriver/yrel';
import * as Styled from './custom-cell.styles';

interface ICustomCellProps {
    design?: 'white' | 'grey';
    title: string;
    subtitle?: string | false | null;
    icon?: string | JSX.Element;
}

export const CustomCell = ({ design = 'white', title, subtitle, icon }: ICustomCellProps) => (
    <Styled.Container design={design}>
        <Styled.Content>
            <Styled.Title size='ml' weight='bold' title={title}>
                {title}
            </Styled.Title>
            {subtitle && (
                <Styled.Subtitle weight='medium' title={subtitle}>
                    {subtitle}
                </Styled.Subtitle>
            )}
        </Styled.Content>
        {icon && (
            <>
                <Styled.CategoryIcon size='xl' content={icon} />
                <Styled.ChevronIcon
                    size='xl'
                    content={<ForwardArrowRight size={28} color={variables['background-brand']} />}
                />
            </>
        )}
    </Styled.Container>
);
