import useEmblaCarousel from 'embla-carousel-react';
import { ReactNode } from 'react';
import * as Styled from './cards-carousel.styles';

interface ICardsCarouselProps {
    children: ReactNode;
}

export const CardsCarousel = ({ children }: ICardsCarouselProps) => {
    const [viewportRef] = useEmblaCarousel({ align: 'start', containScroll: 'trimSnaps', skipSnaps: true });
    return (
        <Styled.Viewport ref={viewportRef}>
            <Styled.EmblaContainer>{children}</Styled.EmblaContainer>
        </Styled.Viewport>
    );
};
