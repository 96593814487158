import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TFunction } from 'next-i18next';
import { NextRouter } from 'next/router';
import { v4 as uuidv4 } from 'uuid';
import type { IOrderData, OrderField } from 'common/api';
import { patchUpdateOrder, postCancelOrder, postCompleteOrder, postOrder } from 'common/api/order';
import { toastError, toastSuccess } from 'common/ui';

interface IUseCancelOrder {
    onSettled: () => void;
    cityId: number;
}

export const useCancelOrder = ({ onSettled, cityId }: IUseCancelOrder) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (orderId: string) => postCancelOrder(orderId, cityId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['getOrder'] });
        },
        onError: () => {
            toastError({ message: 'Unexpected error', toastId: 'cancel-order-error' });
        },
        onSettled,
    });
};

interface IUseCompleteOrder {
    onSettled: () => void;
    cityId: number;
}

export const useCompleteOrder = ({ onSettled, cityId }: IUseCompleteOrder) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (orderId: string) => postCompleteOrder(orderId, cityId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['getOrder'] });
        },
        onError: () => {
            toastError({ message: 'Unexpected error', toastId: 'cancel-order-error' });
        },
        onSettled,
    });
};

interface IUseUpdateOrderParams {
    onSuccess: () => void;
    cityId: number;
}

interface IUpdateOrderMutationParams {
    orderId: string;
    updateOrderBody: { fields: OrderField[] };
}

export const useUpdateOrder = ({ onSuccess, cityId }: IUseUpdateOrderParams) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({ orderId, updateOrderBody }: IUpdateOrderMutationParams) =>
            patchUpdateOrder({ cityId, orderId, updateOrderBody }),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['getOrder'] });
            onSuccess();
        },
        onError: () => {
            toastError({ message: 'Unexpected error', toastId: 'cancel-order-error' });
        },
    });
};

interface IUseRepeatOrderMutationParams {
    locale: string;
    router: NextRouter;
    cityId: number;
    t: TFunction;
}

export const useRepeatOrderMutation = ({ locale, router, cityId, t }: IUseRepeatOrderMutationParams) =>
    useMutation({
        mutationFn: async (orderData: Omit<IOrderData, 'idempotency_key' | 'time_zone'>) =>
            postOrder(
                {
                    ...orderData,
                    idempotency_key: uuidv4(),
                    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
                locale,
                cityId,
            ),
        onSuccess: async (data) => {
            await router.push(`/orders/${data.id}`);
            toastSuccess({
                message: t('toast_order_repeated_success'),
                toastId: 'repeat-order-success',
                position: 'top-right',
            });
        },
        onError: () => {
            toastError({ message: 'Unexpected error', toastId: 'repeat-order-error', position: 'top-right' });
        },
    });
