import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { seo } from 'common/api';
import { useCitiesBySlug } from 'entities/city';

interface IUseGetLargestCitiesByCategory {
    countryIso: string;
    categorySlug: string | undefined;
}

export const useGetLargestCitiesByCategory = ({ countryIso, categorySlug }: IUseGetLargestCitiesByCategory) => {
    const { data: citiesBySlug } = useCitiesBySlug();
    const { data: citySlugs } = useQuery({
        queryKey: ['getLargestCitiesByCategory', { countryIso, categorySlug }],
        queryFn: async () => {
            if (categorySlug === undefined) {
                throw new Error('Parameter <categorySlug> is not provided');
            }

            return seo.getLargestCitiesByCategory(countryIso, categorySlug);
        },
        enabled: categorySlug !== undefined,
    });

    return React.useMemo(() => {
        if (citiesBySlug === undefined || citySlugs === undefined) {
            return [];
        }

        return citySlugs.map((citySlug) => citiesBySlug[citySlug]).filter((city) => city !== undefined);
    }, [citiesBySlug, citySlugs]);
};
