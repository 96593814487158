import { WarningTrFilled } from '@indriver/mireska';
import { StyledErrorToast, StyledIconWrapper, StyledText } from './error-toast.styles';

interface IProps {
    message: string;
    fullWidth?: boolean;
}

export const ErrorToast = ({ message, fullWidth = false }: IProps) => {
    return (
        <StyledErrorToast fullWidth={fullWidth}>
            <StyledIconWrapper>
                <WarningTrFilled color='var(--extensions-text-and-icon-error)' size={24} />
            </StyledIconWrapper>
            <StyledText>{message}</StyledText>
        </StyledErrorToast>
    );
};
