import type { IncomingMessage } from 'http';
import { getCookie } from 'cookies-next';
import { ACCESS_TOKEN_KEY } from './auth';
import { parseJwt } from './parse-jwt';

export const DEVICE_ID_COOKIE = 'abidv0';
export const generateTrackingId = () => Math.round(Math.random() * Number.MAX_SAFE_INTEGER).toString();

export const getDeviceId = (req?: IncomingMessage) => {
    const cookie = getCookie(DEVICE_ID_COOKIE, { req });
    return typeof cookie !== 'string' || Number.isNaN(Number(cookie)) ? generateTrackingId() : cookie;
};

export const getUserId = (req?: IncomingMessage) => {
    const accessToken = getCookie(ACCESS_TOKEN_KEY, { req });
    if (typeof accessToken !== 'string') {
        return undefined;
    }

    try {
        return parseJwt(accessToken).uid;
    } catch (error) {
        return undefined;
    }
};
